
import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'react-bootstrap';

const STATUS = {
  POR_CONTACTAR: 0,
  CONTACTADO: 1,
  ATENDIDO: 2,
  EN_CURSO: 3,
  NO_CONTACTADO: 4
}

export const ExportCSV = ({ csvData, fileName }) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const csvData2 = []

  let Estado

  csvData.forEach(data => {
    switch (data.data.status) {
      case STATUS.POR_CONTACTAR:
        Estado = "Por contactar"
        break
      case STATUS.CONTACTADO:
        Estado = "Contactado"
        break
      case STATUS.ATENDIDO:
        Estado = "Atendido"
        break
      case STATUS.EN_CURSO:
        Estado = "En curso"
        break
      case STATUS.NO_CONTACTADO:
        Estado = "No contactado"
        break
      default:
        Estado = "Error"
        break

    }
    let comentariooo = "Sin comentario"
    if (data.data.comment !== undefined) {
      comentariooo = data.data.comment
    }
    let existeEmail = false
    let data1
    let index2 = 0
    let keysCorreo
    data.data.options.forEach((option, index) => {
      //console.log('option', option)
      if (Object.keys(option)[0] === 'Correo') {
        keysCorreo = Object.keys(option)[0]
        //console.log(index);
        existeEmail = true
        index2 = index
      }
    })


    if (existeEmail) {
      data1 = { Nombre: data.data.options[2].completeName, Fecha: data.data.dateCreate, Comentario: comentariooo, Numero: data.data.options[1].telephone, Estado, Correo: data.data.options[index2].Correo }
    } else {
      data1 = { Nombre: data.data.options[2].completeName, Fecha: data.data.dateCreate, Comentario: comentariooo, Numero: data.data.options[1].telephone, Estado }
    }
    csvData2.push(data1)
  })

  const exportToCSV = (csvData2, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData2);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Button onClick={(e) => exportToCSV(csvData2, fileName)}>Exportar Excel</Button>

  )

}
