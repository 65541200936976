import React, { useState, useRef } from "react";
import { Modal } from 'react-bootstrap';
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import { useSelector, useDispatch } from 'react-redux'
import clienteAxios from "../config/axios";
import { APP_API_URL } from "../config";
import { guardarIdResponsableAction } from "../store/actions/authActions";
import imgTrash from '../assets/header/trash-red.png'
// import imgPlus from '../assets/header/mas.png'
import imgTrash1 from '../assets/header/trash (1).png'
// import imgSend from '../assets/header/enviar.png'
// import imgAdjuntar from '../assets/header/adjuntar-foto.png'
// import Switch from '@material-ui/core/Switch';
// import imgReasignar from '../assets/header/Reasignar.png'
import Swal from "sweetalert2";
import { SplshConversation } from "../components/SplashConversation";
import img from './../images/upload.svg'

const data = localStorage.getItem('user');
let dataParse = ''
let client
if (data !== null) {
  dataParse = JSON.parse(data)
  client = new W3CWebSocket(`ws://localhost:5000/${dataParse.numberWsp}/${dataParse.user.email}`);
}

const BlogOverview = () => {
  const role = useSelector(state => state.auth.user.user.role)
  const user = useSelector(state => state.auth.user.user)
  const dispatch = useDispatch()
  const [iframeModal, setiframeModal] = useState(false)
  // const [siwtch, setSwitch] = useState(false);
  const [chats, setChats] = useState([]);
  const [chatSelect, setChatSelect] = useState({
    number: ""
  })
  const [mensajesChat, setMensajesChat] = useState([])
  let myRef = useRef()
  const [charts, setCharts] = React.useState([])
  const [mensaje, setMensaje] = useState('')
  const openManualModal = () => {
    setiframeModal(true)
  }

  const closeModal = () => {
    setiframeModal(false)
  }

  // let client
  // let dataParse = ''
  // React.useEffect(() => {
  //   const data = localStorage.getItem('user');

  //   if (data !== null) {
  //     dataParse = JSON.parse(data)
  //     client = new W3CWebSocket(`ws://localhost:5000/${dataParse.numberWsp}/${dataParse.user.email}`);
  //   }
  // }, [])

  React.useEffect(() => {

    if (role) {
      if (role === 1) {
        clienteAxios.get(`${APP_API_URL}/admin/clients/dataCharts/${role}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('userToken')
          }
        }
        ).then((res) => {

          setCharts([
            {
              label: "Clientes activos",
              labelCliente: 'Usuarios atendidos',
              value: res.data.data.totalClientsActives,
              image: require('../assets/header/CLIENTES-ACTIVOS.svg'),
              percentage: "4.7%",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(23,198,113,0.1)",
                  borderColor: "rgb(23,198,113)",
                  data: [1, 2, 1, 3, 5, 4, 7]
                }
              ]
            },
            {
              label: "Clientes por vencer",
              labelCliente: 'Por contactar',
              value: res.data.data.totalClientsActives,
              image: require('../assets/header/CLIENTES-INACTIVOS.svg'),
              percentage: "12.4",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(0, 184, 216, 0.1)",
                  borderColor: "rgb(0, 184, 216)",
                  data: [1, 2, 3, 3, 3, 4, 4]
                }
              ]
            },
            {
              label: "Clientes inactivos",
              flabelCliente: 'Contactados',
              value: res.data.data.totalClientsInactives,
              image: require('../assets/header/CLIENTES-POR-VENCER.svg'),
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(255,180,0,0.1)",
                  borderColor: "rgb(255,180,0)",
                  data: [2, 3, 3, 3, 4, 3, 3]
                }
              ]
            }
          ])
        }).catch(err => console.log(err))
      } else {

        if (user.stateChad === 4) {
          clienteAxios.get(`${APP_API_URL}/admin/request/${user.whatsapp}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('userToken')
            }
          }).then(res => {
            setCharts([
              {
                label: "Clientes activos",
                labelCliente: 'Nuevas solicitudes',
                value: res.data.data.totalRequestActives,
                image: require('../assets/header/CLIENTES-ACTIVOS.svg'),
                percentage: "4.7%",
                increase: true,
                chartLabels: [null, null, null, null, null, null, null],
                attrs: { md: "6", sm: "6" },
                datasets: [
                  {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(23,198,113,0.1)",
                    borderColor: "rgb(23,198,113)",
                    data: [1, 2, 1, 3, 5, 4, 7]
                  }
                ]
              },
              {
                label: "Clientes por vencer",
                labelCliente: 'solicitudes atentidas',
                value: res.data.data.totalRequest,
                image: require('../assets/header/CLIENTES-POR-VENCER.svg'),
                percentage: "12.4",
                increase: true,
                chartLabels: [null, null, null, null, null, null, null],
                attrs: { md: "6", sm: "6" },
                datasets: [
                  {
                    label: "Today",
                    fill: "start",
                    borderWidth: 1.5,
                    backgroundColor: "rgba(0, 184, 216, 0.1)",
                    borderColor: "rgb(0, 184, 216)",
                    data: [1, 2, 3, 3, 3, 4, 4]

                  }
                ]
              }
            ])
          })
          return
        }
        if (user.stateChad == 5) {
          clienteAxios.get(`${APP_API_URL}/admin/request/${user.whatsapp}`, {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('userToken')
            }
          }).then(res => {

            clienteAxios.post(`${APP_API_URL}/admin/clients/dataChartsByRol`, { nit: role == 2 ? user.dataUser.nit : user.companyNit, role, idResponsable: user.id }, {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('userToken')
              }
            }).then(res2 => {
              setCharts([
                {
                  label: "Clientes activos",
                  labelCliente: 'Usuarios atendidos',
                  value: res2.data.data.totalClients,
                  image: require('../assets/header/CLIENTES-ACTIVOS.svg'),
                  percentage: "4.7%",
                  increase: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(23,198,113,0.1)",
                      borderColor: "rgb(23,198,113)",
                      data: [1, 2, 1, 3, 5, 4, 7]
                    }
                  ]
                },
                {
                  label: "Clientes por vencer",
                  labelCliente: 'Por contactar',
                  value: res2.data.data.totalClientsActives,
                  image: require('../assets/header/CLIENTES-INACTIVOS.svg'),
                  percentage: "12.4",
                  increase: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(0, 184, 216, 0.1)",
                      borderColor: "rgb(0, 184, 216)",
                      data: [1, 2, 3, 3, 3, 4, 4]
                    }
                  ]
                },
                {
                  label: "Clientes inactivos",
                  labelCliente: 'Contactados',
                  value: res2.data.data.totalClientsInactives,
                  image: require('../assets/header/CLIENTES-POR-VENCER.svg'),
                  percentage: "3.8%",
                  increase: false,
                  decrease: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "4", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(255,180,0,0.1)",
                      borderColor: "rgb(255,180,0)",
                      data: [2, 3, 3, 3, 4, 3, 3]
                    }
                  ]
                },
                {
                  label: "Clientes activos",
                  labelCliente: 'Nuevas solicitudes',
                  value: res.data.data.totalRequestActives,
                  image: require('../assets/header/CLIENTES-ACTIVOS.svg'),
                  percentage: "4.7%",
                  increase: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "6", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(23,198,113,0.1)",
                      borderColor: "rgb(23,198,113)",
                      data: [1, 2, 1, 3, 5, 4, 7]
                    }
                  ]
                },
                {
                  label: "Clientes por vencer",
                  labelCliente: 'solicitudes atentidas',
                  value: res.data.data.totalRequest,
                  image: require('../assets/header/CLIENTES-POR-VENCER.svg'),
                  percentage: "12.4",
                  increase: true,
                  chartLabels: [null, null, null, null, null, null, null],
                  attrs: { md: "6", sm: "6" },
                  datasets: [
                    {
                      label: "Today",
                      fill: "start",
                      borderWidth: 1.5,
                      backgroundColor: "rgba(0, 184, 216, 0.1)",
                      borderColor: "rgb(0, 184, 216)",
                      data: [1, 2, 3, 3, 3, 4, 4]
                    }
                  ]
                }
              ])
            })
          })
          return
        }

        clienteAxios.post(`${APP_API_URL}/admin/clients/dataChartsByRol`, { nit: role == 2 ? user.dataUser.nit : user.companyNit, role, idResponsable: user.id }, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('userToken')
          }
        }).then(res => {
          setCharts([
            {
              label: "Clientes activos",
              labelCliente: 'Usuarios atendidos',
              value: res.data.data.totalClients,
              image: require('../assets/header/CLIENTES-ACTIVOS.svg'),
              percentage: "4.7%",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(23,198,113,0.1)",
                  borderColor: "rgb(23,198,113)",
                  data: [1, 2, 1, 3, 5, 4, 7]
                }
              ]
            },
            {
              label: "Clientes por vencer",
              labelCliente: 'Por contactar',
              value: res.data.data.totalClientsActives,
              image: require('../assets/header/CLIENTES-INACTIVOS.svg'),
              percentage: "12.4",
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(0, 184, 216, 0.1)",
                  borderColor: "rgb(0, 184, 216)",
                  data: [1, 2, 3, 3, 3, 4, 4]
                }
              ]
            },
            {
              label: "Clientes inactivos",
              labelCliente: 'Contactados',
              value: res.data.data.totalClientsInactives,
              image: require('../assets/header/CLIENTES-POR-VENCER.svg'),
              percentage: "3.8%",
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(255,180,0,0.1)",
                  borderColor: "rgb(255,180,0)",
                  data: [2, 3, 3, 3, 4, 3, 3]
                }
              ]
            }
          ])
        })

      }

    }
    return () => {
    }
  }, [])

  const getDeparments = () => {
    clienteAxios.get(`${APP_API_URL}/admin/departments/${user.dataUser.nit}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }
    ).then((res) => {
      if (res.data.success) {
        const { data } = res.data;
        if (data != undefined) {
          if (data.deparments != undefined) {
            if (data.deparments.length > 0) {
              const deparments = res.data.data.deparments
              dispatch(guardarIdResponsableAction(deparments[0].id))
              // crearTabla(res.data.data.deparments)
            }
          }
        }
      }

    }).catch(err => {
      console.log("error -->>> ", err);
      window.location.reload();
    })


  }

  React.useEffect(() => {
    if (user.stateChad == 5) {
      getDeparments()
    }
  }, [])

  const getChats2 = async (select, first) => {
    const result = await clienteAxios.post(`${APP_API_URL}/chatbot/v1/chats`, { numberEnterprise: user.enterprise, asig: dataParse.user.email });
    setChats(result.data.data.chats)
    const id = window.sessionStorage.getItem('chatSelected')
    const arr = result.data.data.chats.filter(chat => chat.id == id);
    handleSelectChat(arr[0])
    document.getElementById("conversaciones").scrollTo({ left: 0, top: document.body.scrollHeight + 1000000 })
  }

  const getChats = async (select, first) => {
    const result = await clienteAxios.post(`${APP_API_URL}/chatbot/v1/chats`, { numberEnterprise: user.enterprise, asig: dataParse.user.email });
    setChats(result.data.data.chats)
    if (select) {
      const id = window.sessionStorage.getItem('chatSelected')
      const arr = result.data.data.chats.filter(chat => chat.id == id);
      handleSelectChat(arr[0])
    }
    document.getElementById("conversaciones").scrollTo({ left: 0, top: document.body.scrollHeight + 1000000 })
  }

  React.useEffect(() => {
    if (role === 10 && client) {
      getChats()
      client.onopen = () => {
        console.log('WebSocket Client Connected');
        client.onmessage = (message) => {
          console.log(message.data);
          getChats(true, true)
        };
      };
      client.onmessage = (message) => {
        console.log(message);
        getChats(true, true)
      };
    } else if (role === 10) {
      window.location.reload()
    }
  }, [])

  const handleSelectChat = async (chat) => {
    if (chat) {
      window.sessionStorage.setItem('chatSelected', chat.id)
      setChatSelect(chat)
      await clienteAxios.post(`${APP_API_URL}/chatbot/v1/changeStatusChat`, { keyChat: chat.keyChat })
      setMensajesChat(chat.mensajes)
      document.getElementById("conversaciones").scrollTo({ left: 0, top: document.body.scrollHeight + 1000000 })
      getChats(false)
    }
  }
  const handleEnviarRespuesta = async () => {
    if (mensaje.trim() === '') {
      alert('Digite un mensaje')
      return
    }
    console.log(mensaje)
    await clienteAxios.post(`${APP_API_URL}/chatbot/v1/responder`, { idClient: chatSelect.idClient, mensaje, idInbox: mensajesChat[mensajesChat.length - 1].id, cel: user.enterprise, numberCLient: user.idDepartment, numberEnterprise: user.enterprise, asig: dataParse.user.email });
    getChats2()
    setMensaje('');
  }

  const handleDeleteChat = async (chat) => {
    Swal.fire({
      title: '¿Desea eliminar el chat?',
      icon: 'info',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `si`,
    }).then(async result => {
      if (result.isConfirmed) {
        const resp = await clienteAxios.post(`${APP_API_URL}/chatbot/v1/delChat`, { keyChat: chat.keyChat })
        console.log(resp)
        if (resp.data.success) {
          Swal.fire('¡Chat eliminado exitosamete!', '', 'success')
          const id = window.sessionStorage.getItem('chatSelected')
          if (id === chat.id) {
            setChatSelect({ number: '' })
            setMensajesChat([])
          }
          getChats()
        }
      }
    })
  }

  const handleFileChat = async (chat) => {
    const { value: file } = await Swal.fire({
      title: '¡Seleccone la imagen a enviar!',
      input: 'file',
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Ulpoad your profile picture'
      }
    })
    console.log(file)
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        Swal.fire({
          title: file.name,
          imageUrl: e.target.result,
          imageAlt: 'The uploaded picture',
          showCancelButton: true,
          confirmButtonText: `Enviar`,
          cancelButtonText: `No enviar`,
        }).then(async result => {
          if (result.isConfirmed) {
            await clienteAxios.post(`${APP_API_URL}/chatbot/v1/responder`, { idClient: chatSelect.idClient, mensaje: e.target.result, idInbox: mensajesChat[mensajesChat.length - 1].id, cel: user.enterprise, numberCLient: user.idDepartment, numberEnterprise: user.enterprise, asig: dataParse.user.email });
            getChats2()
            Swal.fire('Saved!', '', 'success')
          }
        })
      }
      reader.readAsDataURL(file)
    }
  }

  if (role === 10) {
    return (
      <Container fluid className="main-content-container px-4 py-4">
        <div className="d-flex">
          <div className="col-md-4" style={{ border: '2px solid #3d207d', borderRadius: '6px', backgroundColor: 'white', padding: '10px', height: '82vh' }}>
            <h3 style={{ color: '#3d207d' }}>Chats</h3>
            <div style={{ overflow: 'scroll', height: '90%', overflowX: 'hidden' }}>
              {/** LISTA DE USUARIOS */}
              {chats.map(chat => (
                <div style={{ width: '96%', borderRadius: '10px', display: 'block', borderStyle: `solid`, borderWidth: '2px 10px 2px 2px', borderColor: `${chat.read ? '#3d207d' : '#ff6364'}`, padding: '5px', marginBottom: '10px', cursor: 'pointer' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ margin: '0px' }} onClick={() => handleSelectChat(chat)}>{chat.userName}</h4>
                    <div>
                      <button onClick={() => handleDeleteChat(chat)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <img style={{ width: '26px' }} src={imgTrash} alt={imgTrash} />
                      </button>
                    </div>
                  </div>
                  <span style={{ width: '10px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {console.log('lasmena', chat.lastMessage)}
                    {
                      isNaN(chat.lastMessage) ? (chat.lastMessage.includes('data:image/') ? 'Imagen' : chat.lastMessage) : 'Nuevo mensaje'
                    }
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* componente por defecto, para cuando no se ha seleccionado un chat aun */}
          <SplshConversation mensajesChat={mensajesChat} />

          <div id="conversacion" ref={myRef} className="col-md-8 ml-2" style={{ border: '2px solid #3d207d', borderRadius: '6px', backgroundColor: 'white', padding: '0px!important', height: '82vh' }} hidden={mensajesChat.length === 0}>

            <div style={{ backgroundColor: '#3d207d', padding: '10px', color: 'white', fontsize: '18px', fontweight: '700', display: 'flex', justifycontent: 'space-between' }}>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <div id="contact-name">{chatSelect.userName}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p onClick={() => handleDeleteChat(chatSelect)} className="btn btn-xs btn-danger" style={style}>
                  <img style={{ width: '26px' }} src={imgTrash1} alt={imgTrash} />
                </p>
                {/* <span style={{ textAlign: 'center' }}>Tomar control del Chat<Switch
                  checked={siwtch}
                  onChange={() => setSwitch(!siwtch)}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></span> */}
              </div>
            </div>
            <div style={{ height: '92%' }}>
              <div style={{ padding: '10px', overflow: 'scroll', height: '86%', overflowX: 'hidden', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} id="conversaciones">
                <span style={{ display: 'block', textAlign: 'center', color: 'white', fontWeight: 'bold' }}><p style={{ color: 'white' }} >Cargar mas </p></span>
                {mensajesChat.length === 0 ? ' No hay mensajes' : ''}
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} id="">
                  {mensajesChat.map(mensaje => (
                    <>
                      <div key={mensaje.id} style={{ width: mensaje.message.length < 95 ? 'max-content' : '60%', backgroundColor: '#673ab7', padding: '10px', borderRadius: '10px', color: 'white', marginBottom: '10px' }}>
                        <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>
                          {
                            mensaje.message.includes('data:image/') ? (<img src={mensaje.message} alt={mensaje.message} style={{ objectFit: 'contain', width: '100%' }} />) : mensaje.message
                          }
                        </p>
                        <span className="alert-link">{mensaje.date}</span>
                      </div>
                      {mensaje.reply.map(rep => (
                        <div style={{ width: rep.mensaje.length < 95 ? 'max-content' : '60%', color: '#fff', textAlign: 'end', backgroundColor: '#ff6364', padding: '10px', borderRadius: '10px', alignSelf: 'flex-end', marginBottom: '10px' }}>
                          {
                            rep.mensaje.includes('data:image/') ? (<img src={rep.mensaje} alt={rep.mensaje} style={{ objectFit: 'contain', width: '100%' }} />) : rep.mensaje
                          }
                          <div>
                            <span className="alert-link">{rep.date}</span>
                          </div>
                        </div>
                      ))}

                    </>
                  ))}
                </div>
              </div>
              <div style={{ padding: '10px', borderTop: '1px solid #000', height: '9%', display: 'flex', alignItems: 'center', backgroundColor: "#3d207d", borderRadius: '0px 0px 5px 5px' }}>
                <input value={mensaje} onChange={(e) => setMensaje(e.target.value)} placeholder="Ingrese su mensaje" style={{ width: '80%', borderRadius: '10px', border: '1px solid #000' }} />
                <p onClick={() => handleFileChat(chatSelect)} className="btn btn-primary btn-sm m-auto" style={{ padding: '5px 10px' }} >
                  <img style={{ width: '16px', color: '#fff' }} src={img} alt='Enviar archivo' />
                </p>
                <button onClick={handleEnviarRespuesta} className="btn btn-primary btn-sm m-auto" style={{ padding: '5px 10px' }}>Enviar</button>
              </div>
            </div>

          </div>
        </div>
      </Container>
    )

  }
  return (<Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Dashboard" subtitle="" className="text-sm-left mb-3" openManualModal={openManualModal} />
    </Row>

    {/* Small Stats Blocks */}
    <Row>
      {charts.map((stats, idx) => (
        <Col className="mb-3" key={idx} {...stats.attrs}>
          <SmallStats
            id={`small-stats-${idx}`}
            variation="1"
            chartData={stats.datasets}
            chartLabels={stats.chartLabels}
            label={role !== 1 ? stats.labelCliente : stats.label}
            value={stats.value}
            percentage={stats.percentage}
            increase={stats.increase}
            decrease={stats.decrease}
            image={stats.image}
            role={role}
          />
        </Col>
      ))}
    </Row>

    <div style={{ marginleft: '-25px' }}>
      <img style={{ height: "70vh" }} src={require("../assets/header/chad.png")} alt="" />
      {/* Users Overview */}
      {/*<Col lg="8" md="12" sm="12" className="mb-4">
        <UsersOverview />
      </Col>*/}

      {/* Users by Device */}
      {/*<Col lg="4" md="6" sm="12" className="mb-4">
        <UsersByDevice />
      </Col>*/}

    </div>


    <div className="form-group col-md-12 ">
      <Modal
        show={iframeModal}
        onHide={closeModal}
        centered
        scrollable
        size="lg"
      >
        <Modal.Body style={{ height: '2500px' }}>
          <iframe src={role === 2 ? "https://chatome.com.co/media/manuales/dashboard/perfil-3/dashboard.pdf" : role === 3 ? "https://chatome.com.co/media/manuales/dashboard/perfil-3/dashboard-1.pdf" : ""} style={{ width: '100%', height: "100%" }} title="Iframe Dashboard"></iframe>
        </Modal.Body>
      </Modal>
    </div>

  </Container>)
};

BlogOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

BlogOverview.defaultProps = {
  smallStats: [
    {
      label: "Clientes activos",
      labelCliente: 'Usuarios atendidos',
      value: "1,490",
      image: require('../assets/header/CLIENTES-ACTIVOS.svg'),
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "Clientes por vencer",
      labelCliente: 'Por contactar',
      value: "182",
      image: require('../assets/header/CLIENTES-INACTIVOS.svg'),
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: "Clientes inactivos",
      labelCliente: 'Contactados',
      value: "8,147",
      image: require('../assets/header/CLIENTES-POR-VENCER.svg'),
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    }
  ]
};

export default BlogOverview;

const style = {
  background: '#ff6363',
  // border: 'none',
  // paddingBottom: 0,
  // marginBottom: 10,
  padding: '5px 10px',
}
