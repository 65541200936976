import React, { useEffect, useState, createRef } from 'react';
import { Container, Row, Col } from 'shards-react';
import { APP_API_URL, WAPI } from '../config';
import clienteAxios from '../config/axios';
import Select from 'react-select';
import { Button, Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment'
import './index.css'
import Permission from '../components/common/Permission';
import PageTitle from '../components/common/PageTitle';
import Swal from 'sweetalert2';
import LabelError from '../components/LabelError';
import { useSelector, useDispatch } from "react-redux"
import Validate from '../utils/validateInput';
import Installer from './Installer';
import {
  CircularProgress
} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { guardarIdResponsableAction } from '../store/actions/authActions';
import axios from 'axios';


const Clients = (props) => {
  const dispatch = useDispatch()
  const role = useSelector(state => state.auth.user.user.role)
  //const nit2 = useSelector(state => state.auth.user.user.dataUser.nit)
  const user = useSelector(state => state.auth.user)
  const state = useSelector(state => state.auth)
  const inputPlan = createRef()
  const inputState = createRef()
  const inputChad = createRef()
  const [clients, setClients] = useState([])
  const [modalIsOpen, setIsOpen] = useState(false);
  const [nombre, setNombre] = useState('')
  const [empresa, setEmpresa] = useState('')
  const [telefono, setTelefono] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [correo, setCorreo] = useState('')
  const [password, setPassword] = useState('')
  const [canales, setCanales] = useState('')
  const [nit, setNit] = useState('')
  const [modoPlan, setModoPlan] = useState(false)
  const [empresaSeleccioanda, setEmpresaSeleccionada] = useState({})
  const [statePlan, setStatePlan] = useState("")
  const [stateActivo, setStateActivo] = useState("")
  const [stateChad, setStateChad] = useState("")
  // const [state, setState] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [host, setHost] = useState("");
  const [modalInstaller, setModalInstaller] = useState(false);
  const [selectInstaller, setSelectInstaller] = useState({});

  //errores
  const [nameError, setNameError] = useState({ mensaje: "", error: false })
  const [empresaError, setempresaError] = useState({ mensaje: "", error: false })
  const [nitError, setnitError] = useState({ mensaje: "", error: false })
  const [telefonoError, settelefonoError] = useState({ mensaje: "", error: false })
  const [correoError, setcorreoError] = useState({ mensaje: "", error: false })
  const [canalesError, setcanalesError] = useState({ mensaje: "", error: false })
  const [passError, setpassError] = useState({ mensaje: "", error: false })
  const [hostError, sethostError] = useState({ mensaje: "", error: false })

  // State de editar datos
  const [editResponsable, setEditResponsable] = useState("")
  const [editIdDepartamento, setEditIdDepartamento] = useState("")
  const [editDepartamento, setEditDepartamento] = useState("")
  const [editPassword, setEditPassword] = useState("")
  const [editTelefono, setEditTelefono] = useState("")
  const [editEmail, setEditEmail] = useState("")
  const [editNit, setEditNit] = useState("")
  const [editCanales, setEditCanales] = useState("")
  const [editIdentificacion, setIdentificacion] = useState("")

  const [modalEdit, setModalEdil] = useState(false)

  const [loader, setLoader] = useState(false);

  const [modalDept, setmodalDept] = useState(false)

  // SE VALIDA USARIO ACTIVO
  const [whatsappActive, setWhatsappActive] = useState(false);




  // const {} = useParams()

  const { path } = props.match;

  // console.log("useParams() ", path);

  // State de errores
  const states = [
    { value: 1, label: 'Activo' },
    { value: 0, label: 'Inactivo' }
  ]
  const planes = [
    { value: 1, label: '1 mes' },
    { value: 6, label: '6 meses' },
    { value: 12, label: '12 meses' },
  ]

  let chad = []

  switch (role) {
    case 1:
      chad = [
        { value: 1, label: 'Chad Web - (Plan 1)' },
        { value: 2, label: 'Chat Whatsapp - (Plan 2)' },
        { value: 3, label: 'Chad Web & Whatsapp - (Plan 3)' },
        { value: 4, label: 'Chad Solicitudes Whatsapp - (Plan 4)' },
        { value: 5, label: 'Chad web & Solicitudes Whatsapp - (Plan 5)' },
      ]
      break;

    case 2:
      chad = [
        { value: 1, label: 'Chad Web - (Plan 1)' },
      ]
      break;

    default:
      break;
  }

  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
    setModoPlan(false)
    setEmpresaSeleccionada({})
    setEmpresa("")
    setTelefono("")
    setNombre("")
    setNit("")
    setCanales("")
    setCorreo("")
    setPassword("")
    setHost("")
  }
  const closeModalInstaller = () => {
    setModalInstaller(false);
  }
  const getClient = async () => {
    let arrData = [];
    setLoader(true)
    clienteAxios.get(`${APP_API_URL}/admin/clients`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }).then((res) => {
      if (res.status == 200) {

        function ciclo(indice, arrays) {
          if (indice < arrays.length) {
            let phone = arrays[indice].phone;
            axios.post(`${WAPI}/islogin`, {
              phone: `57${phone}`
            },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("userToken")
                }
              }
            ).then(response => {
              console.log('response wapi');
              if (response.data.d == null || response.data.d == "" || response.data.d == undefined) {
                arrData.push({
                  ...res.data.data.clients[indice],
                  isActive: false
                })

                indice++;
                ciclo(indice, arrays);
              } else {
                arrData.push({
                  ...res.data.data.clients[indice],
                  isActive: true
                })

                indice++;
                ciclo(indice, arrays);
              }
            }).catch(error => console.log(error))
          } else {
            console.log('funciona');
            setClients(arrData)
            setLoader(false)
          }
        }

        ciclo(0, res.data.data.clients);

        /*
        res.data.data.clients.map((data, i) => {
          axios.post(`${WAPI}/islogin`, {
            phone: `57${data.phone}`
          },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("userToken")
              }
            }
          ).then(response => {
            console.log('response wapi');
            if (response.data.d == null || response.data.d == "" || response.data.d == undefined) {
              arrData.push({
                ...res.data.data.clients[i],
                isActive: false
              })
            } else {
              arrData.push({
                ...res.data.data.clients[i],
                isActive: true
              })
            }
          }).catch(error => console.log(error))
        })*/

      }
    }).catch(err => {
      console.log(err);
      setLoader(false)
      window.location.reload();
    })
  }

  console.log('clients', clients);

  const getDeparments = () => {
    setLoader(true)
    if (path == "/departments") {
      clienteAxios.get(`${APP_API_URL}/admin/departments/${user.user.dataUser.nit}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userToken')
        }
      }
      ).then((res) => {
        setLoader(false)
        if (res.data.success) {
          const { data } = res.data;
          if (data != undefined) {
            if (data.deparments != undefined) {
              if (data.deparments.length > 0) {
                const deparments = res.data.data.deparments
                setClients(deparments)
                dispatch(guardarIdResponsableAction(deparments[0].id))
                // crearTabla(res.data.data.deparments)
              }
            }
          }
        }

      }).catch(err => {
        setLoader(false)
        console.log("error -->>> ", err);
        window.location.reload();
      })
    }

  }
  useEffect(() => {
    if (role == 1) {
      getClient()
    } else {
      getDeparments()
    }
  }, [])
  const validateRegular = (data) => {
    let errors = false;
    const keys = Object.keys(data)
    const Regex = /[-_^*?\[\]]/
    if (Regex.test(data)) {
      errors = true
    }
    return errors
  }

  const validarDominio = (data) => {
    let errors = false;
    const keys = Object.keys(data)
    const Regex = /[a-z]+\.[a-z]+/
    if (Regex.test(data)) {
      errors = true
    }
    return errors
  }

  const handleSaveClient = () => {
    // console.log("handleSaveClient");
    if (nombre === "") {
      setNameError({ mensaje: "El campo no puede ir vacio", error: true })
      return false;
    } else if (validateRegular(nombre) === true) {
      setNameError({ mensaje: "El campo no puede tener caracteres especiales", error: true })
      return false;
    } else {
      setNameError({ mensaje: "", error: false })
    }
    if (empresa === "") {
      setempresaError({ mensaje: "El campo no puede ir vacio", error: true })
      return false;
    } else if (validateRegular(empresa) === true) {
      setempresaError({ mensaje: "El campo no puede tener caracteres especiales", error: true })
      return false;
    } else {
      setempresaError({ mensaje: "", error: false })
    }
    if (nit === "") {
      setnitError({ mensaje: "El campo no puede ir vacio", error: true })
      return false;
    } else if (validateRegular(nit) === true) {
      setNameError({ mensaje: "El campo no puede tener caracteres especiales", error: true })
      return false;
    } else if (path == "/departments" && nit.length < 4) {
      setnitError({ mensaje: "El campo debe tener 10 dígitos", error: true })
      return false;
    } else if (path == "/clients" && nit.length < 10) {
      setnitError({ mensaje: "El campo debe tener 10 dígitos", error: true })
      return false;
    } else {
      setnitError({ mensaje: "", error: false })
    }
    if (telefono === "") {
      settelefonoError({ mensaje: "El campo no puede ir vacio", error: true })
      return false;
    } if (telefono.length < 10) {
      settelefonoError({ mensaje: "El campo debe tener 10 dígitos", error: true })
      return false;
    } else {
      settelefonoError({ mensaje: "", error: false })
    }

    if (path === "/client") {
      if (role === 2 && (stateChad === 2 || stateChad === 3 || stateChad === 4)) {
        if (whatsapp === "") {
          settelefonoError({ mensaje: "El campo no puede ir vacio", error: true })
          return false;
        } if (whatsapp.length < 10) {
          settelefonoError({ mensaje: "El campo debe tener 10 dígitos", error: true })
          return false;
        } else {
          settelefonoError({ mensaje: "", error: false })
        }
      }
    }

    if (correo === "") {
      setcorreoError({ mensaje: "El campo no puede ir vacio", error: true })
      return false;
    } else if (validateRegular(correo) === true) {
      setNameError({ mensaje: "El campo no puede tener caracteres especiales", error: true })
    } else if (!Validate.validateEmail(correo)) {
      setcorreoError({ mensaje: "El campo debe ser un email valido", error: true })
      return false;
    } else {
      setcorreoError({ mensaje: "", error: false })
    }

    if (host === "") {
      sethostError({ mensaje: "El campo no puede ir vacio", error: true })
      return false;
    } else if (validarDominio(host) === false) {
      sethostError({ mensaje: "Dominio invalido", error: true })
      return true;
    } else {
      sethostError({ mensaje: "", error: false })
    }


    if (canales === "" && role != 2) {
      setcanalesError({ mensaje: "El campo no puede ir vacio", error: true })
      return false;

    } if (parseInt(canales) == 0) {
      setcanalesError({ mensaje: "La cantidad de canales debe ser mayor a cero", error: true })
      return false;
    } else {
      setcanalesError({ mensaje: "", error: false })
    }

    if (password === "") {
      setpassError({ mensaje: "El campo no puede ir vacio", error: true })
      return false;

    } if (password.length < 6) {
      setpassError({ mensaje: "La contraseña debe ser mínimo de 6 caracteres", error: true })
      return false;
    } else {
      setpassError({ mensaje: "", error: false })

    }


    if (role == 1) {
      // Enviando peticion

      const obj = {
        "companyName": empresa,
        "phone": telefono,
        "responsable": nombre,
        "nit": nit,
        "channels": canales,
        "email": correo,
        "password": password,
        "plan": inputPlan.current.state.value.value,
        "host": host,
        "stateChad": stateChad,
        "send": true
      }

      const obj2 = {
        ...obj,
        "whatsapp": whatsapp
      }

      clienteAxios.post(`${APP_API_URL}/admin/clients`,
        stateChad === 1 ? obj : obj2
        ,
        {
          headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }//the token is a variable which holds the token
        }
      ).then((res) => {
        if (res.data.success) {
          closeModal()
          getClient()

          // Limpiando formulario
          setEmpresa("")
          setTelefono("")
          setNombre("")
          setNit("")
          setCanales("")
          setCorreo("")
          setPassword("")

          Swal.fire({
            icon: 'success',
            title: "¡Buenas noticias!",
            text: `El usuario ha sido creado exitosamente.`,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Advertencia',
            text: res.data.message,
          })
        }
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Advertencia',
          text: err.message,
        })
      })
      return
    }

    // Enviando peticion
    clienteAxios.post(`${APP_API_URL}/admin/departments/`, {
      "nameDepartment": nombre,
      "companyNit": user.user.role == 3 ? user.user.companyNit : user.user.dataUser.nit,
      "phone": telefono,
      "responsable": empresa,
      "id": nit,
      "channels": canales,
      "email": correo,
      "password": password,
      "host": host,
      "send": user.user.stateChad != 5
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }//the token is a variable which holds the token
      }
    ).then((res) => {
      if (res.data.success) {
        closeModal()
        getDeparments()

        // Limpiando formulario
        setEmpresa("")
        setTelefono("")
        setNombre("")
        setNit("")
        setCanales("")
        setCorreo("")
        setPassword("")
        Swal.fire({
          icon: 'success',
          title: "¡Buenas noticias!",
          text: `El departamento ha sido creado exitosamente.`,
        })
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Advertencia',
          text: res.data.message,
        })
      }


    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: err.message,
      })
    })

  }

  const handleDelete = (nit) => {

    Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta acción no se puede revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!'
    }).then((result) => {
      if (result.isConfirmed) {

        clienteAxios.delete(`${APP_API_URL}/admin/clients/${nit}`, {
          headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }//the token is a variable which holds the token
        }).then((res) => {
          getClient()
          Swal.fire(
            'Eliminado!',
            'El cliente ha sido eliminado.',
            'success'
          )
        }).catch(err => console.log(err))

      }
    })

  }
  const handleModificarPlan = (client) => {
    setEmpresaSeleccionada(client)
    setStateActivo(client.moreData.activo == 1 ? states[0] : states[1])
    setStatePlan(parseInt(client.moreData.plan) == 1 ? planes[0] : parseInt(client.moreData.plan) == 6 ? planes[1] : planes[2])
    setModoPlan(true)
    openModal()
  }
  const handleChangePlan = () => {
    const obj = {
      "nit": empresaSeleccioanda.nit,
      "plan": inputPlan.current.state.value.value + "",
      "status": inputState.current.state.value.value + ""
    };
    clienteAxios.put(`${APP_API_URL}/admin/clients/changeStatus`, {
      "nit": empresaSeleccioanda.nit,
      "plan": inputPlan.current.state.value.value + "",
      "status": inputState.current.state.value.value + ""
    }, {
      headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }//the token is a variable which holds the token
    }).then((resp) => {
      if (resp.data.success) {
        closeModal()
        setStateActivo("")
        setStatePlan("")
        getClient()

        Swal.fire({
          icon: 'success',
          title: "¡Buenas noticias!",
          text: `Cambio exitoso.`,
        })

      } else {
        Swal.fire({
          icon: 'info',
          title: 'Advertencia',
          text: `¡Ups, ha sucedido un problema, intentan nuevamente realizar el cambio!`,
        })
      }

    }).catch(err => {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: err.message,
      })
    })
  }
  // const crearTabla = (clients2) => {
  //   if (true) {
  //     let datos = [];
  //     clients2.map(client =>
  //       datos.push({
  //         responsable: client.responsable,
  //         companyName: client.companyName,
  //         dateRegister: moment(client.moreData.dateRegister).format("YYYY-MM-DD"),
  //         dateExpirePlan: moment(client.moreData.dateExpirePlan).format("YYYY-MM-DD"),
  //         contact: <><span>{client.moreData.email}</span>
  //           <span className="d-block">{client.phone}</span></>,
  //         acciones: (
  //           <button onClick={() => handleModificarPlan(client)} className={`btn ${client.moreData.activo == "1" ? "bg-primary" : "bg-success"}  text-white`}>Manejo de plan</button>
  //         )
  //       })
  //     );
  //     setState({
  //       columns: [
  //         { title: "Responsable", field: "responsable" },
  //         { title: "Empresa", field: "companyName" },
  //         { title: "Inicio plan", field: "dateRegister" },
  //         { title: "Fin plan", field: "dateExpirePlan" },
  //         { title: "Datos", field: "contact" }
  //       ],
  //       data: datos
  //     });
  //   }
  // };

  //métodos onChange
  const onChangeNit = (resp) => {
    let length = 11;
    if (path == "/departments") {
      length = 5;
    }
    if (Validate.lengthNumber(resp, length)) {
      setNit(resp);
    }
  }

  const onChangeTelefono = (resp) => {
    if (Validate.lengthNumber(resp, 11)) {
      setTelefono(resp);
    }
  }
  const onChangeWhatsapp = (resp) => {
    if (Validate.lengthNumber(resp, 11)) {
      setWhatsapp(resp);
    }
  }

  const onChangeCanal = (resp) => {
    if (Validate.lengthNumber(resp, 3)) {
      setCanales(resp);
    }
  }

  const showInstaller = (client) => {
    setModalInstaller(true);
    setSelectInstaller(client);
  }

  const handleEdit = (data) => {
    setModalEdil(true)
    switch (role) {
      case 1:
        setEditResponsable(data.responsable)
        setEditIdDepartamento(data.id)
        setEditDepartamento(data.companyName)
        setEditIdDepartamento(data.id)
        setEditTelefono(data.phone)
        setEditEmail(data.moreData.email)
        setEditNit(data.nit)
        setEditCanales(data.channels)
        break;
      case 2:
        setEditResponsable(data.responsable)
        setEditIdDepartamento(data.id)
        setEditDepartamento(data.nameDepartment)
        setEditIdDepartamento(data.id)
        setEditTelefono(data.phone)
        setEditEmail(data.email)
        setEditNit(data.companyNit)
        setEditCanales(data.channels)
        setIdentificacion(data.id)
        break;

      default:
        break;
    }
  }


  const handleUpdateData = () => {

    if (editResponsable === "") {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "Digite el nombre del responsable",
      })
      return

    }
    if (validateRegular(editResponsable) === true) {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "El nombre del responsable no debe tener caracteres especiales",
      })
      return
    }

    if (editDepartamento === "") {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "Digite el nombre del responsable",
      })
      return
    }
    if (validateRegular(editDepartamento) === true) {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "El nombre  no debe tener caracteres especiales",
      })
      return
    }

    if (editTelefono === "") {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "Digite el número de teléfono del responsable",
      })
      return
    }

    if (editEmail === "") {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "Digite el email del responsable",
      })
      return
    }

    if (editNit === "") {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "Digite el Nit",
      })
      return
    }

    clienteAxios.post(`${APP_API_URL}/admin/clients/changeDataClient`, {
      "newResponsable": editResponsable,
      "newDepartment": editDepartamento,
      "email": editEmail,
      "nit": editNit,
      "newPhone": editTelefono,
      "channels": editCanales,
      "id": role == 1 ? editNit : editIdentificacion
    },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userToken')
        }
      }
    ).then((res) => {
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Éxito',
          text: "Datos actualizados correctamente",
        })
        if (role == 1) {
          getClient()
        } else {
          getDeparments()
        }
        return
      }
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res.data.message,
      })

    }).catch(err => {
      console.log(err);
    })

  }

  const handleRecoveryPass = () => {
    if (editPassword === "") {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "Digite la contraseña",
      })
      return
    }

    clienteAxios.post(`${APP_API_URL}/auth/changePasswordAlone`, {
      "newPassword": editPassword,
      "email": editEmail
    },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userToken')
        }
      }
    ).then((res) => {
      Swal.fire({
        icon: 'success',
        title: 'Éxito',
        text: "Contraseña actualizada correctamente",

      })
    }).catch(err => {
      console.log(err);
    })
  }

  const openManualModal = () => {
    setmodalDept(true)
  }

  const closeDeptModal = () => {
    setmodalDept(false)
  }

  const onchangeChad = (e) => {
    setStateChad(e.value);
  }

  const allData = (data) => {
    console.log('data ======>', data);
    // res.data.data.clients.map((data, i) => {
    //   axios.post(`${WAPI}/islogin`, {
    //     phone: `57${data.phone}`
    //   },
    //     {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("userToken")
    //       }
    //     }
    //   ).then(response => {
    //     console.log('response wapi');
    //     if (response.data.d == null || response.data.d == "" || response.data.d == undefined) {
    //       arrData.push({
    //         ...res.data.data.clients[i],
    //         isActive: false
    //       })
    //     } else {
    //       arrData.push({
    //         ...res.data.data.clients[i],
    //         isActive: true
    //       })
    //     }
    //   }).catch(error => console.log(error))
    // })
  }

  console.log('clients ===>', clients);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title={path === "/clients" ? "Clientes" : "Departamentos"} className="text-sm-left mb-3" openManualModal={openManualModal} />
      </Row>
      <Row>
        <div style={{ width: '31%', marginTop: '10px' }} className="d-flex justify-content-between">
          <button onClick={() => role == 1 ? getClient() : getDeparments()} style={{ color: 'white', backgroundColor: '#341678', marginLeft: '10px' }} className="btn ">
            <img style={{ width: '20px' }} src={require('../assets/header/actualizar.svg')} alt="Actualizar" />
          </button>
          {(state.idResponsable == null && user.user.stateChad === 5) || role == 1 || role == 2 ? (
            <button onClick={() => openModal()} style={{ color: 'white', backgroundColor: '#8573ad', alignItems: 'center', marginLeft: '10px' }} className="btn d-flex"> {path === "/clients" ? "Nuevo cliente" : "Nuevo departamento"} <img style={{ width: '20px', marginLeft: '5px' }} src={require('../assets/header/mas.svg')} alt="Agregar" /></button>
          ) : ''}
          <Permission>
            <button onClick={() => window.print()} style={{ color: 'white', backgroundColor: '#8573ad', alignItems: 'center', marginLeft: '10px' }} className="btn d-flex">Exportar <img style={{ width: '30px', marginLeft: '5px' }} src={require('../assets/header/GUARDAR.svg')} alt="Guardar" /></button>
          </Permission>
        </div>
      </Row>

      <Row>
        <table className="table mb-0 mt-2">
          <thead className="text-white" style={{ backgroundColor: '#ff6363' }}>
            <tr>
              <Permission>
                {role != 2 && (
                  <th scope="col" className="border-0">
                    Borrar
                  </th>
                )}
              </Permission>
              <th scope="col" className="border-0">
                Editar
              </th>
              <th scope="col" className="border-0">
                Responsable
              </th>

              <th scope="col" className="border-0">
                {path === "/clients" ? "Empresa" : "Departamento o área"}
              </th>

              <th scope="col" className="border-0">
                Datos
              </th>

              {path === "/clients" && (
                <th scope="col" className="border-0">
                  Sincronizado
                </th>
              )}

              {path === "/clients" && (
                <th scope="col" className="border-0">
                  Inicio plan
                </th>
              )}
              {path === "/clients" && (
                <th scope="col" className="border-0">
                  Fin plan
                </th>
              )}
              {path === "/clients" && (
                <th scope="col" className="border-0">
                  Estado
                </th>
              )}

              {path === "/departments" && (
                <th scope="col" className="border-0">
                  Instalación
                </th>
              )}

            </tr>
          </thead>
          <tbody style={{ backgroundColor: '#fff' }}>
            {loader ? (
              <div style={{ width: '280%', textAlign: 'end' }}>
                <CircularProgress size={130} />
              </div>
            ) : (
              <>
                {clients.length === 0 && <div>{path === "/clients" ? 'No hay clientes' : 'No hay departamentos'}</div>}
                {clients.map((client, i) => (
                  <tr key={i}>
                    <Permission>
                      {role != 2 && (
                        <td>
                          <button onClick={() => handleDelete(client.nit)} style={{ color: 'white', backgroundColor: '#fe6969' }} className="btn ">
                            <i className="fas fa-trash"></i>
                          </button>
                        </td>
                      )}
                    </Permission>
                    <td>
                      <button onClick={() => handleEdit(client)} style={{ color: 'white', backgroundColor: '#fe6969' }} className="btn ">
                        <i class="fas fa-edit"></i>
                      </button>
                    </td>
                    <td>{client.responsable}</td>
                    <td>{path === "/clients" ? client.companyName : client.nameDepartment}</td>
                    {/****nameDepartment */}
                    <td>
                      <span>{path === "/clients" ? client.moreData.email : client.email}</span>
                      <span className="d-block">{client.phone}</span>
                    </td>
                    <td>{client.isActive ? <span style={{ color: "#008000" }}>Sincronizado</span> : <span style={{ color: "#FF0000" }}>No sincronizado</span>}</td>
                    {path === "/clients" && (
                      <>
                        <td>{moment(client.moreData.dateRegister).format("YYYY-MM-DD")}</td>
                        <td>{moment(client.moreData.dateExpirePlan).format("YYYY-MM-DD")}</td>
                      </>
                    )}
                    {path === "/clients" && (
                      <td>
                        <button onClick={() => handleModificarPlan(client)} className={`btn ${client.moreData.activo == "1" ? "bg-primary" : "bg-success"}  text-white`}>Manejo de plan</button>
                      </td>
                    )}

                    {path === "/departments" && (
                      <td>
                        <button onClick={() => showInstaller(client)} className={`btn bg-primary text-white`}>Instalación</button>
                      </td>
                    )}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </Row>

      <div className="form-group col-md-12 ">
        {
          modalIsOpen && (
            <Modal
              show={modalIsOpen}
              onHide={closeModal}
              centered
              scrollable

            >
              <Modal.Body>
                {modoPlan ? (
                  <>
                    <h2 className="text-center">Modificando el plan</h2>
                    <div style={{ marginBottom: '150px', display: 'flex', justifyContent: 'space-around' }}>
                      <Select
                        ref={inputPlan}
                        className="basic-single w-130"
                        classNamePrefix="select"
                        defaultValue={statePlan}
                        name="planes"
                        options={planes}
                      />
                      <Select
                        ref={inputState}
                        className="basic-single w-130"
                        classNamePrefix="select"
                        defaultValue={stateActivo}
                        name="states"
                        options={states}
                      />
                    </div>
                    <div className="d-flex justify-content-around">
                      <button onClick={() => {
                        setModoPlan(false)
                        closeModal()
                      }} className="btn bg-primary text-white">Cancelar</button>
                      <button onClick={() => handleChangePlan()} className="btn text-white" style={{ backgroundColor: '#341678' }}>Modificar</button>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 style={{
                      fontFamily: " Ubuntu",
                      fontWeight: "500",
                      fontSize: "30px"
                    }}> {path === "/clients" ? 'Agregar Cliente' : 'Agregar departamento'}</h1>

                    <div>
                      <Row>
                        <Col lg="8">
                          <label>Seleccione una opción</label>
                          <Select
                            ref={inputChad}
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={stateChad}
                            name="planes"
                            options={chad}
                            onChange={onchangeChad}
                          />
                        </Col>
                      </Row>
                      <div className="mb-3">
                        <label>Nombre*</label>
                        <input
                          style={{ border: nameError.error ? "1px solid red" : "1px solid #341678" }}
                          value={nombre}
                          onChange={(e) => setNombre(e.target.value)}
                          placeholder="Nombre"
                          type="text"
                          className="form-control"
                          maxLength="80"
                          autocomplete="off"
                        />
                        <LabelError data={nameError} />
                      </div>

                      <div className="mb-3">
                        <label>{path === "/clients" ? "Empresa*" : "Responsable*"}</label>
                        <input
                          style={{ border: empresaError.error ? "1px solid red" : "1px solid #341678" }}
                          value={empresa}
                          onChange={(e) => setEmpresa(e.target.value)} maxLength="20"
                          placeholder="Empresa"
                          type="text"
                          className="form-control"
                          maxLength="80"
                          autocomplete="off"
                        />
                        <LabelError data={empresaError} />
                      </div>

                      <div className="mb-3">
                        <label>{path === "/clients" ? "Nit*" : "Id departamento*"}</label>
                        <input
                          style={{ border: nitError.error ? "1px solid red" : "1px solid #341678" }}
                          value={nit} onChange={(e) => onChangeNit(e.target.value)} maxLength="15"
                          placeholder="Nit"
                          type="text"
                          className="form-control"
                          autocomplete="off" />
                        <LabelError data={nitError} />
                      </div>
                      <div className="mb-3">
                        <label>Teléfono*</label>
                        <input
                          style={{ border: telefonoError.error ? "1px solid red" : "1px solid #341678" }}
                          value={telefono}
                          onChange={(e) => onChangeTelefono(e.target.value)} maxLength="10"
                          placeholder="Telefono"
                          type="text"
                          className="form-control"
                          autocomplete="off"
                        />
                        <LabelError data={telefonoError} />
                      </div>
                      {
                        path === "/clients" ? //Solamente para el perfil 2
                          stateChad === 2 || stateChad === 3 || stateChad === 4 || stateChad === 5 ?
                            <div className="mb-3">
                              <label>Número para WhatsApp</label>
                              <input
                                style={{ border: telefonoError.error ? "1px solid red" : "1px solid #341678" }}
                                value={whatsapp}
                                onChange={(e) => onChangeWhatsapp(e.target.value)} maxLength="10"
                                placeholder="WhatsApp"
                                type="text"
                                className="form-control"
                                autocomplete="off"
                              />
                              <LabelError data={telefonoError} />
                            </div>
                            : null
                          : null
                      }
                      <div className="mb-3">
                        <label>Correo*</label>
                        <input autocomplete="off" style={{ border: correoError.error ? "1px solid red" : "1px solid #341678" }} value={correo} onChange={(e) => setCorreo(e.target.value)} maxLength="76" placeholder="Correo" type="email" className="form-control" />
                        <LabelError data={correoError} />
                      </div>
                      <div className="mb-3">
                        <label>Dominio*<span>
                          <Tooltip title="Ejemplo chatome.com.co" maxLength="253" placement="top-start">
                            <span><i class="fas fa-question-circle"></i></span>
                          </Tooltip>

                        </span></label>
                        <input autocomplete="off" style={{ border: correoError.error ? "1px solid red" : "1px solid #341678" }} value={host} onChange={(e) => setHost(e.target.value)} placeholder="ejemplo.com" type="text" className="form-control" />
                        <LabelError data={hostError} />
                      </div>

                      <div className={`${path === "/clients" ? "d-flex justify-content-around " : ''}`}>
                        {
                          path === "/clients" && (
                            <div className="mb-3">
                              <label>Plan*</label>
                              <Select
                                ref={inputPlan}
                                className="basic-single w-130"
                                classNamePrefix="select"
                                defaultValue={planes[0]}
                                name="planes"
                                options={planes}
                              />
                            </div>
                          )
                        }
                        <div className="mb-3">
                          <label htmlFor="Canales">Canales del departamento*
                            {path === '/departments' && (<span>
                              <Tooltip title="De tus canales, puedes asignarle a tus departamentos." placement="top-start">
                                <span><i class="fas fa-question-circle"></i></span>
                              </Tooltip>

                            </span>)}
                          </label>
                          <input
                            style={{ border: canalesError.error ? "1px solid red" : "1px solid #341678" }}
                            value={canales} onChange={(e) => onChangeCanal(e.target.value)}
                            placeholder="Canales"
                            type="email"
                            className="form-control"
                            autocomplete="off"
                          />
                          <LabelError data={canalesError} />
                        </div>
                      </div>



                      <label>Crear contraseña*</label>
                      <div className="form-row row">

                        <div className="form-group col-md-10">

                          <input
                            style={{ border: passError.error ? "1px solid red" : "1px solid #341678" }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Telefono"
                            maxLength="20"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            autocomplete="off"

                          />
                          <LabelError data={passError} />
                        </div>
                        <div className="form-group col-md-2" style={{ justifyContent: 'center' }}>
                          <button onClick={() => setShowPassword(!showPassword)} style={{ color: 'white', backgroundColor: '#8573AD' }} className="btn ">
                            <i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                          </button>
                        </div>

                      </div>
                      <div className="d-flex justify-content-around">
                        <button onClick={closeModal} className="btn bg-primary text-white w-130">Cancelar</button>
                        <button onClick={() => handleSaveClient()} className="btn text-white w-130" style={{ backgroundColor: '#341678' }}>
                          {path === "/clients" ? 'Crear cliente' : 'Crear departamento'}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </Modal.Body>
            </Modal>
          )
        }
      </div>

      <div className="form-group col-md-12 ">
        {
          modalInstaller && (
            <Modal
              show={modalInstaller}
              onHide={closeModalInstaller}
              centered
              scrollable
              size="lg"

            >
              <Modal.Body>
                <Installer client={selectInstaller} />
              </Modal.Body>
            </Modal>
          )
        }
      </div>

      <Modal
        show={modalEdit}
        onHide={closeModalInstaller}
        centered
        scrollable
        size="lg"

      >
        <Modal.Body>
          <h5>Editar datos</h5>
          <hr />
          <div className="mb-3">
            <Row>
              <Col sm="6">
                <label>Nit</label>
                <input
                  style={{ border: nameError.error ? "1px solid red" : "1px solid #341678" }}
                  value={editNit}
                  onChange={(e) => setEditNit(e.target.value)}
                  placeholder="Nit"
                  type="text"
                  className="form-control"
                  maxLength="10"
                  autocomplete="off"
                  disabled={true}
                />
                <LabelError data={nameError} />
              </Col>
              <Col sm="6">
                <label>Responsable</label>
                <input
                  style={{ border: nameError.error ? "1px solid red" : "1px solid #341678" }}
                  value={editResponsable}
                  onChange={(e) => setEditResponsable(e.target.value)}
                  placeholder="Responsable"
                  type="text"
                  className="form-control"
                  maxLength="80"
                  autocomplete="off"
                />
                <LabelError data={nameError} />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                <label>{role == 1 ? "Cliente" : "Departamento"}</label>
                <input
                  style={{ border: nameError.error ? "1px solid red" : "1px solid #341678" }}
                  value={editDepartamento}
                  onChange={(e) => setEditDepartamento(e.target.value)}
                  placeholder="Departamento"
                  type="text"
                  className="form-control"
                  maxLength="80"
                  autocomplete="off"
                />
                <LabelError data={nameError} />
              </Col>
              <Col sm="12" lg="6">
                <label>Canales</label>
                <input
                  style={{ border: nameError.error ? "1px solid red" : "1px solid #341678" }}
                  value={editCanales}
                  onChange={(e) => setEditCanales(e.target.value)}
                  placeholder="Canales"
                  type="text"
                  className="form-control"
                  maxLength="80"
                  autocomplete="off"
                  disabled
                />
                <LabelError data={nameError} />
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <Row>
              <Col sm="6">
                <label>Teléfono</label>
                <input
                  style={{ border: nameError.error ? "1px solid red" : "1px solid #341678" }}
                  value={editTelefono}
                  onChange={(e) => setEditTelefono(e.target.value)}
                  placeholder="Teléfono"
                  type="text"
                  className="form-control"
                  maxLength="10"
                  autocomplete="off"
                  disabled
                />
                <LabelError data={nameError} />
              </Col>
              <Col sm="6">
                <label>Correo electrónico</label>
                <input
                  style={{ border: nameError.error ? "1px solid red" : "1px solid #341678" }}
                  value={editEmail}
                  onChange={(e) => setEditEmail(e.target.value)}
                  placeholder="Email"
                  type="text"
                  className="form-control"
                  maxLength="80"
                  autocomplete="off"
                  disabled={true}
                />
                <LabelError data={nameError} />
              </Col>
            </Row>
            <br />
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <Button onClick={handleUpdateData}>Actualizar datos</Button>
            </div>
          </div>
          <hr />
          <div className="mb-3">
            <label style={{ fontWeight: "bold" }}>Restablecer contraseña</label>
            <Row>
              <Col sm="6">
                <input
                  style={{ border: nameError.error ? "1px solid red" : "1px solid #341678" }}
                  value={editPassword}
                  onChange={(e) => setEditPassword(e.target.value)}
                  placeholder="Contraseña"
                  type="password"
                  className="form-control"
                  maxLength="80"
                  autocomplete="off"
                />
                <LabelError data={nameError} />
              </Col>
              <Col sm="6">
                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                  <Button onClick={handleRecoveryPass}>Actualizar contraseña</Button>
                  <Button style={{ backgroundColor: '#341678', borderColor: '#341678' }} onClick={() => setModalEdil(false)}>Cerrar</Button>
                </div>
              </Col>
            </Row>
          </div>

        </Modal.Body>
      </Modal>

      <div className="form-group col-md-12 ">
        <Modal
          show={modalDept}
          onHide={closeDeptModal}
          centered
          scrollable
          size="lg"
        >
          <Modal.Body style={{ height: '2500px' }}>
            <iframe src="https://chatome.com.co/media/manuales/dashboard/perfil-2/departamentos.pdf" style={{ width: '100%', height: "100%" }} title="Iframe Dashboard"></iframe>
          </Modal.Body>
        </Modal>
      </div>

    </Container >
  );
}

export default Clients;
