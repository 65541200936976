import {
  LOGIN,
  LOGOUT,
  SAVE_QUESTION_BOT,
  SAVE_QUESTIONS_BOT,
  DELETE_QUESTIONS_BOT,
  SETEANDO_OPCIONES_TRIGGER,
  SELECT_TRIGGER,
  GUARDAR_ID_RESPONSABLE
} from "../types";



const saveImeiSelecteds = (imeiSelecteds, gps, nameGPS, valueGPS) => {
  const exctractIndex = (data, indicador) => imeiSelecteds.map((e) => e[indicador]).indexOf(data);

  let arr = imeiSelecteds
  if (imeiSelecteds.length > 0) {
    gps.list.forEach((element) => {
      const i = exctractIndex(element.value, 'selected')
      const e = exctractIndex(nameGPS, 'input')
      if (e === -1 && i !== -1) {
        if (i !== -1) {
          arr.push({
            ...element,
            name: nameGPS,
            selected: element.value,
          })
        }
      } else if (e !== -1) {
        arr[e] = { name: nameGPS, selected: valueGPS }
      }

    })
  } else {
    arr.push({
      name: nameGPS,
      selected: valueGPS,
    })
  }
  return arr
}

const disabledImeiGPS = (gps, imeiSelecteds) => {
  return gps.list.map((imeiGPS) => {
    if (imeiSelecteds.some(e => e.selected === imeiGPS.value)) {
      return { ...imeiGPS, disabled: true };
    }
    return { ...imeiGPS, disabled: false };
  });
}

const selectTagGPS = (gps, valueGPS) => {
  return gps.list.map((imeiGPS) => {
    if (imeiGPS.uuid === valueGPS) {
      return { ...imeiGPS, disabled: true };
    }
    return { ...imeiGPS, disabled: false };
  });
}


//cada reducer tiene su propio state
const initialState = {
  user: {},
  questions: [],
  triggers: [],
  selectTrigger: [],
  idResponsable: null
};
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
      };
    case SAVE_QUESTION_BOT:
      return {
        ...state,
        questions: [...state.questions, action.payload]
      }
    case SAVE_QUESTIONS_BOT:
      return {
        ...state,
        questions: action.payload
      }
    case DELETE_QUESTIONS_BOT:
      return {
        ...state,
        questions: state.questions.filter(question => question.id !== action.payload)
      }
    case SETEANDO_OPCIONES_TRIGGER:
      return {
        ...state,
        triggers: action.payload
      }
    case GUARDAR_ID_RESPONSABLE:
      return {
        ...state,
        idResponsable: action.payload
      }
    case SELECT_TRIGGER:
      const valueGPS = action.payload.value;
      const nameGPS = action.payload.input;
      const imeiSelecteds = saveImeiSelecteds(state.selectTrigger, state.triggers, nameGPS, valueGPS)
      const gpsImei = disabledImeiGPS(state.triggers, imeiSelecteds)
      const tag = selectTagGPS(state.triggers, valueGPS)

      return {
        ...state,
        selectTrigger: {
          id: tag[0].uuid,
          name: tag[0].tag,
          uuid: tag[0].uuid,
        },
        triggers: gpsImei,
      };
    default:
      return state;
  }
}
