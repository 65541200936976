import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, TestLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Login from "./views/Login";
import Clients from "./views/Clients";
import Config from "./views/Config";
import UserListForm from "./views/UserListForm";
import Installer from "./views/Installer";
import TrackingListState from "./views/TrackingListState";
import UserProfile from "./views/UserProfileLite";
import Requests from "./views/Requests";
import Requests2 from "./views/Requests2"
import Requests3 from "./views/Request3"
import Agents from "./views/Agents";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/login",
    layout: TestLayout,
    exact: true,
    component: Login
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    exact: true,
    component: BlogOverview
  },
  {
    path: "/clients",
    layout: DefaultLayout,
    exact: true,
    component: Clients
  },
  {
    path: "/config",
    layout: DefaultLayout,
    exact: true,
    component: Config
  },
  {
    path: "/Installer",
    layout: DefaultLayout,
    exact: true,
    component: Installer
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/list-form",
    layout: DefaultLayout,
    component: UserListForm
  }, {
    path: "/whatsapp",
    layout: DefaultLayout,
    exact: true,
    component: Config
  },
  {
    path: "/departments",
    layout: DefaultLayout,
    exact: true,
    component: Clients
  },
  {
    path: "/agents",
    layout: DefaultLayout,
    exact: true,
    component: Agents
  },
  {
    path: "/tracking-list-state",
    layout: DefaultLayout,
    exact: true,
    component: TrackingListState
  },
  {
    path: "/tracking-list-state-one",
    layout: DefaultLayout,
    exact: true,
    component: TrackingListState
  },
  {
    path: "/profile",
    layout: DefaultLayout,
    exact: true,
    component: UserProfile
  },
  {
    path: "/tracking-list-state-tree",
    layout: DefaultLayout,
    exact: true,
    component: TrackingListState
  },
  {
    path: "/tracking-list-state-two",
    layout: DefaultLayout,
    exact: true,
    component: TrackingListState
  },
  {
    path: "/tracking-list-state-four",
    layout: DefaultLayout,
    exact: true,
    component: TrackingListState
  },
  {
    path: "/request",
    layout: DefaultLayout,
    exact: true,
    component: Requests
  },
  {
    path: "/request2",
    layout: DefaultLayout,
    exact: true,
    component: Requests2
  },
  {
    path: "/request3",
    layout: DefaultLayout,
    exact: true,
    component: Requests3
  }
];
