import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Navbar } from "shards-react";

import NavbarNav from "./NavbarNav/NavbarNav";

const MainNavbar = ({ layout, stickyTop }, props) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  return (
    <div className={classes}>
      <div style={{margin: '0px 20px'}} className="p-0">
        <Navbar  type="light" className="justify-content-end align-items-stretch flex-md-nowrap p-0">
          {/* <div className="d-flex" style={{ justifyContent: 'space-evenly', width: '20%'}}>
          <Link to='/clients' className="d-flex" style={{ alignItems: 'center'}}>
            <img src={require('../../../assets/header/CLIENTES.svg')} style={{width: '25px'}} alt=""/>
            <span>Clientes</span>
          </Link>
          <Link to='/clients' className="d-flex"  style={{ alignItems: 'center'}}>
            <img src={require('../../../assets/header/EN-VIVO.svg')} style={{width: '25px'}} alt=""/>
            <span>En vivo (12)</span>
          </Link>
          </div> */}
          {/* <NavbarSearch /> */}
          <NavbarNav />
        </Navbar>
      </div>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
