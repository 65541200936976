
const validateNumber = (value) => {
    let regexNum = /^[0-9]*$/;
    let resp = false;
    if(regexNum.test(value)){
        resp = true;
    }
    return resp;
}

const lengthNumber = (number, length) => {
    let resp = false;
    if(validateNumber(number) && number.length < length){
        resp = true;
    }
    return resp;
}

const validateEmail = (value) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let resp = false;
    if(reg.test(value)){
        resp = true;
    }
    return resp;
}

const ValidateInput = {
    validateNumber,
    lengthNumber,
    validateEmail,
}

export default ValidateInput;