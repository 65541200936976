import React from 'react'
import './index.css'

const ChatBot = ({ background, arrListForm, mesajeBienvenida, mensajeSecundario, siwtch, imagenes }) => {
  return (
    <>
      <div className="zIndex" style={{
        justifyContent: 'center',
        display: 'flex',
        padding: '10px'
      }} id="body">

        <div className="chat-box zIndex" >
          <div className="chat-box-header-chat-tome zIndex" style={{ backgroundColor: background.header }} >
            Chad
                <span className="chat-box-toggle-chat-tome">
              <img style={{ width: '30px' }} src="https://i.ibb.co/LNc6QDC/LINEA.png" alt="" />
            </span>
          </div>
          <div className="chat-box-body-chat-tome zIndex">
            <div className="chat-box-overlay-chat-tome">
            </div>
            <div className="chat-logs-chat-tome zIndex">
              <div id="mensaje-bienvenida-chat-tome">

                {siwtch ? (<div>

                  <div id='cm-msg-${INDEX}' className="chat-msg user">
                    <span className="msg-avatar">
                      {imagenes && (<img src='https://i.ibb.co/QPFtqr5/ICONO-CHAD.png' />)}
                    </span>

                    <div className="cm-msg-text" style={{ backgroundColor: background.chad_box_message }}>
                      <span>Hola, esto es un mensaje de prueba</span>
                      <div className="cm-msg-button">
                        <ul style={{ padding: "0px" }}>
                          <li className="button disabled${INDEX}" style={{ backgroundColor: background.chad_box_options }} >
                            <div className="btn chat-btn" onclick="deshabilitaDiv(${INDEX})" chat-value="${button.trigger}">
                              <span style={{ color: "white", fontWeight: "700" }} href="javascript:;">Opción 1 de prueba</span>
                            </div>
                          </li>
                          <li className="button disabled${INDEX}" style={{ backgroundColor: background.chad_box_options }} >
                            <div className="btn chat-btn" onclick="deshabilitaDiv(${INDEX})" chat-value="${button.trigger}">
                              <span style={{ color: "white", fontWeight: "700" }} href="javascript:;">Opción 2 de prueba</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/** RESPONSE SUSER */}
                    <div id='cm-msg-${INDEX}' class="chat-msg self">
                      <span class="msg-avatar">
                        {imagenes && (<img src='https://i.ibb.co/fxLnWnb/ICONO-USUARIO.png' />)}
                      </span>

                      <div class="cm-msg-text" style={{ backgroundColor: background.user_box_message, border: `2px solid ${background.user_box_border}` }}>
                        Opción 1 de prueba
                        </div>
                    </div>
                  </div>

                </div>) : (<>
                  <h1 className="h1-chat-tome" style={{ color: background.msg_primary }}>{mesajeBienvenida}
                  </h1>
                  <h3 className="h3-chat-tome" style={{ color: background.msg_secondary }}>{mensajeSecundario}</h3>

                  <div id="chattome-inputs-form">

                    {arrListForm.map(field => (
                      <input disabled className="input-chattome-bot" key={field.field} maxlength="300" placeholder={field.field} name={field.field} id={`input_chatome_${field.field}`} style={{
                        padding: '10px', width: '100%', border: 'none', bordeRadius: '10px', marginBottom: '10px', color: 'black', backgroundColor: background.input
                      }} type="text" />
                    ))}

                  </div>

                  <div className="form-check">
                    <input disabled className="form-check-input" type="checkbox" value="true" id="chattome-checkbox-form" />
                    <label className="form-check-label" for="chattome-checkbox-form"
                      style={{ color: '#341677', textAlign: 'center' }}>
                      He leido y aceptado <span style={{ color: background.msg_primary }}>politica de tratamiento de datos personales</span>
                    </label>
                  </div>
                </>)}
              </div>
            </div>
          </div>
          <div className="chat-input">
            <form>

              <div id="chattome-boton-form">
                <button style={{
                  width: '100%',
                  color: 'white',
                  backgroundColor: background.footer,
                  border: 'none',
                  padding: '10px 10px',
                  borderRadius: '10px',
                  fontSize: '18px',
                  cursor: 'pointer'
                }} type="button" id="chattome-boton">
                  Enviar
                        </button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </>
  );
}

export default ChatBot;
