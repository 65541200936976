import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Table } from "react-bootstrap";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import Swal from 'sweetalert2'
import clienteAxios from '../config/axios';
import Select from 'react-select';
import moment from 'moment';
import { APP_API_URL } from '../config';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from "../components/common/PageTitle";
import { grey } from '@material-ui/core/colors';
import ChatBot from '../components/Chatbot';
import { TwitterPicker } from 'react-color';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const UserListForm = () => {
  const user = useSelector(state => state.auth.user)
  const state = useSelector(state => state.auth)
  const role = user.user.role

  const inputSelectEstado = createRef()
  const inputSelectDepartments = createRef()

  //panel

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const dispatch = useDispatch()
  // const [validateBtnNew, setvalidateBtnNew] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [siwtch, setSwitch] = useState(false)
  const [modalIsEditOpen, setIsEditOpen] = useState(false)
  const [arrForm, setArrForm] = useState([{ id: 0, title: "Country/Ciudad", field: "country", required: true }, { id: 1, title: "Telephone/Teléfono", field: "telephone", required: true }, { id: 2, title: "Nombre completo", field: "completeName", required: true }])
  const [inputForm, setInputForm] = useState("")
  const [arrListForm, setArrListForm] = useState([])
  const [dateCreateForm, setDateCreateForm] = useState("")
  const [idEditForm, setIDEditForm] = useState("")
  const [titleEditForm, setTitleEditForm] = useState("")
  const [requiredEditForm, setRequiredEditForm] = useState("")
  const [selectEstado, setSelectEstado] = useState("")
  const [selectDepartments, setSelectDepartments] = useState("")
  const [arrDepartments, setArrDepartments] = useState([])
  const [departments, setDepartments] = useState("")
  const [mesajeBienvenida, setMensajeBienvenida] = useState("¡Te damos la bienvenida, a tu centro de soluciones!")
  const [mensajeSecundario, setMensajeSecundario] = useState("Para chatear con alguien de nuestro equipo de soporte, por favor deligencia el siguiente formato.")
  const [background, setBackground] = useState({
    footer: '#ff6363',
    msg_primary: '#ff6363'
  })
  const [link, setLink] = useState('')
  const [imagenes, setImagenes] = useState(false)
  const [error, setError] = useState({ error: false, message: "" })

  const estados = [
    { value: true, label: 'Habilitado' },
    { value: false, label: 'Deshabilitado' },
  ]

  const [modalForm, setmodalForm] = useState(false)

  useEffect(() => {
    if (role === 2) {
      getListDepartments();
    }
    else if (role === 3) {
      setDepartments(user.user.id)
      getListForm()
    }

  }, [])

  const handleSearch = (depart) => {
    //Validamos que el campo no esté vacío
    // if(inputSelectDepartments.current != null && inputSelectDepartments.current.state.value === null){
    //     Swal.fire({
    //         icon: 'warning',
    //         title: '¡Espera!',
    //         text: "Seleccione un departamento"
    //     })
    //     return
    // }
    setDepartments(depart);
    getListForm(depart)
  }

  const getListDepartments = () => {
    clienteAxios.get(`${APP_API_URL}/admin/departments/${role == 3 ? user.user.companyNit : user.user.dataUser.nit}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }
    ).then((res) => {
      let arrDepart = [];
      res.data.data.deparments.map((department) => {
        arrDepart.push({ value: department.id, label: department.nameDepartment });
      });
      setArrDepartments(arrDepart);
      setSelectDepartments(arrDepart);
    }).catch(err => {
      console.log(err);
      window.location.reload();
    })
  }
  const getConfigBot = (department) => {
    const url = user.user.stateChad == 5 ? `${APP_API_URL}/admin/departments/config/${user.user.dataUser.nit}/${state.idResponsable}` : `${APP_API_URL}/admin/departments/config/${user.user.companyNit}/${user.user.id}`
    clienteAxios.get(url, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }).then((res) => {
      const json = res.data.data;
      if (json.config !== null) {
        setMensajeBienvenida(json.config.welcomeMessage)
        setMensajeSecundario(json.config.secondMessage)
        setBackground(json.config.colors)
        setLink(json.config.link === undefined ? "" : json.config.link)
      }
    }).catch(err => {
      console.log(err);
      window.location.reload();
    })
  }

  useEffect(() => {
    if (role === 3 || user.user.stateChad == 5) {
      getConfigBot()
    }
  }, [])

  const getListForm = (department) => {
    clienteAxios.get(`${APP_API_URL}/admin/form/${role === 2 ? user.user.dataUser.nit : user.user.companyNit}/${role === 2 ? department : user.user.id}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }).then((res) => {
      const json = res.data.data;
      if (json.form.length === 0) {
        setDateCreateForm("")
        setArrListForm([])
        return
      }
      setDateCreateForm(json.form[0].date);
      setArrListForm(json.form[0].options);
    }).catch(err => {
      console.log(err);
      window.location.reload();
    })
  }
  //Para remover caracteres especiales y espacio. Para la opción "field" de los formularios
  const removeAccentsAndSpaces = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "_");
  };
  //Acción para abrir el modal
  const handleModal = () => {
    if (role === 2) {
      //Validamos que el campo no esté vacío
      if (inputSelectDepartments.current.state.value === null) {
        Swal.fire({
          icon: 'warning',
          title: '¡Espera!',
          text: "Debes seleccionar primero un departamento"
        })
        return
      }
      setDepartments(inputSelectDepartments.current.state.value.value)
    }
    openModal()
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const openManualModal = () => {
    setmodalForm(true);
  }

  const closeModalForm = () => {
    setmodalForm(false);
  }

  //Adicionando campos al listao del Form
  const addForm = () => {
    //Validamos que el campo no esté vacío
    if (inputForm === "") {
      Swal.fire({
        icon: 'warning',
        title: '¡Espera!',
        text: "El campo no puede estar vacío"
      })
      return
    }
    //Validamos que el nombre del campo esté en el listado
    const searchForm = arrForm.filter(form => form.title === inputForm);
    if (searchForm.length > 0) {
      Swal.fire({
        icon: 'warning',
        title: '¡Espera!',
        text: "Este campo ya existe en el listado"
      })
      setInputForm("")
      return
    }
    //Validamos que solo se puedna agregar 5 campos incluyendo los 3 opcionales
    if (arrForm.length === 5) {
      Swal.fire({
        icon: 'warning',
        title: '¡Espera!',
        text: "Solo puede agregar 5 campos en este listado"
      })
      return
    }
    //Validando después de los campos obligatorios
    if (arrForm.length === 2) {
      //Agregando los campos en el array
      arrForm.push({ id: 2, title: inputForm, field: removeAccentsAndSpaces(inputForm), required: true })
    } else {
      //Agregando los campos en el array hasta llegar al límite
      arrForm.push({ id: arrForm.length, title: inputForm, field: removeAccentsAndSpaces(inputForm), required: true })
    }
    // setvalidateBtnNew(true)
    setInputForm("")
    setArrForm(arrForm)
  }
  //Onchange del input de ingreso para el formulario
  const inputOnChangeForm = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    setInputForm(value);
  }
  //Eliminando la ultima posición del listado del formulario
  const removeForm = (pos) => {
    const removeArr = arrForm.splice(0, pos)
    setArrForm(removeArr)
  }
  //Guardando los registros del listado del formulario
  const handleSave = () => {
    clienteAxios.post(`${APP_API_URL}/admin/form`, {
      "nit": role === 2 ? user.user.dataUser.nit : user.user.companyNit,
      "idresponsable": departments,//Sería el del usuario
      "contact": false,
      "options": arrForm
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }
      }
    ).then((res) => {
      const json = res.data
      if (json.data != undefined && json.data.form === "OK") {
        setArrForm("")
        setIsOpen(false)
        getListForm()
        Swal.fire({
          icon: 'success',
          title: '¡Buenas noticias!',
          text: json.message
        })
      } else {
        Swal.fire({
          icon: 'warning',
          title: '¡Espera!',
          text: json.message
        })
      }
    }).catch(err => console.log(err))
  }
  //Modal para editar los campos
  const handleModalEdit = (id, title, required) => {
    setIDEditForm(id);
    setTitleEditForm(title);
    setRequiredEditForm(required);
    setIsEditOpen(true);
    setSelectEstado(required);
  }
  const closeEditModal = () => {
    setIsEditOpen(false);
  }
  //Onchange del input de ingreso para el formulario
  const inputOnChangeEditTitleForm = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    setTitleEditForm(value);
  }
  const validateRegular = (data) => {
    let errors = false;
    const keys = Object.keys(data)
    const Regex = /[-_^*?\[\]]/
    if (Regex.test(data)) {
      errors = true
    }
    return errors
  }
  //Acción para editar el del formulario seleccionado
  const handleEdit = () => {
    //Validamos que el campo no esté vacío
    if (titleEditForm === "") {
      Swal.fire({
        icon: 'warning',
        title: '¡Espera!',
        text: "El campo no puede estar vacío para su edición"
      })
      return
    }
    //Validamos que el select esté seleccionado
    if (inputSelectEstado.current.state.value === null) {
      Swal.fire({
        icon: 'warning',
        title: '¡Espera!',
        text: "Por favor, seleccione el estado para el campo"
      })
      return
    }
    clienteAxios.post(`${APP_API_URL}/admin/form/updateForm`, {
      "nit": role === 2 ? user.user.dataUser.nit : user.user.companyNit,
      "idresponsable": departments,
      "id": idEditForm,
      "title": titleEditForm,
      "required": inputSelectEstado.current.state.value.value
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }
      }
    ).then((res) => {
      const json = res.data
      if (json.data != undefined && json.data.form === "OK") {
        setTitleEditForm("")
        setIsEditOpen(false)
        getListForm()
        Swal.fire({
          icon: 'success',
          title: '¡Buenas noticias!',
          text: json.message
        })

      } else {
        Swal.fire({
          icon: 'warning',
          title: '¡Espera!',
          text: json.message
        })
      }
    }).catch(err => console.log(err))
  }

  const OnchangeDepartments = (e) => {
    handleSearch(e.value);
  }
  const handleSaveConfig = () => {
    if (mesajeBienvenida.trim() === '' || mensajeSecundario.trim() === '' || link.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: '¡Espera!',
        text: "Todos los campos son requeridos"
      })
      return
    }
    if (error.error) {
      Swal.fire({
        icon: 'error',
        title: '¡Espera!',
        text: "Ingrese una ulr correcta"
      })
      return
    }
    clienteAxios.post(`${APP_API_URL}/admin/departments/config`, {
      "nit": user.user.stateChad == 5 ? user.user.dataUser.nit : user.user.companyNit,
      "idResponsable": user.user.stateChad == 5 ? state.idResponsable : user.user.id,
      "welcomeMessage": mesajeBienvenida,
      "secondMessage": mensajeSecundario,
      "colors": background,
      "link": link,
      "images": imagenes
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }
      }
    ).then((res) => {
      const json = res.data
      Swal.fire({
        icon: 'success',
        title: '¡Buenas noticias!',
        text: 'Configuración guardada con éxito'
      })
    }).catch(err => console.log(err))
  }
  const handleChangeComplete = (color, name) => {
    setBackground({ ...background, [name]: color.hex });
  };
  const handleLInk = (e) => {

    setLink(e.target.value)
    if (!/^(ftp|http|https):\/\/[^ "]+$/.test(e.target.value)) {
      setError({ error: true, message: "Digite una url con el formato https://example.com" })
    } else {
      setError({ error: false, message: "" })
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Gestión del formulario" subtitle="Listado de campos" className="text-sm-left" openManualModal={openManualModal} />
      </Row>

      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <Row>
                <Col>
                  {
                    /**Validando el perfil del usuario para mostrar o no los filtros  */
                    role === 2 ?
                      <div>
                        <label>Departamento</label>
                        <Select
                          ref={inputSelectDepartments}
                          className="basic-single"
                          classNamePrefix="Departamentos"
                          defaultValue={selectDepartments[0]}
                          name="Departamentos"
                          options={arrDepartments}
                          onChange={OnchangeDepartments}
                        />
                      </div>
                      : null
                  }
                </Col>

                <Col>
                  <br />
                  {
                    /**Validando Si hay registros del formulario, que no muestre el button, si hay datos, muestra la fecha de creación del formulario*/
                    arrListForm != undefined && arrListForm.length === 0 ?
                      <button onClick={() => handleModal()} type="button" className="btn btn-success btn-sm">Nuevo</button>
                      : <span className="badge badge-primary">Fecha creación: {moment(dateCreateForm).format("YYYY-MM-DD HH:mm:ss")}</span>
                  }
                </Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table className="table table-responsive-lg mb-0 mt-2">
                <thead className="text-white" style={{ backgroundColor: '#ff6363' }}>
                  <tr>
                    <th>#</th>
                    <th>Nombre de la etiqueta</th>
                    {/** <th>Nombre del campo</th> */}
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    arrListForm != undefined && arrListForm.length > 0 ?
                      arrListForm.map((list, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              {list.id + 1}
                            </td>
                            <td>
                              {list.title}
                            </td>
                            {/** <td>
                                                    {list.field}
                                                </td> */}
                            <td>
                              {
                                list.required === true ?
                                  <span className="badge badge-success">Habilitado</span>
                                  :
                                  <span className="badge badge-danger">Deshabilitado</span>
                              }
                            </td>
                            <td>
                              {
                                list.id != 0 && list.id != 1 ?
                                  <button onClick={() => handleModalEdit(list.id, list.title, list.required)} className="btn btn-dark btn-sm">Editar</button>
                                  :
                                  null
                              }

                            </td>
                          </tr>
                        )
                      })
                      :
                      <tr>
                        <td colSpan="4">No hay campos registrados</td>
                      </tr>
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {role === 3 || user.user.stateChad == 5 ? (
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Chatbot" subtitle="Configuracion de mensajes" className="text-sm-left" />
        </Row>
      ) : null}

      {role === 3 || user.user.stateChad == 5 ? (
        <Row>
          <Col>
            <Card small className="mb-4">
              {!siwtch ? (<>
                <Row>
                  <Col>
                    <div style={{ padding: '10px' }}>
                      <div>
                        <label htmlFor="">Mensaje de bienvenida</label>
                        <input className="form-control" value={mesajeBienvenida} onChange={(e) => setMensajeBienvenida(e.target.value)} />
                      </div>
                      <div className="my-2">
                        <label htmlFor="">Mensaje de secundario</label>
                        <input className="form-control" value={mensajeSecundario} onChange={(e) => setMensajeSecundario(e.target.value)} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <span style={{ padding: '20px', fontWeight: '900' }}>Colores</span>
                  <hr style={{
                    width: '95%', margin: 'auto',
                    marginBottom: '14px'
                  }} />
                  <Col lg="12"  >
                    <Accordion style={{ padding: '10px', marginBottom: '10px' }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <p className={classes.heading}>Header / Footer</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Col lg="6">
                          <span>Header</span>
                          <div className="mt-2">
                            <TwitterPicker color={background}
                              onChangeComplete={(color) => handleChangeComplete(color, 'header')} />
                          </div>
                        </Col>
                        <Col lg="6">
                          <span>Footer</span>
                          <div className="mt-2">
                            <TwitterPicker color={background}
                              onChangeComplete={(color) => handleChangeComplete(color, 'footer')} />
                          </div>
                        </Col>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ padding: '10px', marginBottom: '10px' }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                      >
                        <p className={classes.heading}>Mensajes</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Col lg="6">
                          <span>Mensaje primario</span>
                          <div className="mt-2">
                            <TwitterPicker color={background}
                              onChangeComplete={(color) => handleChangeComplete(color, 'msg_primary')} />
                          </div>
                        </Col>
                        <Col lg="6">
                          <span>Mensaje secundario</span>
                          <div className="mt-2">
                            <TwitterPicker color={background}
                              onChangeComplete={(color) => handleChangeComplete(color, 'msg_secondary')} />
                          </div>
                        </Col>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ padding: '10px', marginBottom: '10px' }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6bh-content"
                        id="panel6bh-header"
                      >
                        <p className={classes.heading}>Inputs</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Col lg="6">
                          <span>Input</span>
                          <div className="mt-2">
                            <TwitterPicker color={background}
                              onChangeComplete={(color) => handleChangeComplete(color, 'input')} />
                          </div>
                        </Col>
                      </AccordionDetails>
                    </Accordion>


                  </Col>
                </Row>

                <Row>
                  <span style={{ padding: '20px', fontWeight: '900' }}>Adicionales</span>
                  <hr style={{
                    width: '95%', margin: 'auto',
                    marginBottom: '14px'
                  }} />
                  <Col lg="12" style={{ padding: '20px' }}>
                    <label htmlFor="">Link de politicas</label>
                    <input className="form-control" value={link} onChange={(e) => handleLInk(e)} />
                    {error.error && <span style={{ color: "red" }}>{error.message}</span>}
                    <div style={{ padding: '10px' }}>
                      <Button onClick={handleSaveConfig}>Guardar</Button>
                    </div>
                  </Col>
                </Row>
              </>) : (
                <div>
                  <Row>
                    <span style={{ padding: '20px', fontWeight: '900' }}>Colores</span>
                    <hr style={{
                      width: '95%', margin: 'auto',
                      marginBottom: '14px'
                    }} />
                    <Col lg="12"  >
                      <div>
                        <span style={{ padding: '20px' }}>¿Usar imagenes?<Switch
                          checked={imagenes}
                          disabled
                          onChange={() => setSwitch(!siwtch)}
                          name="checkedA"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        /></span>
                      </div>
                      <Accordion style={{ padding: '10px', marginBottom: '10px' }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4bh-content"
                          id="panel4bh-header"
                        >
                          <p className={classes.heading}>Chad</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Col lg="6">
                            <span>Caja de mensaje</span>
                            <div className="mt-2">
                              <TwitterPicker color={background}
                                onChangeComplete={(color) => handleChangeComplete(color, 'chad_box_message')} />
                            </div>
                          </Col>
                          <Col lg="6">
                            <span>Caja de opciones</span>
                            <div className="mt-2">
                              <TwitterPicker color={background}
                                onChangeComplete={(color) => handleChangeComplete(color, 'chad_box_options')} />
                            </div>
                          </Col>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion style={{ padding: '10px', marginBottom: '10px' }} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel7bh-content"
                          id="panel7bh-header"
                        >
                          <p className={classes.heading}>Usuario</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Col lg="6">
                            <span>Borde caja de mensaje</span>
                            <div className="mt-2">
                              <TwitterPicker color={background}
                                onChangeComplete={(color) => handleChangeComplete(color, 'user_box_border')} />
                            </div>
                          </Col>
                          <Col lg="6">
                            <span>Fondo caja de mensaje</span>
                            <div className="mt-2">
                              <TwitterPicker color={background}
                                onChangeComplete={(color) => handleChangeComplete(color, 'user_box_message')} />
                            </div>
                          </Col>
                        </AccordionDetails>
                      </Accordion>
                    </Col>
                  </Row>
                </div>
              )}
            </Card>
          </Col>
          <Col>
            <Card small className="mb-4">
              <Row>
                <Col>
                  <span style={{ padding: '20px' }}>¿Chat?<Switch
                    checked={siwtch}
                    onChange={() => setSwitch(!siwtch)}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  /></span>
                  <ChatBot imagenes={imagenes} siwtch={siwtch} background={background} mesajeBienvenida={mesajeBienvenida} mensajeSecundario={mensajeSecundario} arrListForm={arrListForm} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

      ) : null}

      {/**Modal Nuevo*/}
      <Modal show={modalIsOpen} onHide={closeModal} centered scrollable>
        <Modal.Body>
          <div className="container">
            <h4 className="page-title py-4" style={{ color: "#341678", fontWeight: "bold" }}>Formulario de contacto</h4>
            <Row>
              <Col>
                <label style={{ color: "#341678" }}>Seleccione los datos que desea solicitar a sus clientes</label>
                <div className="input-group py-3">
                  <input className="form-control" value={inputForm} onChange={inputOnChangeForm} />
                  <div className="input-group-append">
                    <span onClick={() => { addForm() }} className="input-group-text" style={{ background: "#8471AD", color: "white", cursor: "pointer" }}>Agregar</span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {
                  arrForm.length > 0 ?
                    arrForm.map((input, i) => {
                      return (
                        <div key={i} className="input-group py-2">
                          <input className="form-control" disabled={true} value={input.title} style={{ color: grey }} />
                          {
                            !input.required ?
                              <div className="input-group-append">
                                <span onClick={() => { removeForm(i) }} className="input-group-text" style={{ background: "#FF6364", color: "white", cursor: "pointer" }}>Quitar</span>
                              </div>
                              :
                              <div className="input-group-append">
                                <span className="input-group-text" style={{ background: "#20B970", color: "white" }}>Obligatorio</span>
                              </div>
                          }
                        </div>
                      )
                    })
                    :
                    null
                }
              </Col>
            </Row>

            <div className="d-flex justify-content-around py-4">
              <button onClick={() => { closeModal() }} className="btn btn-display bg-primary text-white">Cerrar</button>
              <button onClick={() => { handleSave() }} className="btn btn-display text-white" style={{ backgroundColor: "rgb(52, 22, 120)" }}>Guardar</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/** Modal Editar */}
      <Modal show={modalIsEditOpen} onHide={closeEditModal} centered scrollable>
        <Modal.Body>
          <div className="container">
            <h4 className="page-title py-4" style={{ color: "#341678", fontWeight: "bold" }}>Editar campo del Formulario</h4>

            <Row>
              <Col>
                <label>Nombre de la etiqueta</label>
                <input value={titleEditForm} className="form-control" onChange={inputOnChangeEditTitleForm} />
              </Col>
            </Row>

            {/** <Row>
                    <Col>
                        <label>Nombre del campo</label>
                        <input value={fieldEditForm} className="form-control" disabled={true} />
                    </Col>
                </Row> */}

            <Row>
              <Col>
                <label>Estado actual</label><br />
                {
                  requiredEditForm === true ?
                    <span className="badge badge-success">Habilitado</span>
                    :
                    <span className="badge badge-danger">Deshabilitado</span>
                }
              </Col>
              <Col>
                <label>Seleccione un estado</label>
                <Select
                  ref={inputSelectEstado}
                  className="basic-single w-130"
                  classNamePrefix="Estado∫"
                  defaultValue={selectEstado[0]}
                  name="Estados"
                  options={estados}
                />
              </Col>
            </Row>

            <div className="d-flex justify-content-around py-4">
              <button onClick={() => { closeEditModal() }} className="btn btn-display bg-primary text-white">Cerrar</button>
              <button onClick={() => { handleEdit() }} className="btn btn-display text-white" style={{ backgroundColor: "rgb(52, 22, 120)" }}>Editar</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="form-group col-md-12 ">
        <Modal
          show={modalForm}
          onHide={closeModalForm}
          centered
          scrollable
          size="lg"
        >
          <Modal.Body style={{ height: '2500px' }}>
            <iframe src={role === 2 ? "https://chatome.com.co/media/manuales/dashboard/perfil-2/formulario.pdf" : role === 3 ? "https://chatome.com.co/media/manuales/dashboard/perfil-3/formulario.pdf" : ""} style={{ width: '100%', height: "100%" }} title="Iframe Dashboard"></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </Container >


  )
}

export default UserListForm;
