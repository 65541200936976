import React from "react";
import { BrowserRouter as Router,Redirect} from "react-router-dom";

import routes from "./routes";
import './assets/login.css'
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import PrivateRoutes from "./components/auth/PrivateRoutes";
import withTracker from "./withTracker"
//redux
import { Provider } from "react-redux";
import store from "./store";



const MainApp = () => {
  return (
    <>
      {routes.map((route, index) => {
        return (
          <>
          <PrivateRoutes
            key={index}
            path={route.path}
            exact={route.exact}
            
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        
          </>
        );

      })}
      {/* <Route path="/" component={NotFoundpage} /> */}
      <Redirect to="/login"/>
    </>
  )
}

export default () => {

  return (
    <Provider store={store}>
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <div className="Login">
          <MainApp />
        </div>
      </Router>
    
    </Provider>
  )
};
