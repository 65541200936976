import React, { useState, useCallback } from "react";
import { Container, CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
//import ChatBot from "react-simple-chatbot";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormInput,
  FormSelect,
  Button
} from "shards-react";
import axios from "axios";
import Discussions from "../components/blog/Discussions";
import NewDraft from "../components/blog/NewDraft";
import PageTitle from "../components/common/PageTitle";
import Select from "react-select";

import { setTriggerOptionsAction } from "../store/actions/authActions";
import { APP_API_URL, WAPI } from "../config";
import QRCode from "react-qr-code";
import { Modal } from "react-bootstrap";
import Modal2 from "react-modal";
import Swal from "sweetalert2";
import imgActive from "../assets/header/aviso.png";
import Switch from "@material-ui/core/Switch";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#8573ad",
    textAlign: "center",
    borderRadius: "30px",
    color: "white"
  }
};

const Config = props => {
  const dispatch = useDispatch();
  const preguntas = useSelector(state => state.auth.questions);
  //const nit = useSelector(state => state.auth.user.user.dataUser.nit);
  const user = useSelector(state => state.auth.user);
  const state = useSelector(state => state.auth);
  //const phone = useSelector(state => state.auth.user.user.dataUser.phone);
  const triggers = useSelector(state => state.auth.triggers);
  const [siwtch, setSwitch] = useState(false);
  const [siwtch1, setSwitch1] = useState(false);
  const [addRespuesta, setAddRespuesta] = useState(false);
  const [preguntaSeleccionada, setPreguntaSeleccionada] = useState({});
  const [steps, setSteps] = useState([]);
  const [showQR, setShowQR] = useState(false);
  const [qr, setQR] = useState("");
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [activeWhatsapp, setActiveWhatsapp] = useState(false);
  const [whatsappActive, setWhatsappActive] = useState(false);

  const [modalCon, setmodalCon] = useState(false);

  const { path } = props.match;

  const [respuestas, setRespuestas] = React.useState([
    {
      id: preguntas.length - 1,
      message: "",
      trigger: -1
    }
  ]);

  const initState = () => {
    setAddRespuesta(false);
    setRespuestas([{
      id: preguntas.length - 1,
      message: "",
      trigger: -1
    }]);
    setPreguntaSeleccionada({});
  }

  const nuevaRespuesta = () => {
    if (respuestas.length !== preguntas.length - 1) {
      setRespuestas(
        respuestas.concat([
          {
            id: preguntas.length - 1,
            message: "",
            trigger: -1
          }
        ])
      );
    }
  };

  const leerCampo = (i, value, input) => {
    const nuevaRespuesta = respuestas.map((respu, index) => {
      if (i !== index) return respu;
      return {
        ...respu,
        id: i + 1,
        [input]: value
      };
    });

    setRespuestas(nuevaRespuesta);
  };

  React.useEffect(() => {
    dispatch(setTriggerOptionsAction(preguntas));
  }, [preguntas]);

  const eliminarRespuesta = i => {
    // TODO: evitar que eliminen el primero
    setRespuestas(respuestas.filter((element, index) => i !== index));
  };

  const handleAddRespuesta = useCallback(
    data => {
      const arrOptions = preguntas.filter(pregunta => pregunta.id !== data.id);
      const optionsParse = [];
      console.log("data", data);
      setSwitch(data.solicitud);
      setSwitch1(data.requirements);

      arrOptions.forEach(option => {
        optionsParse.push({ value: option.id, label: option.message });
      });
      dispatch(setTriggerOptionsAction(optionsParse));
      // setOptions(optionsParse)
      setAddRespuesta(true);
      if (data.options) {
        delete data.trigger;
      }
      setPreguntaSeleccionada(data);
      if (data.options) {
        setRespuestas(data.options);
      }
    },
    [triggers, preguntas]
  );
  const guardarRespuestas = () => {
    let messageClean = "";
    let idClean = "";
    let arrayMessage = [];
    let arrayId = [];
    let countId = 0;
    let countMessage = 0;

    respuestas.forEach((respuesta, i) => {
      const sepId = i == 0 || countId == 0 ? "" : ", ";
      const sepMessage = i == 0 || countMessage == 0 ? "" : ", ";
      if (parseInt(respuesta.trigger) == -1) {
        let id = respuesta.id;
        if (respuesta.id == -1) {
          id = 1;
        }
        idClean = idClean + sepId + id;
        arrayId.push(respuesta.id);
        countId++;
      }

      if (respuesta.message.trim() == "") {
        let id = respuesta.id;
        if (respuesta.id == -1) {
          id = 1;
        }
        messageClean = messageClean + sepMessage + id;
        arrayMessage.push(respuesta.id);
        countMessage++;
      }
      respuesta.label = respuesta.message;
      respuesta.value = respuesta.id;
    });

    if (!siwtch) {
      if (messageClean != "" || idClean != "") {
        let msgClean = "";
        let msgId = "";
        if (arrayMessage.length == 1) {
          msgClean = `La respuesta ${messageClean} esta en blanco.`;
        } else if (arrayMessage.length == 0) {
          msgClean = "";
        } else {
          msgClean = `Las respuestas ${messageClean} estan en blanco.`;
        }

        if (arrayId.length == 1) {
          msgId = `La respuesta ${idClean} no esta asociada a una pregunta.`;
        } else if (arrayId.length == 0) {
          msgId = "";
        } else {
          msgId = `La respuestas ${idClean} no estan asociadas a una pregunta.`;
        }
        Swal.fire({
          icon: "info",
          title: "Advertencia",
          text: `Las respuestas no pueden ser guardadas. ${msgClean} ${msgId}`
        });
      } else {
        setLoader(true);
        axios
          .put(
            `${APP_API_URL}/admin/questions/addAnswers`,
            {
              id: preguntaSeleccionada.id,
              nit:
                path == "/whatsapp"
                  ? user.user.dataUser.nit
                  : user.user.stateChad == 5
                    ? user.user.dataUser.nit
                    : user.user.companyNit,
              options: respuestas,
              number:
                path == "/whatsapp"
                  ? user.user.whatsapp
                  : user.user.stateChad == 5
                    ? user.user.whatsapp
                    : user.user.phone,
              idResponsable:
                path != "/whatsapp"
                  ? user.user.stateChad == 5
                    ? state.idResponsable
                    : user.user.id
                  : user.user.dataUser.nit,
              wsp: path == "/whatsapp",
              solicitud: false,
              requirements: siwtch1
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("userToken")
              }
            }
          )
          .then(res => {
            if (res.data != undefined) {
              if (res.data.StatusCode == 200) {
                initState();
                setLoader(false);
              }
            }
          })
          .catch(err => setLoader(false));
      }
    } else {
      const respuestasFormat = [];
      respuestas.forEach(respuestas => {
        //console.log({ ...respuestas, message: "RESPUESTA ABIERTA", label: "RESPUESTA ABIERTA" });
        respuestasFormat.push({ ...respuestas, message: "RESPUESTA ABIERTA" });
      });

      setLoader(true);
      axios
        .put(
          `${APP_API_URL}/admin/questions/addAnswers`,
          {
            id: preguntaSeleccionada.id,
            nit:
              path == "/whatsapp"
                ? user.user.dataUser.nit
                : user.user.stateChad == 5
                  ? user.user.dataUser.nit
                  : user.user.companyNit,
            options: respuestasFormat,
            number:
              path == "/whatsapp"
                ? user.user.whatsapp
                : user.user.stateChad == 5
                  ? user.user.whatsapp
                  : user.user.phone,
            idResponsable:
              path != "/whatsapp"
                ? user.user.stateChad == 5
                  ? state.idResponsable
                  : user.user.id
                : user.user.dataUser.nit,
            wsp: path == "/whatsapp",
            solicitud: true,
            requirements: siwtch1
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("userToken")
            }
          }
        )
        .then(res => {
          if (res.data != undefined) {
            if (res.data.StatusCode == 200) {
              initState();
              setLoader(false);
              setSwitch(false);
            }
          }
        })
        .catch(err => setLoader(false));
    }
  };

  const sendMessage = (node, step2 = []) => {
    axios
      .post(`${APP_API_URL}/chatbot/v1/messages`, {
        node: node,
        nit: user.user.companyNit
      })
      .then(res => {
        setSteps([...step2, res.data.data.questionParse]);
      })
      .catch(err => {
        console.log(err);
        window.location.reload();
      });
  };

  const fetchVerifyStatus = () => {
    axios.post(`${WAPI}/islogin`, {
      phone: '57' + user.user.whatsapp
    },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken")
        }
      }
    ).then(response => {
      if (response.data.d == null || response.data.d == "" || response.data.d == undefined) {
        setWhatsappActive(false);
      } else {
        setWhatsappActive(true);
      }
    }).catch(error => console.log(error))
  }

  React.useEffect(() => {
    if (path == "/whatsapp") {
      fetchVerifyStatus();
    }
  }, []);

  const handleSelectTrigger = option => {
    const stepDisabled = [];
    steps.forEach(step => {
      stepDisabled.push({ ...step, disabled: true });
    });
    const arr = [...stepDisabled, { label: option.label, respuesta: true }];
    setSteps(arr);
    //sendMessage(option.trigger, arr);
    return;
  };

  const handleGetQr = () => {
    setLoader(true);
    const dev = false;
    axios
      .post(
        dev
          ? `http://localhost:18500/start`
          : `${process.env.REACT_APP_WAPI}/start`,
        {
          phone: `57${user.user.whatsapp}`
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            ContentType: "application/x-www-form-urlencoded"
          }
        }
      )
      .then(async res => {
        if (res.data.activated) {
          setLoader(false);
          setShowModal(false);
          setShowQR(false);
          setActiveWhatsapp(true);

          // Guardar session

          return;
        }
        setTimeout(async () => {
          setShowModal(false);

          // TODO: realizar peticion al server
          // await axios.post(`${APP_API_URL}/admin/whatsapp/closeQR`, {
          //   number: `57${user.user.whatsapp}`
          // }, {
          //   headers: {
          //     Authorization: "Bearer " + localStorage.getItem("userToken"),
          //     ContentType: "application/x-www-form-urlencoded"
          //   }
          // }).then(res => {
          //   setLoader(false);
          //   setShowQR(false);
          // })
          setLoader(false);
          setShowQR(false);
          fetchVerifyStatus();
        }, 10000);

        setActiveWhatsapp(false);
        setLoader(false);
        setShowQR(true);
        if (res.data.d == '') {
          handleGetQr()
        } else {
          setQR(res.data.d);
        }
      })
      .catch(err => {
        console.log(err);
        setActiveWhatsapp(false);
        setLoader(false);
        //window.location.reload();
      });
  };

  const openManualModal = () => {
    setmodalCon(true);
  };

  const closeConModal = () => {
    setmodalCon(false);
  };
  return (
    <Container
      style={{ position: "relative" }}
      fluid
      className="main-content-container px-4"
    >
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Chad"
          subtitle="Conversaciones"
          className="text-sm-left mb-3"
          openManualModal={openManualModal}
        />
      </Row>
      <Row>
        {addRespuesta ? (
          <>
            <Col lg="12" className="mb-4">
              <Card small className="h-100">
                <CardBody className="d-flex flex-column">
                  <div>
                    <Button
                      onClick={() => {
                        initState();
                      }}
                    >
                      Atrás
                    </Button>
                    <div>
                      <h4 className="m-0 text-dark">
                        {preguntaSeleccionada.message}
                      </h4>
                      <p>Una descripcion de lo que debe hacer el usuario</p>
                    </div>
                    <div>
                      <span>
                        ¿Es una solicitud?
                        <Switch
                          checked={siwtch}
                          onChange={() => setSwitch(!siwtch)}
                          name="checkedA"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </span>
                      <span>
                        ¿Solicitud por contactar?
                        <Switch
                          checked={siwtch1}
                          onChange={() => setSwitch1(!siwtch1)}
                          name="checkedB"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </span>
                    </div>
                    {/** RESPUESTAS */}
                    <div>
                      <div style={{ marginBottom: 10 }}>
                        {respuestas.map((respuesta, index) => {
                          return (
                            <div key={index} className="mt-1 d-flex">
                              <div style={{ width: "50%" }}>
                                <FormInput
                                  disabled={siwtch}
                                  value={
                                    siwtch
                                      ? "RESPUESTA ABIERTA"
                                      : respuesta.message
                                  }
                                  onChange={e =>
                                    leerCampo(index, e.target.value, "message")
                                  }
                                  placeholder="Brave New World"
                                />
                              </div>
                              <div
                                style={{
                                  alignSelf: "center",
                                  margin: " 0px 10px"
                                }}
                              >
                                <span>
                                  <i class="fas fa-arrow-right"></i>
                                </span>
                              </div>
                              <div style={{ width: "50%" }}>
                                <FormSelect
                                  value={respuesta.trigger}
                                  style={{ maxWidth: "100%" }}
                                  onChange={e =>
                                    leerCampo(index, e.target.value, "trigger")
                                  }
                                >
                                  <option value="-1" selected>
                                    Selecciona respuesta
                                  </option>
                                  {triggers.map(option => {
                                    return (
                                      <option
                                        value={option.value}
                                        selected={false}
                                      >
                                        {option.label.length > 60
                                          ? option.label.substr(0, 100)
                                          : option.label}
                                      </option>
                                    );
                                  })}
                                </FormSelect>
                              </div>
                              <button
                                type="button"
                                disabled={siwtch}
                                onClick={() => eliminarRespuesta(index)}
                                style={{
                                  color: "white",
                                  backgroundColor: "#fe6969"
                                }}
                                className="btn ml-1"
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </div>
                          );
                        })}
                      </div>

                      {!siwtch && (
                        <Button
                          theme="secondary"
                          type="button"
                          onClick={() => nuevaRespuesta()}
                        >
                          Agregar mas respuestas
                        </Button>
                      )}
                    </div>
                    <div className="justify-content-end row">
                      <div className="col-3">
                        {/* {
                          respuestas.length > 0 && (
                            <Button theme="accent" type="button" onClick={() => loader ? undefined : guardarRespuestas()}>
                              {loader ? (
                                <CircularProgress size={25} />
                              ) : "Guardar respuestas"}
                            </Button>
                          )
                        } */}
                        <Button
                          theme="accent"
                          type="button"
                          onClick={() =>
                            loader ? undefined : guardarRespuestas()
                          }
                        >
                          {loader ? (
                            <CircularProgress size={25} />
                          ) : (
                            "Guardar respuestas"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </>
        ) : (
          <>
            <Col lg={path == "/whatsapp" ? "10" : "12"} className="mb-4">
              {/**componente de pregunta */}
              <NewDraft
                setLoadingData={setLoadingData}
                path={path == "/whatsapp" ? "wsp" : "web"}
              />
            </Col>
            {/**componente de whatsapp */}
            {path == "/whatsapp" && (
              <Col lg="2" className="mb-4">
                <div
                  onClick={() => setShowModal(true)}
                  style={{
                    backgroundColor: !whatsappActive ? "#ff7a7b" : "#4caf50",
                    border: `10px solid ${!whatsappActive ? "#ff696a" : "rgb(55 156 59)"
                      }`,
                    borderRadius: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                    position: "relative"
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "-33px",
                      textAlign: "center",
                      color: !whatsappActive ? "#ff696a" : "rgb(55 156 59)"
                    }}
                  >
                    {whatsappActive ? "Sincronizado" : "No sincronizado"}
                  </span>
                  <img src={require("../assets/header/QR.png")} alt="" />
                </div>
              </Col>
            )}

            <Col lg="12" className="mb-4">
              <Discussions
                loadingData={loadingData}
                handleAddRespuesta={handleAddRespuesta}
                setPreguntaSeleccionada={setPreguntaSeleccionada}
                setAddRespuesta={setAddRespuesta}
                path={path}
                path2={path == "/whatsapp" ? "wsp" : "web"}
              />
            </Col>
          </>
        )}
      </Row>
      {/* <button onClick={() => handleGetQr()}>Generar token</button>
      {showQR ? (<QRCode value={qr} />) : loader && <CircularProgress />} */}

      <Modal2
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
          fetchVerifyStatus();
        }}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ backgroundColor: "#8573ad" }}>
          <div className="headerModal">
            <h3 style={{ color: "white", fontWeight: "700" }}>
              ¡Escanea tu código QR!
            </h3>
            <span className="d-block">
              y Chatome funcionará desde tu WhatsApp
            </span>
            <img src={require("../assets/header/línea.png")} alt="" />
          </div>
          <p>
            Si llegas a tener problemas de sincronización genera nuevamente el
            QR. <br />
            <span>No olvides cerrar la sesión en tu dispositivo.</span>
          </p>
          <p></p>
          <div
            style={{
              width: "300px",
              height: "300px",
              backgroundColor: "white",
              borderRadius: "20px",
              border: "10px solid #341677",
              margin: "auto"
            }}
          >
            {loader ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {activeWhatsapp ? (
                  <img src={imgActive} className="w-100" />
                ) : (
                  <>
                    {showQR && (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <QRCode value={qr} />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <div>
            <Button
              onClick={() => handleGetQr()}
              className="btn"
              style={{
                backgroundColor: "#341677",
                color: "white",
                marginTop: "10px",
                fontWeight: "700"
              }}
            >
              Generar QR
            </Button>
          </div>
        </div>
      </Modal2>

      <div className="form-group col-md-12 ">
        <Modal
          show={modalCon}
          onHide={closeConModal}
          centered
          scrollable
          size="lg"
        >
          <Modal.Body style={{ height: "2500px" }}>
            <iframe
              src={
                path == "/whatsapp"
                  ? "https://chatome.com.co/media/manuales/dashboard/perfil-3/conf_web.pdf"
                  : "https://chatome.com.co/media/manuales/dashboard/perfil-3/conf_web.pdf"
              }
              style={{ width: "100%", height: "100%" }}
              title="Iframe Dashboard"
            ></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
};

export default Config;
