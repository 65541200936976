//sidebar

export default function () {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      // htmlBefore: '<i class="material-icons">info</i>',
      htmlAfter: "",
      show: true
    },
    {
      title: "Mi perfil",
      to: "/profile",
      // htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: "",

    },
    {
      title: "Usuarios",
      // htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/clients",
      show: true
    },
    {
      title: "Departamentos",
      // htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/departments",
      show: true
    },
    {
      title: "Agentes",
      // htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/agents",
      show: true
    },
    {
      title: "Configuración Whatsapp",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/whatsapp",
      show: true
    },
    {
      title: "Por contactar WhatsApp",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/request",
      show: true
    },
    {
      title: "Contactado WhatsApp",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/request3",
      show: true
    },
    {
      title: "Atendidas por WhatsApp",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/request2",
      show: true
    },
    {
      title: "Configuración Web",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/config",
      show: true
    },
    {
      title: "Instalación Web",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/Installer",
      show: true
    },
    {
      title: "Formulario Web",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/list-form",
      show: true
    },
    {
      title: "Atendidos Web",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/tracking-list-state-two",
      show: true
    },
    {
      title: "Por contactar Web",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/tracking-list-state",
      show: true
    },
    {
      title: "En curso Web",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/tracking-list-state-tree",
      show: true
    },
    {
      title: "Contactados Web",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/tracking-list-state-one",
      show: true
    },
    {
      title: "No desea ser contactado Web",
      // htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/tracking-list-state-four",
      show: true
    },
  ];
}
