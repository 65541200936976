import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ButtonGroup,
  Button,

} from "shards-react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Swal from 'sweetalert2'

import { APP_API_URL } from "../../config";
import { deleteQuestionsBotAction, saveQuestionsBotAction } from "../../store/actions/authActions";
import { CircularProgress } from "@material-ui/core";

const Discussions = ({ title, handleAddRespuesta, setAddRespuesta, setPreguntaSeleccionada, path, path2, loadingData }) => {
  const dispatch = useDispatch()
  const preguntas = useSelector(state => state.auth.questions)
  const [loading, setLoading] = React.useState(false)
  const [loadingEdit, setLoadingEdit] = React.useState(false)
  const [select, setSelect] = React.useState(0)
  const [modoEdicion, setModoEdicion] = React.useState(false)
  const [questionSelected, setQuestionSelected] = React.useState({})
  const [newValue, setNewValue] = React.useState("")

  // const nit = useSelector(state => state.auth.user.user.dataUser.nit)
  const user = useSelector(state => state.auth.user)
  const state = useSelector(state => state.auth)
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('userToken')
  }

  const getQuestionByNit = async () => {

    const newNit = path == "/whatsapp" ? user.user.dataUser.nit : user.user.stateChad == 5 ? user.user.dataUser.nit : user.user.companyNit
    const newNumber = path == "/whatsapp" ? user.user.whatsapp : user.user.stateChad == 5 ? user.user.whatsapp : user.user.phone
    const newDeparment = path == "/whatsapp" ? user.user.dataUser.nit : user.user.stateChad == 5 ? state.idResponsable : user.user.id
    const paatth = path == "/whatsapp" ? 'wsp' : 'web'
    axios.get(`${APP_API_URL}/admin/questions/${newNit}/${newNumber}/${newDeparment}/${paatth}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      },
    }).then((res) => {

      const arrOptions = res.data.data.questions.sort((a, b) => (a.id > b.id) ? 1 : -1)

      dispatch(saveQuestionsBotAction(arrOptions));


    }).catch(err => {
      console.log(err)
    })
  }
  const deleteQuestion = async (id) => {
    setSelect(id)
    setLoading(true)
    axios.delete(`${APP_API_URL}/admin/questions`, {
      data:
      {
        id,
        nit: path2 === 'wsp' ? user.user.dataUser.nit : user.user.stateChad == 5 ? user.user.dataUser.nit : user.user.companyNit,
        idResponsable: path2 === 'wsp' ? user.user.dataUser.nit : user.user.stateChad == 5 ? state.idResponsable : user.user.id,
        phone: path2 === 'wsp' ? user.user.whatsapp : user.user.stateChad == 5 ? user.user.whatsapp : user.user.phone,
        wsp: path == "/whatsapp"
      },
      headers: headers
    }).then(res => {
      if (res.data.StatusCode == 200 && res.data.data == undefined) {
        dispatch(deleteQuestionsBotAction(id));
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: `Operación exitosa.`,
        });
        setLoading(false)
        setSelect(0)
      } else {
        const { data } = res.data;
        let questionBusy = "";
        if (data.questionsFind != undefined) {
          if (data.questionsFind.length > 0) {
            data.questionsFind.forEach((element, i) => {
              const sep = i == data.questionsFind.length - 1 ? '' : ', ';
              const q = i + 1;
              questionBusy = questionBusy + q + sep;
            });
            setLoading(false)
            setSelect(0)
            Swal.fire({
              icon: 'info',
              title: 'Advertencia',
              text: `Esta pregunta no pudo ser eliminada porque esta asociada a ${data.questionsFind.length == 1 ? 'la pregunta' : 'las preguntas'}: ${questionBusy}`,
            })
          }
        }
      }

    }).catch(error => { setLoading(false); setSelect(0) })
  }
  const handleChangeQuestion = async (data) => {
    setQuestionSelected(data)
    setNewValue(data.message)
    setModoEdicion(true)
  }
  console.log(questionSelected);
  const cancelEdit = () => {
    setModoEdicion(false)
    setQuestionSelected({})
  }
  const saveEditQuestion = () => {

    if (newValue.trim() === "") {
      Swal.fire({
        icon: 'error',
        title: 'Advertencia',
        text: "El campo es obligatorio.",
      })
      return
    }
    setLoadingEdit(true)
    const dataSend = { ...questionSelected }
    delete dataSend.created
    axios.post(
      `${APP_API_URL}/admin/questions`,
      {
        ...dataSend,
        message: newValue,
        nit: path == "/whatsapp" ? user.user.dataUser.nit : user.user.stateChad == 5 ? user.user.dataUser.nit : user.user.companyNit,
        number: path == "/whatsapp" ? user.user.whatsapp : user.user.stateChad == 5 ? user.user.whatsapp : user.user.phone,
        idResponsable: path != "/whatsapp" ? user.user.stateChad == 5 ? state.idResponsable : user.user.id : user.user.dataUser.nit,
        wsp: path == "/whatsapp"
      },
      { headers: headers })
      .then(res => {
        setModoEdicion(false)
        setLoadingEdit(false)


        axios
          .put(
            `${APP_API_URL}/admin/questions/addAnswers`,
            {
              id: dataSend.id,
              nit: path == "/whatsapp" ? user.user.dataUser.nit : user.user.stateChad == 5 ? user.user.dataUser.nit : user.user.companyNit,
              options: dataSend.options,
              number: path == "/whatsapp" ? user.user.whatsapp : user.user.stateChad == 5 ? user.user.dataUser.phone : user.user.phone,
              idResponsable: path != "/whatsapp" ? user.user.stateChad == 5 ? state.idResponsable : user.user.id : user.user.dataUser.nit,
              wsp: path == "/whatsapp"
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("userToken")
              }
            }
          )
          .then(res => {
            getQuestionByNit()
          })
          .catch(err => console.log(err));

      }).catch(error => setLoadingEdit(false))
  }

  return (
    <Card small className="blog-comments">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Preguntas creadas</h6>
      </CardHeader>

      <CardBody style={{ margin: loadingData ? 'auto' : 'initial' }} className="p-0">
        {loadingData ? <CircularProgress /> : (
          <>
            {preguntas.map((discussion, idx) => (
              <div key={idx} className="blog-comments__item d-flex p-3">
                {/* Content */}
                <div className="blog-comments__content col-11">
                  {/* Content :: Title */}
                  <div className="blog-comments__meta text-mutes">
                    <span className="text-secondary" href="!#">
                      {modoEdicion && questionSelected.id == discussion.id ? (
                        <>
                          <div class="input-group">
                            <textarea className="form-control" onChange={(e) => setNewValue(e.target.value)} value={newValue} ></textarea>
                            <div class="input-group-append">
                              <Button style={{ margin: "0px 10px 0px 10px" }} onClick={() => loadingEdit ? undefined : saveEditQuestion()}>
                                {loadingEdit ? (<CircularProgress size={15} />) : (<i class="fas fa-check"></i>)}
                              </Button>
                              <Button onClick={() => loadingEdit ? undefined : cancelEdit()}><i class="fas fa-times-circle"></i></Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {idx + 1}. {discussion.message} <button onClick={() => handleChangeQuestion(discussion)} style={{ border: 'none', background: 'none' }}><i class="far fa-edit"></i></button>
                        </>
                      )}

                    </span>{" "}
                    {/* <span className="text-mutes">- {discussion.date}</span> */}
                  </div>

                  {/* Content :: Body */}
                  {discussion.options ? (
                    <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                      {discussion.options.map((option, index) => (
                        <div style={{
                          border: '1px solid #1239de',
                          width: option.message.length > 50 ? '90%' : 'fit-content',
                          padding: '2px 10px',
                          borderRadius: '15px',
                          margin: ' 5px 0px',
                          marginLeft: index === 0 ? '0px' : '10px',
                          alignSelf: 'center'
                        }}>
                          {option.message}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="m-0 my-1 mb-2 text-muted">Sin respuestas.</p>

                  )}

                  {/* Content :: Actions */}
                  <div className="blog-comments__actions">
                    <ButtonGroup size="sm">
                      {/* <Button theme="white">
                  <span className="text-success">
                    <i className="material-icons">check</i>
                  </span>{" "}
                  Approve
                </Button> */}

                      <Button onClick={() => loading ? undefined : deleteQuestion(discussion.id)} theme="white">
                        {loading && select == discussion.id ?
                          <CircularProgress size={15} />
                          : <>
                            <span className="text-danger">
                              <i className="material-icons">clear</i>
                            </span>{" "}
                            Eliminar
                          </>}
                      </Button>
                      <Button onClick={() => handleAddRespuesta(discussion)} theme="white">
                        <span className="text-light">
                          <i className="material-icons">more_vert</i>
                        </span>{" "}
                        Agregar respuestas
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            ))}
          </>

        )}</CardBody>

      {/* <CardFooter className="border-top">
      <Row>
        <Col className="text-center view-report">
          <Button theme="white" type="submit">
            View All Comments
          </Button>
        </Col>
      </Row>
    </CardFooter> */}
    </Card>
  )
};

Discussions.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The discussions dataset.
   */
  discussions: PropTypes.array
};

Discussions.defaultProps = {
  title: "Discussions",
  discussions: [
    {
      id: 1,
      date: "3 days ago",
      author: {
        image: require("../../images/avatars/1.jpg"),
        name: "John Doe",
        url: "#"
      },
      post: {
        title: "Hello World!",
        url: "#"
      },
      body: "Well, the way they make shows is, they make one show ..."
    },
    {
      id: 2,
      date: "4 days ago",
      author: {
        image: require("../../images/avatars/2.jpg"),
        name: "John Doe",
        url: "#"
      },
      post: {
        title: "Hello World!",
        url: "#"
      },
      body: "After the avalanche, it took us a week to climb out. Now..."
    },
    {
      id: 3,
      date: "5 days ago",
      author: {
        image: require("../../images/avatars/3.jpg"),
        name: "John Doe",
        url: "#"
      },
      post: {
        title: "Hello World!",
        url: "#"
      },
      body: "My money's in that office, right? If she start giving me..."
    }
  ]
};

export default Discussions;
