import React from 'react'
import Logo from './../../images/avatars/avatar-chad.png';
export const SplshConversation = ({ mensajesChat = [] }) => {
  return (
    <div className="col-md-8 ml-2" style={styles.container} hidden={mensajesChat.length !== 0}>
      <img src={Logo} alt={Logo} style={styles.img} />
      <h4 style={styles.text}>No se ha seleccionado un chat aun...</h4>
    </div>
  )
}

const styles = {
  container: {
    border: '2px solid #3d207d',
    borderRadius: '6px',
    backgroundColor: 'white',
    padding: '0px!important',
    height: '82vh',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }, img: {
    maxWidth: '20vw',
    maxHeight: '40vh'
  }, text: {
    textAlign: 'center'
  }
}
