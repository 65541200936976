import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";
import { useSelector } from 'react-redux';
import { APP_API_URL } from "../../../config";
import clienteAxios from "../../../config/axios";

const SidebarNavItem = (props) => {
  const role = useSelector(state => state.auth.user.user.role);
  const user = useSelector(state => state.auth.user)
  const [data, setData] = useState(0)
  const [loader, setLoader] = useState(false);
  const [modalDept, setmodalDept] = useState(false)
  const [requests, setRequest] = useState([])
  const [requestsTemp, setRequestTemp] = useState(0)
  const [requestsTemp2, setRequestTemp2] = useState(0)

  useEffect(() => {
    clienteAxios.post(`${APP_API_URL}/admin/clients/dataChartsByRol`, { nit: role == 2 ? user.user.dataUser.nit : user.user.companyNit, role, idResponsable: user.user.id }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }).then(res => {
      setData(res.data.data.totalClientsActives)
    })
  }, [])

  useEffect(() => {
    clienteAxios.get(`${APP_API_URL}/admin/clients/dataChartsByRequest/${user.user.whatsapp}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }).then(res => {
      setRequestTemp(res.data.data.data)
    })
  }, [])

  const { item } = props;
  if (role == 1) {
    if (item.to != "/agents" && item.to != "/departments" && item.to != "/config" && item.to != "/whatsapp" && item.to != "/request" && item.to != "/tracking-list-state-four" &&
      item.to != "/request" && item.to != "/request2" && item.to != "/Installer" && item.to != "/list-form" && item.to != "/tracking-list-state" && item.to != "/tracking-list-state-one" && item.to != "/tracking-list-state-two" && item.to != "/tracking-list-state-tree") {
      return (
        <NavItem>
          <NavLink tag={RouteNavLink} to={item.to}>
            {item.htmlBefore && (
              <div
                className="d-inline-block item-icon-wrapper"
                dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
              />
            )}
            {item.title && <span>{item.title}</span>}
            {item.htmlAfter && (
              <div
                className="d-inline-block item-icon-wrapper"
                dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
              />
            )}
          </NavLink>
        </NavItem>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  if (role == 2) {
    const stateChad = user.user.stateChad;
    if (stateChad != undefined) {
      switch (stateChad) {
        case 1:
          if (item.to != "/agents" && item.to != "/clients" && item.to != "/config" && item.to != "/Installer" && item.to != "/whatsapp" && item.to != "/request" && item.to != "/request2") {
            return (
              <NavItem>
                <NavLink tag={RouteNavLink} to={item.to}>
                  {item.htmlBefore && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                    />
                  )}
                  {item.title && <span>{item.title}</span>}
                  {item.htmlAfter && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                    />
                  )}
                </NavLink>
              </NavItem>
            )
          } else {
            return (
              <div></div>
            )
          }
          break;
        case 2:
          if (item.to != "/agents" && item.to == "/whatsapp" && item.to != "/request" && item.to != "/request2") {
            return (
              <NavItem>
                <NavLink tag={RouteNavLink} to={item.to}>
                  {item.htmlBefore && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                    />
                  )}
                  {item.title && <span>{item.title}</span>}
                  {item.htmlAfter && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                    />
                  )}
                </NavLink>
              </NavItem>
            )
          } else {
            return (
              <div></div>
            )
          }
          break;
        case 3:
          if (item.to != "/agents" && item.to != "/clients" && item.to != "/config" && item.to != "/Installer" && item.to != "/request" && item.to != "/request2") {
            return (
              <NavItem>
                <NavLink tag={RouteNavLink} to={item.to}>
                  {item.htmlBefore && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                    />
                  )}
                  {item.title && <span>{item.title} {item.title === "Por contactar Web" && <span style={{
                    backgroundColor: "#ff6363",
                    padding: "1px 8px",
                    borderRadius: '20px',
                    color: 'white'
                  }}>{data}</span>} </span>}
                  {item.htmlAfter && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                    />
                  )}
                </NavLink>
              </NavItem>
            )
          } else {
            return (
              <div></div>
            )
          }
          break;
        case 4:
          if (item.to != "/agents" && item.to != "/departments" && item.to != "/config" && item.to != "/clients" &&
            item.to != "/Installer" && item.to != "/list-form" && item.to != "/tracking-list-state" && item.to != "/tracking-list-state-one" && item.to != "/tracking-list-state-two" && item.to != "/tracking-list-state-tree" && item.to != "/tracking-list-state-four") {
            return (
              <NavItem>
                <NavLink tag={RouteNavLink} to={item.to}>
                  {item.htmlBefore && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlBefore }}

                    />
                  )} {item.title && <span>{item.title} {item.title === "Por contactar WhatsApp" && <span style={{
                    backgroundColor: "#ff6363",
                    padding: "1px 8px",
                    borderRadius: '15px',
                    color: 'white'
                  }}>{requestsTemp}</span>}</span>}
                  {item.htmlAfter && (
                    <div

                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlAfter }}

                    />
                  )}
                </NavLink>
              </NavItem>
            )
          } else {
            return (
              <div></div>
            )
          }
          break;
        case 5:
          if (item.to != "/agents" && item.to != "/clients" &&
            item.to != "/Installer") {
            return (
              <NavItem>
                <NavLink tag={RouteNavLink} to={item.to}>
                  {item.htmlBefore && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                    />
                  )} {item.title && <span>{item.title} {item.title === "Por contactar WhatsApp" && <span style={{
                    backgroundColor: "#ff6363",
                    padding: "1px 8px",
                    borderRadius: '20px',
                    color: 'white'
                  }}>{requestsTemp}</span>}

                    {item.title === "Por contactar Web" && <span style={{
                      backgroundColor: "#ff6363",
                      padding: "1px 8px",
                      borderRadius: '20px',
                      color: 'white'
                    }}>{data}</span>}
                  </span>}
                  {item.htmlAfter && (
                    <div
                      className="d-inline-block item-icon-wrapper"
                      dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                    />
                  )}
                </NavLink>
              </NavItem>
            )
          } else {
            return (
              <div></div>
            )
          }
          break;
      }
    } else {
      if (item.to != "/agents" && item.to != "/clients" && item.to != "/config" && item.to != "/Installer" && item.to != "/whatsapp") {
        return (
          <NavItem>
            <NavLink tag={RouteNavLink} to={item.to}>
              {item.htmlBefore && (
                <div
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
                />
              )}
              {item.title && <span>{item.title}</span>}
              {item.htmlAfter && (
                <div
                  className="d-inline-block item-icon-wrapper"
                  dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
                />
              )}
            </NavLink>
          </NavItem>
        )
      } else {
        return (
          <div></div>
        )
      }
    }
  }

  if (role == 3) {
    if (item.to != "/clients" && item.to != "/departments" && item.to != "/whatsapp" && item.to != "/Installer" && item.to != "/request" && item.to != "/request2" && item.to != "/request3") {
      return (
        <NavItem>
          <NavLink tag={RouteNavLink} to={item.to}>
            {item.htmlBefore && (
              <div
                className="d-inline-block item-icon-wrapper"
                dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
              />
            )}
            {item.title && <span>{item.title} {item.title === "Por contactar Web" && <span style={{
              backgroundColor: "#ff6363",
              padding: "1px 8px",
              borderRadius: '20px',
              color: 'white'
            }}>{data}</span>}</span>}
            {item.htmlAfter && (
              <div
                className="d-inline-block item-icon-wrapper"
                dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
              />
            )}
          </NavLink>
        </NavItem>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

  if (role == 10) {
    if (item.to == "/chat") {
      return (
        <NavItem>
          <NavLink tag={RouteNavLink} to={item.to}>
            {item.htmlBefore && (
              <div
                className="d-inline-block item-icon-wrapper"
                dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
              />
            )}
            {item.title && <span>{item.title}</span>}
            {item.htmlAfter && (
              <div
                className="d-inline-block item-icon-wrapper"
                dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
              />
            )}
          </NavLink>
        </NavItem>
      )
    } else {
      return (
        <div></div>
      )
    }
  }

};

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
