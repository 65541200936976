import React, { useEffect, useState, createRef } from 'react';
import { Dropdown, Modal, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom";

const UserActions = () => {

  const role = useSelector(state => state.auth.user.user.role)
  const user = useSelector(state => state.auth.user)

  const [name, setName] = useState("")

  useEffect(() => {
    switch (role) {
      case 1:
        setName(user.user.email);
        break;

      case 2:
        setName(user.user.dataUser.responsable);
        break;

      case 3:
        setName(user.user.responsable);
        break;

      default:
    }
  }, [name])

  const onSubmitForm = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('user');
    window.location.replace('')
  };

  return (
    <Dropdown className="py-3" style={{ borderLeftColor: '#000' }}>
      <Dropdown.Toggle id="dropdown-basic" >
        {name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item hidden={role === 10}>
          <div>
            <Link to='/profile' style={{ display: 'block' }}>
              <i className="material-icons">&#xE7FD;</i> Mi Perfil
            </Link>
          </div>
        </Dropdown.Item>
        <Dropdown.Item href="#/" onClick={onSubmitForm} >
          <i className="material-icons text-danger">&#xE879;</i> Cerrar sesión
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserActions;
