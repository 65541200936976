import React from "react";
import { Nav } from "shards-react";
import NavbarToggle from "../NavbarToggle"
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import Notifications from "./Notifications";
import UserActions from "./UserActions";

export default () => (
  
  <Nav navbar className="flex-row">
    
    <NavbarToggle className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"></NavbarToggle>
    {/* <Notifications/> */}
    <UserActions/>
  </Nav>
  
);

