import React from "react";
import { Nav } from "shards-react";
import avatar from "../../../images/avatars/avatar-chad.png";
import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount () {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount () {
    Store.removeChangeListener(this.onChange);
  }

  onChange () {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render () {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <div className="text-center py-2">
          <img
            className="rounded-circle"
            src={avatar}
            alt="Photo Profile"
            width="50"
          />
        </div>
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => {
            if (item.show) {
              return <SidebarNavItem key={idx} item={item} />
            } else
              return undefined
          })}
          
        </Nav>
        
      </div>
    )
  }
}

export default SidebarNavItems;
