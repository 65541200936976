import React, { useEffect, useState, createRef } from 'react';
import { Container, Row, Col } from 'shards-react';
import { APP_API_URL } from '../config';
import clienteAxios from '../config/axios';
import Select from 'react-select';
import ExportExcel from 'react-export-excel';

import moment from 'moment'
import './index.css'
import PageTitle from '../components/common/PageTitle';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from "react-redux"
import { ExportSolicitudes } from '../components/ExportSolicitudes';
import { CircularProgress } from "@material-ui/core";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelColummn

const Test = [
  {
    "question": "Escoge alguno de la siguiente lista:",
    "answer": "1"
  },
  {
    "question": "Escoge alguno de la siguiente lista:",
    "answer": "2"
  },
  {
    "question": "Escoge alguno de la siguiente lista:",
    "answer": "7"
  },
  {
    "question": " Digita cantidad Pizza sencilla",
    "answer": "3"
  },
  {
    "question": "Instrucciones adicionales:",
    "answer": "No se"
  },
  {
    "question": "Ingrese Direccion",
    "answer": "Mi casa"
  }
]

const Requests3 = (props) => {

  const role = useSelector(state => state.auth.user.user.role)
  //const nit2 = useSelector(state => state.auth.user.user.dataUser.nit)
  const user = useSelector(state => state.auth.user)

  const [loader, setLoader] = useState(false);
  const inputSelectStatus = createRef()

  const [modalDept, setmodalDept] = useState(false)
  const [modalSolicitud, setModalSolicitud] = useState(false)
  const [requests, setRequest] = useState([])
  const [exportExcel, setexportExcel] = useState([])
  const [requestsTemp, setRequestTemp] = useState([])
  const [countRow, setCountRow] = useState(0)
  const [arrListForm, setArrListForm] = useState([])
  const [datosMostrar, setDatosMostrar] = useState({ answers: [] })
  const [stateExcel, setstateExcel] = useState(false)
  const [stateLoad, setstateLoad] = useState(true)


  const arrStatus = [
    { value: "-1", label: "Todos" },
    { value: "0", label: "Recibidos" },
    { value: "1", label: "Atendidos" }
  ]

  // const {} = useParams()

  const { path } = props.match;


  //Change status
  const changeStatus = (id, status) => {
    if (status == 1) {
      clienteAxios.put(`${APP_API_URL}/admin/request`, {
        "id": id.split("_")[1], status: 0
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userToken')
        }
      }
      ).then((res) => {
        getClient()
        setModalSolicitud(false)
        window.location.reload();
        // crearTabla(res.data.data.clients)
      }).catch(err => {

        window.location.reload();
      })
    }


  }

  // State de errores
  const getClient = () => {
    setLoader(true)
    clienteAxios.post(`${APP_API_URL}/admin/request/number`, {
      "number": user.user.whatsapp,
    }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }
    ).then((res) => {
      setLoader(false)
      const data = res.data.data.requests.sort(function (a, b) {
        if (moment(a.created).format('YYYY-MM-DD') > moment(b.created).format('YYYY-MM-DD')) { return -1; }
        if (moment(a.created).format('YYYY-MM-DD') < moment(b.created).format('YYYY-MM-DD')) { return 1; }
      })
      setRequest(data.filter(requests => requests.status == 3))
      // crearTabla(res.data.data.clients)
    }).catch(err => {
      setLoader(false)
      // window.location.reload();
    })
  }

  useEffect(() => {
    getClient()
  }, [])

  const openManualModal = () => {
    setmodalDept(true)
  }

  const closeDeptModal = () => {
    setmodalDept(false)
  }

  const handleViewSolicitud = (data) => {
    console.log('===>', data);
    setDatosMostrar(data)
    setModalSolicitud(true)
  }
  const OnchangeDepartments = (e) => {
    if (requestsTemp.length > 0) {
      if (e.value == "-1") {
        setRequest(requestsTemp)
        return
      }
      setRequest(requestsTemp.filter(request => request.status == e.value))
    } else {
      setRequestTemp(requests)
      setRequest(requests.filter(request => request.status == e.value))

    }
    //getListTracking(e.value)
  }

  const pushExcel = () => {
    let dataExcel = [];
    setLoader(true);
    requests.forEach((client, i) => (
      dataExcel.push({
        name: client.name,
        number: client.number,
        created: moment(client.created).format('YYYY-MM-DD HH:mm'),
        status: client.status == 0 ? 'Atentido' : 'Recibido'
      })
    ))
    setexportExcel(dataExcel);
    setstateLoad(false);
    setstateExcel(true);
    setLoader(false);
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title='Solicitudes' className="text-sm-left mb-3" openManualModal={openManualModal} />
        {/* <Button onClick={(e) => exportToCSV(csvData2, fileName)}>Exportar Excel</Button> */}
      </Row>
      <Row>
        {/* <div className="col-md-4">
          <label><strong>Estados</strong></label>
          <Select
            ref={inputSelectStatus}
            className="basic-single"
            classNamePrefix="Departamentos"
            defaultValue={{ value: "", label: "Todos" }}
            name="Departamentos"
            options={arrStatus}
            onChange={OnchangeDepartments}
          />
        </div> */}
        {/* <div className="col-md-4">
      <button onClick={() => window.print()} style={{ color: 'white', backgroundColor: '#8573ad', alignItems: 'center', borderRadius:'.225rem',border:'none', width:'40%', marginTop:'1px'}}>
          <b>Exportar solicitudes: {countRow}</b>
          {countRow > 0 && (<ExportSolicitudes csvData={arrListForm != undefined && arrListForm.length > 0 ? arrListForm : []} fileName="data" />)}
            </button>
      </div> */}
        {/* <div className="d-flex" style={{ justifyContent: 'space-between', marginTop: '10px' }}>
          <b>Total de Solicitudes: {requests.length}</b>
          {Test.length > 0 && (<ExportSolicitudes csvData2={Test} fileName="data" />)} */}
        {/* <Button onClick={(e) => ExportSolicitudes(Test,"fileName")}>Exportar Solicitudes</Button>               */}
        {/* </div> */}
      </Row>

      {loader ? (
        <div style={{ textAlign: 'center', width: "355%" }}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <>
          {stateLoad == true ? <button className='btn btn-success' style={{ float: 'right', width: '6.8rem', marginTop: '-3rem', backgroundColor: '#ff6363', borderColor: '#ff6363' }} onClick={pushExcel}>Cargar excel</button> :
            <ExcelFile
              element={<button className='btn btn-success' style={{ float: 'right', width: '6.8rem', marginTop: '-3rem', backgroundColor: '#ff6363', borderColor: '#ff6363' }} >Exportar excel</button>}
              filename="Usuarios contactados">
              <ExcelSheet data={exportExcel} name='Usuarios'>
                <ExcelColumn label="Nombre" value="name" />
                <ExcelColumn label="Numero" value="number" />
                <ExcelColumn label="Fecha" value="created" />
                <ExcelColumn label="Estado" value="status" />
              </ExcelSheet>
            </ExcelFile>}
        </>
      )}

      <Row>

        <table className="table mb-0 mt-2">
          <thead className="text-white" style={{ backgroundColor: '#ff6363' }}>
            <tr>
              <th scope="col" className="border-0">
                Nombre
              </th>

              <th scope="col" className="border-0">
                Numero
              </th>

              <th scope="col" className="border-0">
                Fecha
              </th>

              <th scope="col" className="border-0">
                Estado
              </th>

              <th scope="col" className="border-0">
                Acciones
              </th>

            </tr>
          </thead>
          <tbody style={{ backgroundColor: '#fff' }}>
            {loader ? (
              <div style={{ textAlign: 'center', width: "355%" }}>
                <CircularProgress size={100} />
              </div>
            ) : (
              <>
                {requests.length === 0 && (<div>No hay solicitudes</div>)}

                {requests.map((client, i) => (
                  <tr key={i}>
                    <td>{client.name ? client.name : 'Sin registro'}</td>
                    <td>{client.number}</td>
                    <td>{moment(client.created).format('YYYY-MM-DD HH:mm')}</td>
                    <td>{client.status == 0 ? 'Atentido' : 'Recibido'}</td>
                    {/****nameDepartment */}
                    <td>
                      <button onClick={() => handleViewSolicitud(client)} style={{ color: 'white', backgroundColor: '#fe6969' }} className="btn ">
                        <i class="far fa-eye"></i>
                      </button>
                    </td>

                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </Row>

      <Modal
        show={modalSolicitud}
        onHide={() => setModalSolicitud(false)}
        centered
        scrollable
        size="lg"
      >
        <Modal.Body style={{ height: 'auto' }}>
          <h1 className="text-center">Detalles de solicitud</h1>
          <div style={{ marginTop: '3rem', textAlign: 'center' }}>
            {datosMostrar.answers.map((data, i) => (
              <>
                {console.log((data))}
                <strong style={{ fontSize: '1.3rem' }}>
                  {i === datosMostrar.answers.length - 1 ? '' : data.question}
                </strong>
                <br />
                {data.type == 'media' ? (
                  <img style={{ width: '50%' }} src={`data:png;base64,${data.answer}`} />
                ) : (
                  <span style={{ fontWeight: 300, fontSize: '1.3rem' }}>
                    {i === datosMostrar.answers.length - 1 ? '' : data.answer}
                  </span>
                )}

                <br />

              </>
            ))}  <br />
            <div style={{ justifyContent: 'center', display: 'flex' }}><button onClick={() => changeStatus(datosMostrar.id, datosMostrar.status)} style={{ width: '30%', color: 'white', backgroundColor: datosMostrar.status == 1 ? '#fe6969' : '#4caf50' }} className="btn "> <i class="fas fa-exchange-alt"></i> {datosMostrar.status == 1 ? 'Cambio de estado' : 'Solicitud atentida'}  </button></div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="form-group col-md-12 ">
        <Modal
          show={modalDept}
          onHide={closeDeptModal}
          centered
          scrollable
          size="lg"
        >
          <Modal.Body style={{ height: '2500px' }}>
            <iframe src="https://chatome.com.co/media/manuales/dashboard/perfil-2/departamentos.pdf" style={{ width: '100%', height: "100%" }} title="Iframe Dashboard"></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </Container >
  );
}

export default Requests3;
