export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'


export const SAVE_QUESTION_BOT = 'SAVE_QUESTION_BOT'
export const SAVE_QUESTIONS_BOT = 'SAVE_QUESTIONS_BOT'
export const DELETE_QUESTIONS_BOT = 'DELETE_QUESTIONS_BOT'

export const SETEANDO_OPCIONES_TRIGGER = 'SETEANDO_OPCIONES_TRIGGER'
export const SELECT_TRIGGER = 'SELECT_TRIGGER'

export const GUARDAR_ID_RESPONSABLE = 'GUARDAR_ID_RESPONSABLE'
