import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { APP_API_URL } from '../../config';
import { useDispatch } from 'react-redux'
import { saveDataUserLogin } from '../../store/actions/authActions';


const PrivateRoutes = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const [auth, setAuth] = useState('')
  const [token, setToken] = useState(true)

  const verificarToken = () => {
    axios.get(`${APP_API_URL}/admin/clients/verifyToken`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }).then((res) => {
      // console.log('Todo bien')
      setToken(false)
    }).catch(err => {
      setToken(false)
      localStorage.removeItem('userToken');
      localStorage.removeItem('user');
      return (<Redirect to='/login' />)
    })
  }
  const getInfoUser = () => {
    const user = localStorage.getItem('user');
    dispatch(saveDataUserLogin(JSON.parse(user)))
  }
  useEffect(() => {
    if (token) {
      verificarToken()
    }
    getInfoUser()
    const userToken = localStorage.getItem('userToken');

    setAuth(userToken)
  }, [token])
  return (
    <Route
      {...rest}
      render={props =>
        auth !== '' ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )}
    />
  )
}
export default PrivateRoutes;
