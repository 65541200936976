import axios from "axios";
import { APP_API_URL } from ".";

const clienteAxios = axios.create({
  baseURL: APP_API_URL,
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('userToken')
  }
});
export default clienteAxios;
