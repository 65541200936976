import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { Modal } from 'react-bootstrap';
import LabelError from '../components/LabelError';
import { useSelector } from "react-redux"
import { validateEmpty } from '../utils/validate';
import ValidateInput from '../utils/validateInput';
import {
  CircularProgress
} from "@material-ui/core";
import clienteAxios from '../config/axios';
import { APP_API_URL } from '../config';
import Swal from 'sweetalert2'

const Agents = () => {
  // Datos del agente
  const [datosAgente, setDatosAgente] = useState({
    correo: '',
    contraseña: '',
    nombre: ''
  });
  const state = useSelector(state => state.auth.user)
  const [modalAgent, setModalAgent] = useState(false)
  const [telefonoError, settelefonoError] = useState({ mensaje: "", error: false })
  const [loader, setLoader] = useState(false);
  const [errores, setErrores] = useState({ error: false, msj: '' });
  const [agentes, setAgentes] = useState([])

  const handleCreateAgent = () => {
    setModalAgent(true)
  }

  const handleChangueInput = (e) => {
    setDatosAgente({
      ...datosAgente,
      [e.target.name]: e.target.value
    })
  }

  const getAgents = async () => {
    let data = await clienteAxios.post(`${APP_API_URL}/admin/agents/getAllByCompanyNit`, {
      "companyId": state.user.role === 3 ? state.user.companyNit : state.user.dataUser.nit,
      "idDepartment": state.user.id
    }, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    });
    setAgentes(data.data.data.agents)
  }
  useEffect(() => {
    getAgents()
  }, [])

  const CreateAgent = async () => {
    const err = validateEmpty(datosAgente)

    if (Object.keys(err).length > 0) {
      console.log('Cre', err);
      setErrores({
        ...err,
        error: true
      })
      return
    }

    if (!ValidateInput.validateEmail(datosAgente.correo)) {
      setErrores({
        correo: 'Ingrese un correo valido',
        error: true
      })
      return
    }
    setErrores({ error: false, msj: '' })
    const obj = {
      "companyId": state.user.role === 3 ? state.user.companyNit : state.user.dataUser.nit,
      "email": datosAgente.correo,
      "password": datosAgente.contraseña,
      "name": datosAgente.nombre,
      "web": state.user.role === 3,
      "idDepartment": state.user.id
    }
    const data = await clienteAxios.post(`${APP_API_URL}/admin/agents`, obj, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    })
    if (data.data.success) {
      getAgents()
      setModalAgent(false)
      return
    }

    Swal.fire({
      icon: 'info',
      title: 'Advertencia',
      text: data.data.message,
    })

  }
  console.log(state);
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Agentes" className="text-sm-left mb-3" />
      </Row>

      <Row>
        <div style={{ width: '31%', marginTop: '10px' }} className="d-flex justify-content-between">
          <button onClick={handleCreateAgent} style={{ color: 'white', backgroundColor: '#341678', marginLeft: '10px' }} className="btn ">
            Crear agente
          </button>
        </div>
      </Row>

      <Row>
        <table className="table mb-0 mt-2">
          <thead className="text-white" style={{ backgroundColor: '#ff6363' }}>
            <tr>
              <th scope="col" className="border-0">
                Agente
              </th>

              <th scope="col" className="border-0">
                Correo
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: '#fff' }}>
            {loader ? (
              <div style={{ width: '280%', textAlign: 'end' }}>
                <CircularProgress size={130} />
              </div>
            ) : (
              <>

                {agentes.map(agente => (
                  <tr>
                    <td>{agente.name}</td>
                    <td>{agente.email}</td>
                  </tr>
                ))}

              </>
            )}
          </tbody>
        </table>
      </Row>

      <div className="form-group col-md-12 ">
        <Modal
          show={modalAgent}
          onHide={() => setModalAgent(false)}
          centered
          scrollable
          size="lg"
        >
          <Modal.Body style={{ padding: '30px' }}>
            <div>
              <h1>Creación de agentes</h1>
              <p>Estos agentes son los encargados de llevar el manejo de casos en tiempo real median un chat.</p>

              <div>
                <Row>
                  <Col lg="4">
                    <label>Nombre*</label>
                    <input
                      style={{ border: telefonoError.error ? "1px solid red" : "1px solid #341678" }}
                      value={datosAgente.nombre}
                      name="nombre"
                      onChange={(e) => handleChangueInput(e)}
                      placeholder="Correo"
                      type="text"
                      className="form-control"
                      autocomplete="off"
                    />
                    <LabelError data={{ mensaje: errores.correo, error: errores.error }} />
                  </Col>
                  <Col lg="4">
                    <label>Correo electronico*</label>
                    <input
                      style={{ border: telefonoError.error ? "1px solid red" : "1px solid #341678" }}
                      value={datosAgente.correo}
                      name="correo"
                      onChange={(e) => handleChangueInput(e)}
                      placeholder="Correo"
                      type="text"
                      className="form-control"
                      autocomplete="off"
                    />
                    <LabelError data={{ mensaje: errores.correo, error: errores.error }} />
                  </Col>
                  <Col lg="4">
                    <label>Contraseña*</label>
                    <input
                      style={{ border: telefonoError.error ? "1px solid red" : "1px solid #341678" }}
                      value={datosAgente.contraseña}
                      name="contraseña"
                      onChange={(e) => handleChangueInput(e)}
                      placeholder="Correo"
                      type="text"
                      className="form-control"
                      autocomplete="off"
                    />
                    <LabelError data={{ mensaje: errores.contraseña, error: errores.error }} />
                  </Col>
                </Row>
                <Row style={{ justifyContent: 'center', marginTop: '10px' }}>
                  <button onClick={() => setModalAgent(false)} className="btn bg-primary text-white w-130">Cancelar</button>
                  <button onClick={() => CreateAgent()} className="btn text-white w-130" style={{ backgroundColor: '#341678', marginLeft: 10 }}>
                    Crear Agente
                  </button>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

    </Container>
  );
}

export default Agents;
