import React, { useEffect, useState, createRef } from 'react';
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import { APP_API_URL } from '../../config';
import clienteAxios from '../../config/axios';
import { useSelector, useDispatch } from "react-redux"
import { saveDataUserLogin } from '../../store/actions/authActions';
import Swal from 'sweetalert2'
import Validate from '../../utils/validateInput';

const UserAccountDetails = ({ title }) => {

  const dispatch = useDispatch()

  const role = useSelector(state => state.auth.user.user.role)
  const user = useSelector(state => state.auth.user)
  console.log("USER-UserAccountDetails--->", user)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [nit, setNit] = useState("")
  const [idDepartment, setIdDepartment] = useState("")
  const [nameDepartment, setNameDepartment] = useState("")
  const [host, setHost] = useState("")
  const [channels, setChannels] = useState("")
  const [whatsapp, setWhatsapp] = useState("")

  useEffect(() => {
    switch (role) {
      case 1:
        setEmail(user.user.email);
        break;

      case 2:
        const dataUser = user.user.dataUser;
        setName(user.user.dataUser.responsable);
        setEmail(user.user.email);
        setTelephone(user.user.dataUser.phone);
        setNit(dataUser.nit);
        setChannels(dataUser.channels);
        setWhatsapp(user.user.whatsapp)
        break;

      case 3:
        setName(user.user.responsable);
        setEmail(user.user.email);
        setTelephone(user.user.phone);
        setNit(user.user.companyNit);
        setIdDepartment(user.user.id);
        setNameDepartment(user.user.nameDepartment);
        setHost(user.user.host);
        setChannels(user.user.channels);
        break;
    }
  }, [])

  const inputOnChangeName = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    setName(value);
  }

  const inputOnChangeTelephone = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    if (Validate.lengthNumber(value, 11)) {
      setTelephone(value);
    }
  }
  const inputOnChangeWhatsapp = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    if (Validate.lengthNumber(value, 11)) {
      setWhatsapp(value);
    }
  }

  const handleUpdate = () => {
    if (name === "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Digite el nombre"
      })
      return
    }

    if (telephone === "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Digite el número de teléfono"
      })
      return
    }
    if (whatsapp === "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Digite el número de WhatsApp"
      })
      return
    }
    if (whatsapp.length < 10) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Digite un número de WhatsApp correcto"
      })
      return
    }

    clienteAxios.post(`${APP_API_URL}/admin/profile/`, {
      "email": email,
      "responsable": name,
      "phone": telephone,
      "whatsapp": whatsapp,
      "role": role
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }
      }
    ).then((res) => {
      const json = res.data
      let obj = {}
      if (json.data != undefined && json.data.form === "OK") {
        switch (role) {
          case 2:
          case "2":
            obj = {
              ...user,
              user: {
                ...user.user,
                whatsapp: whatsapp,
                responsable: name,
                phone: telephone,
                dataUser: {
                  ...user.user.dataUser,
                  responsable: name,
                  phone: telephone
                }
              }
            }
            dispatch(saveDataUserLogin(obj))
            break;
          case 3:
          case "3":
            obj = {
              ...user,
              user: {
                ...user.user,
                responsable: name,
                phone: telephone
              }
            }
            dispatch(saveDataUserLogin(obj))
            break;

          default:
            break;
        }

        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: json.message
        })
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia!',
          text: json.message
        })
      }
    }).catch(err => console.log(err))
  }

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form className="text-center">
                {
                  role !== 1 ?
                    <Row>
                      {/* First Name */}
                      <Col md="6">
                        <label htmlFor="name">Nombre completo</label>
                        <FormInput
                          id="name"
                          value={name}
                          type="text"
                          //maxLength="80"
                          autocomplete="off"
                          onChange={inputOnChangeName}
                        />
                      </Col>
                      <Col md="6">
                        <label htmlFor="Teléfono">Teléfono</label>
                        <FormInput
                          id="Teléfono"
                          value={telephone}
                          autocomplete="off"
                          onChange={inputOnChangeTelephone}
                        />
                      </Col>
                    </Row>
                    : null
                }
                <Row>
                  {/* Email */}
                  <Col md="6">
                    <label htmlFor="Correo electrónico">Correo electrónico</label>
                    <FormInput
                      type="email"
                      id="Correo electrónico"
                      placeholder="Correo electrónico"
                      value={email}
                      disabled={true}
                      autoComplete="email"
                    />
                  </Col>
                  {/* Nit */}
                  {
                    role !== 1 ?
                      <Col md="6" className="form-group">
                        <label htmlFor="Nit">Nit</label>
                        <FormInput
                          id="Nit"
                          placeholder="Nit"
                          value={nit}
                          disabled={true}
                        />
                      </Col>
                      : null
                  }
                </Row>
                {
                  role === 3 ?
                    <Row>
                      {/* ID Departamento */}
                      <Col md="6">
                        <label htmlFor="ID Departamento">ID Departamento</label>
                        <FormInput
                          id="ID Departamento"
                          placeholder="ID Departamento"
                          value={idDepartment}
                          disabled={true}
                        />
                      </Col>
                      {/* Departamento */}
                      <Col md="6">
                        <label htmlFor="Departamento">Departamento</label>
                        <FormInput
                          id="Departamento"
                          placeholder="Departamento"
                          value={nameDepartment}
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    : null

                }
                <Row>
                  {/* Host */}
                  {
                    role === 3 ?
                      <Col md="6">
                        <label htmlFor="Host">Host</label>
                        <FormInput
                          id="Host"
                          placeholder="Host"
                          value={host}
                          disabled={true}
                        />
                      </Col>
                      : null
                  }
                  {/* Channels */}
                  {
                    role !== 1 ?
                      <Col md="6">
                        <label htmlFor="Channels">Channels</label>
                        <FormInput
                          id="Channels"
                          placeholder="Channels"
                          value={channels}
                          disabled={true}
                        />
                      </Col>
                      : null
                  }

                  {role == 2 && (
                    <Col md="6">
                      <label htmlFor="Teléfono">WhatsApp</label>
                      <FormInput
                        id="Teléfono"
                        value={whatsapp}
                        autocomplete="off"
                        onChange={inputOnChangeWhatsapp}
                      />
                    </Col>
                  )}
                </Row>
                <br />
                {
                  role !== 1 ?
                    <Button onClick={() => handleUpdate()} theme="accent">Actualizar datos</Button>
                    : null
                }
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  )
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Detalles de la cuenta"
};

export default UserAccountDetails;
