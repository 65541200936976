import React from 'react';
import { useSelector } from 'react-redux'

const Permission = ({ children }) => {
  const role = useSelector(state => state.auth.user.user.role)
  const [display, setDisplay] = React.useState(true)

  React.useEffect(() => {
    setDisplay(role === 1 || role === 2)
  }, [role])
  return (
    <>{display ? children : null}</>
  );
}

export default Permission;
