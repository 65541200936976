import React from 'react';

const LabelError = ({ data }) => {
  return (
    <>
      {data.error ? (<span style={{ color: "red" }}>{data.mensaje}</span>) : undefined}
    </>
  );
}

export default LabelError;
