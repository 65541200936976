import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, Table } from "react-bootstrap";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import Swal from 'sweetalert2'
import clienteAxios from '../config/axios';
import Select from 'react-select';
import moment from 'moment';
import { APP_API_URL } from '../config';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PageTitle from "../components/common/PageTitle";
import { ExportCSV } from '../components/ExportExcel';

const TrackingListState = (props) => {
  const user = useSelector(state => state.auth.user)
  const state = useSelector(state => state.auth)
  const role = user.user.role

  const inputSelectStatus = createRef()
  const inputSelectDepartments = createRef()

  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalcomentOpen, setModalcomentOpen] = useState(false)  
  const [arrListForm, setArrListForm] = useState([])
  const [arrDescription, setArrDescription] = useState([])
  const [arrDataForm, setArrDataForm] = useState([])
  const [comment, setComment] = useState("")
  const [key, setKey] = useState("")
  const [countRow, setCountRow] = useState(0)
  const [comentario, setComentario] = useState("HOLA")

  const [selectStatus, setSelectStatus] = useState("")
  const [selectDepartments, setSelectDepartments] = useState("")
  const [arrDepartments, setArrDepartments] = useState([])
  const [departments, setDepartments] = useState("")

  const [arrOptions, setArrOptions] = useState([])
  const [arrHistory, setArrHistory] = useState([])

  const [dateStart, setDateStart] = useState(new Date())
  const [dateFinish, setDateFinish] = useState(new Date())

  const [modalContacto, setmodalContacto] = useState(false)
  const [modalContacto2, setmodalContacto2] = useState(false)

  const { path } = props.match;

  let arrStatus = []

  switch (key) {
    case "/tracking-list-state"://Por contactar
      arrStatus = [
        { value: 1, label: 'Contactado' },
        { value: 3, label: 'En curso' },
      ]
      break;

    case "/tracking-list-state-one"://Contactado
      arrStatus = [
        { value: 3, label: 'En curso' },
      ]
      break;

    default:
      arrStatus = [
        { value: 3, label: 'En curso' },
        { value: 1, label: 'Contactado' },
      ]
      break;
  }

  useEffect(() => {
    if (role === 2) {
      getListDepartments();
    }
    else if (role === 3) {
      getListTracking()
      getListDepartments()
    }

  }, [])

  const getListDepartments = () => {
    clienteAxios.get(`${APP_API_URL}/admin/departments/${role == 3 ? user.user.companyNit : user.user.dataUser.nit}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }
    ).then((res) => {
      let arrDepart = [];
      if (res.data.data.deparments != undefined && res.data.data.deparments.length > 0) {
        arrDepart.push({ value: "", label: "Todos" });
        res.data.data.deparments.map((department) => {
          arrDepart.push({ value: department.id, label: department.nameDepartment });
        });
        setArrDepartments(arrDepart);
      }
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    handleSearch2()
  }, [])

  const getListTracking = (departments) => {
    clienteAxios.get(`${APP_API_URL}/admin/tracking/${role === 2 ? user.user.dataUser.nit : user.user.companyNit}/${role === 2 ? departments : user.user.id}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      }
    }).then((res) => {
      const json = res.data.data;
      if (json.form.length === 0) {
        setArrListForm([])
        return
      }
      let arrFilter = [];
      switch (path) {
        case "/tracking-list-state":
          arrFilter = json.form.filter(st => st.data.status == 0);
          break;

        case "/tracking-list-state-one":
          arrFilter = json.form.filter(st => st.data.status == 1);
          break;

        case "/tracking-list-state-two":
          arrFilter = json.form.filter(st => st.data.status == 2);
          break;

        case "/tracking-list-state-tree":
          arrFilter = json.form.filter(st => st.data.status == 3);
          break;

        default:
          arrFilter = json.form.filter(st => st.data.status == 4);
          break;
      }
      setCountRow(arrFilter.length);
      setArrListForm(arrFilter);
    }).catch(err => {
      console.log(err);
      window.location.reload();
    })
  }

  //Modal para el boton de comentario
  const handModalComent = (data,key)=>{
    setComentario(data)
    openModalcoment()
    setKey(key)
    // setComment(data.comment)
  }
  // const showComent = () => {
  //   {
  //     list.data.comment != undefined && list.data.comment != "" ?
  //       list.data.comment
  //       : "Sin comentario"
  //   }
  // }

  //Modal para el tracking de los campos
  const handleModalTracking = (data, key) => {
    openModal()
    setKey(key)
    setArrDescription(data.description)
    setArrDataForm(data.options)

    //Para visualizar los datos de contacto dinámicos
    let array = [];
    data.options.forEach((element) => {
      let x = Object.keys(element);
      x.map((e) => {
        array.push({ key: e, value: element[e] });
      });
    });
    setArrOptions(array);
    setArrHistory(data.history)
  }

  const openModal = () => {
    setIsOpen(true);
    
  }
  const openModalcoment = ()=> {
    setModalcomentOpen(true);
  }


  const closeModal = () => {
    setIsOpen(false);
    setModalcomentOpen(false);
  }

  
  //Onchange del input de ingreso para el formulario
  const onChangeComment = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    setComment(value);
  }
  //Guardando los registros del listado del formulario
  const handleSave = () => {
    if (comment === "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Digite un comentario al seguimiento"
      })
    }
    if (inputSelectStatus.current.state.value === null) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Seleccione un estado"
      })
      return
    }

    clienteAxios.post(`${APP_API_URL}/admin/tracking`, {
      "key": key,
      "status": inputSelectStatus.current.state.value.value,
      //"status": 1,
      "dateCreate": moment().format("YYYY-MM-DD HH:mm:ss"),
      "userCreate": user.user.stateChad == 5 ? user.user.dataUser.responsable : user.user.responsable,
      "comment": comment
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }
      }
    ).then((res) => {
      const json = res.data
      if (json.data != undefined && json.data.form === "OK") {
        setIsOpen(false)
        getListTracking()
        Swal.fire({
          icon: 'success',
          title: '¡Buenas noticias!',
          text: json.message
        })
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia!',
          text: json.message
        })
      }
    }).catch(err => console.log(err))
  }


  const OnchangeDepartments = (e) => {
    setDepartments(e.value);
    //getListTracking(e.value)
  }

  const Capitalize = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  }

  const handleSearch = () => {

    if (moment(dateStart).format() > moment(dateFinish).format()) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "La fecha de inicio no puede ser mayor a la fecha fin"
      })
      return
    }

    if (dateStart === "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Seleccione una fecha de inicio"
      })
      return
    }

    if (dateFinish === "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: "Seleccione una fecha fin"
      })
      return
    }

    let uDpto;
    switch (role) {
      case 1:
        departments !== "" ? uDpto = departments : uDpto = "null"
        break;

      case 2:
        departments !== "" ? uDpto = departments : uDpto = "null"
        break;

      case 3:
        uDpto = user.user.id;
        break;
    }

    clienteAxios.post(`${APP_API_URL}/admin/tracking/search`, {
      "nit": `${role == 3 ? user.user.companyNit : user.user.dataUser.nit}`,
      "department": uDpto
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }
      }
    ).then((res) => {
      const json = res.data
      if (json.success === true) {
        let arrRes = [];
        let fdateStart = moment(dateStart).format("DD/MM/YYYY")
        let fdateFinish = moment(dateFinish).format("DD/MM/YYYY")
        if (fdateStart === fdateFinish) {
          switch (path) {
            case "/tracking-list-state":
              arrRes = json.data.contact.filter(d =>
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") >= fdateStart &&
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") <= fdateFinish &&
                d.data.status == 0);
              break;

            case "/tracking-list-state-one":
              arrRes = json.data.contact.filter(d =>
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") >= fdateStart &&
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") <= fdateFinish &&
                d.data.status == 1);
              break;

            case "/tracking-list-state-two":
              arrRes = json.data.contact.filter(d =>
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") >= fdateStart &&
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") <= fdateFinish &&
                d.data.status == 2);
              break;

            case "/tracking-list-state-tree":
              arrRes = json.data.contact.filter(d =>
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") >= fdateStart &&
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") <= fdateFinish &&
                d.data.status == 3);
              break;

            default:
              arrRes = json.data.contact.filter(d =>
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") >= fdateStart &&
                moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") <= fdateFinish &&
                d.data.status == 4);
              break;
          }
        }
        else {
          let fdateStartBet = moment(dateStart).subtract(0, 'days');
          let fdateFinishBet = moment(dateFinish);
          switch (path) {
            case "/tracking-list-state":
              arrRes = json.data.contact.filter(d =>
                moment(moment(d.data.history[d.data.history.length - 1].date).subtract(1, 'hours')).isBetween(fdateStartBet, fdateFinishBet) &&
                d.data.status == 0);
              break;

            case "/tracking-list-state-one":
              arrRes = json.data.contact.filter(d =>
                moment(moment(d.data.history[d.data.history.length - 1].date).subtract(1, 'hours')).isBetween(fdateStartBet, fdateFinishBet) &&
                d.data.status == 1);
              break;

            case "/tracking-list-state-two":
              arrRes = json.data.contact.filter(d =>
                moment(moment(d.data.history[d.data.history.length - 1].date).subtract(1, 'hours')).isBetween(fdateStartBet, fdateFinishBet) &&
                d.data.status == 2);
              break;

            case "/tracking-list-state-tree":
              arrRes = json.data.contact.filter(d =>
                moment(moment(d.data.history[d.data.history.length - 1].date).subtract(1, 'hours')).isBetween(fdateStartBet, fdateFinishBet) &&
                d.data.status == 3);
              break;

            default:
              arrRes = json.data.contact.filter(d =>
                moment(moment(d.data.history[d.data.history.length - 1].date).subtract(1, 'hours')).isBetween(fdateStartBet, fdateFinishBet) &&
                d.data.status == 4);
              break;
          }
        }
        setCountRow(arrRes.length);
        setArrListForm(arrRes);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia!',
          text: json.message
        })
      }
    }).catch(err => console.log(err))
  }

  const handleSearch2 = () => {

    let uDpto;
    switch (role) {
      case 1:
        departments !== "" ? uDpto = departments : uDpto = "null"
        break;

      case 2:
        departments !== "" ? uDpto = departments : uDpto = "null"
        break;

      case 3:
        uDpto = user.user.id;
        break;
    }

    clienteAxios.post(`${APP_API_URL}/admin/tracking/search`, {
      "nit": `${role == 3 ? user.user.companyNit : user.user.dataUser.nit}`,
      "department": uDpto
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }
      }
    ).then((res) => {
      const json = res.data
      if (json.success === true) {
        let date = new Date();
        let dateFirtDay = new Date(date.getFullYear(), date.getMonth(), 1)
        let dateLastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        let firtDay = moment(dateFirtDay).format("DD/MM/YYYY")
        let lastDay = moment(dateLastDay).format("DD/MM/YYYY")
        setDateStart(dateFirtDay)
        setDateFinish(dateLastDay)
        let arrRes = [];
        switch (path) {
          case "/tracking-list-state":
            arrRes = json.data.contact.filter(d =>
              moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") >= firtDay &&
              moment(d.data.history[d.data.history.length - 1].date).format("DD/MM/YYYY") <= lastDay &&
              d.data.status == 0);
            break;

          case "/tracking-list-state-one":
            arrRes = json.data.contact.filter(d => d.data.status == 1);
            break;

          case "/tracking-list-state-two":
            arrRes = json.data.contact.filter(d => d.data.status == 2);
            break;

          case "/tracking-list-state-tree":
            arrRes = json.data.contact.filter(d => d.data.status == 3);
            break;

          default:
            arrRes = json.data.contact.filter(d => d.data.status == 4);
            break;
        }

        setCountRow(arrRes.length);
        setArrListForm(arrRes);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia!',
          text: json.message
        })
      }
    }).catch(err => console.log(err))
  }

  const openManualModal = () => {
    switch (path) {
      case "/tracking-list-state":
        setmodalContacto(true)
        break;

      case "/tracking-list-state-one":
        setmodalContacto2(true)
        break;

      default:
        break;
    }
  }

  const closeContactModal = () => {
    setmodalContacto(false)
  }

  const closeContactModal2 = () => {
    setmodalContacto2(false)
  }
  //console.log(arrListForm);
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        {
          path === "/tracking-list-state" ?
            <PageTitle sm="4" title="Gestión por contactar" subtitle="Listado de usuarios" className="text-sm-left" openManualModal={openManualModal} />
            : path === "/tracking-list-state-one" ?
              <PageTitle sm="4" title="Gestión contactados" subtitle="Listado de usuarios" className="text-sm-left" openManualModal={openManualModal} />
              : path === "/tracking-list-state-two" ?
                <PageTitle sm="4" title="Gestión atendidos" subtitle="Listado de usuarios" className="text-sm-left" />
                : path === "/tracking-list-state-tree" ?
                  <PageTitle sm="4" title="Gestión en curso" subtitle="Listado de usuarios" className="text-sm-left" />
                  : <PageTitle sm="4" title="Gestión no desea ser contactado" subtitle="Listado de usuarios" className="text-sm-left" />
        }
      </Row>

      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <Row>
                <Col>
                  {
                    /**Validando el perfil del usuario para mostrar o no los filtros  */
                    role === 2 ?
                      <div>
                        <label>Departamento</label>
                        <Select
                          ref={inputSelectDepartments}
                          className="basic-single"
                          classNamePrefix="Departamentos"
                          defaultValue={{ value: "", label: "Todos" }}
                          name="Departamentos"
                          options={arrDepartments}
                          onChange={OnchangeDepartments}
                        />
                      </div>
                      : null
                  }
                </Col>
                <Col sm="2">
                  <label>Fecha de inicio</label><br />
                  <DatePicker className="form-control" selected={dateStart} onChange={date => setDateStart(date)} />
                </Col>
                <Col sm="2">
                  <label>Fecha fin</label><br />
                  <DatePicker className="form-control" selected={dateFinish} onChange={date => setDateFinish(date)} />
                </Col>
                <Col sm="2">
                  <br />
                  <button onClick={handleSearch} className="btn btn-primary btn-sm">Buscar</button>
                </Col>
                <Col></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                <b>Total de registros: {countRow}</b>
                {countRow > 0 && (<ExportCSV csvData={arrListForm != undefined && arrListForm.length > 0 ? arrListForm : []} fileName="data"/>)}
              </div>
              <div  style={{overflow:'hidden'}}>

              <Table className="table table-responsive-lg mb-0 mt-2">
                <thead className="text-white" style={{ backgroundColor: '#ff6363',textAlign:'center'}}>
                  <tr style={{display:'block'}}>
                    <th style={{width:'35px'}}>#</th>
                    <th style={{width:'112px'}}>Departamento</th>
                    <th style={{width:'100px'}}>Nombre</th>
                    <th style={{width:'190px'}}>Fecha de contacto</th>
                    <th style={{width:'100px'}}>Ciudad</th>
                    <th style={{width:'125px'}}>Teléfono</th>
                    <th style={{width:'130px'}}>Correo</th>
                    <th style={{width:'100px'}}>Comentario</th>
                    <th style={{width:'120px'}}>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style={{display:'block'}}>
                  {
                    arrListForm != undefined && arrListForm.length > 0 ?
                      arrListForm.map((list, i) => {
                        const listDepartment = list.key.split('_')
                        const department = arrDepartments.filter(e => e.value == listDepartment[2])
                        return (
                          <tr key={i}>

                            <td  style={{width:'35px',border:'none'}}>
                              {i + 1}
                            </td>
                            <td style={{width:'120px',display:'inline-block', border:'none'}}>
                              {department.length > 0 ? department[0].label : "No hay na"}
                            </td>
                            <td style={{width:'134px', display:'inline-block', border:'none'}}>{list.data.options[2].completeName}</td>
                            <td style={{width:'130px',display:'inline-block', border:'none'}}>
                              {moment(list.data.history[list.data.history.length - 1].date).format("DD/MM/YYYY  HH:mm:ss")}
                            </td>
                            <td style={{width:'120px', display:'inline-block', border:'none'}}>
                              {list.data.options[0].country}
                            </td>
                            <td style={{width:'120px', border:'none',display:'inline-block'}}>
                              {list.data.options[1].telephone}
                            </td>
                            <td style={{width:'134px',display:' inline-block', border:'none'}}>
                              {list.data.options[3].Correo}
                            </td>
                            <td style={{border:'none', width:'101px'}}>
                              <button onClick={() => handModalComent(list.data.comment,list.key)} className="btn btn-display bg-primary text-white"><i class="far fa-comment-dots"></i></button>
                            </td>
                            
                            <td style={{width:'120px',border:'none', display:'block'}}>
                            <div style={{width:'136px',border:'none'}}  className='row'>
                              {
                                path === "/tracking-list-state" ?
                                  <span  style={{width:'136px',display:'block',background:'#fff', color:"#000"}} className="badge badge-info col-12">Por contactar</span>
                                  : path === "/tracking-list-state-one" ?
                                    <span style={{width:'136px',display:'block',background:'#fff', color:"#000"}} className="badge badge-info col-12">Contactado</span>
                                    : path === "/tracking-list-state-two" ?
                                      <span style={{width:'136px',display:'block', background:'#fff', color:"#000"}} className="badge badge-info col-12">Atendido</span>
                                      : path === "/tracking-list-state-tree" ?
                                        <span className="badge badge-info col-12">En curso</span>
                                        : <span style={{width:'200px', display:'inline-block', background:'#fff', color:"#000"}} className="badge badge-info col-12">No desea ser contactado</span>
                              }
                              <button onClick={() => handleModalTracking(list.data, list.key)} className="btn btn-sm col-12 mt-1" style={{width:'136px',background:"#341678", color:'white'}}>Seguimiento</button>
                            </div>
                            </td>
                            
                            
                          </tr>
                        )
                      })
                      :
                      <tr>
                        <td colSpan="7">No hay datos registrados</td>
                      </tr>
                  }
                </tbody>
              </Table>
              </div>
              
            </CardBody>
          </Card>
        </Col>
      </Row>

      
      {/**Modal Comentario*/}
      <Modal  size="lg"show= {modalcomentOpen} onHide={closeModal} centered scrollable>
      <ModalBody>
        <h4 className="page-title" style={{ color: "#341678", fontWeight: "bold" }}>Comentario</h4>
              <Row>
                <Col>
                {/* { comentario != undefined ?comentario : "sin comentarioJSDDFJSDADFJSDFJS"} */
                comentario
                }
                </Col>
              </Row>
              <div className="d-flex justify-content-around py-4">
              <button onClick={() => { closeModal() }} className="btn btn-display bg-primary text-white">Cerrar</button>
            </div>
        </ModalBody>


      </Modal>
      {/**Modal Nuevo*/}
      <Modal size="lg" show={modalIsOpen} onHide={closeModal} centered scrollable>
        <Modal.Body>
          <div className="container">
            <h4 className="page-title" style={{ color: "#341678", fontWeight: "bold" }}>Seguimiento</h4>
            <Table>
              <thead>
                <tr>
                  <th>Datos del contacto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      {
                        arrOptions.length > 0 ?
                          arrOptions.map((data, i) => {
                            return (
                              <li key={i}>
                                {
                                  data.key === "country" ?
                                    `Ciudad: ${data.value}`
                                    : data.key === "telephone"
                                      ? `Teléfono: ${data.value}`
                                      : `${Capitalize(data.key)}: ${data.value}`
                                }
                              </li>
                            )
                          })
                          : null
                      }
                    </ul>
                  </td>
                </tr>
              </tbody>
            </Table>

            <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
              <Tab label="Historial de seguimiento">
                <br />
                {
                  role === 3 || (role == 2 && user.user.stateChad == 5) ?//Validando los roles
                    path === "/tracking-list-state" ? //Validando la página de por contactar
                      <>
                        <Row>
                          <Col>
                            <label>Comentario</label>
                            <textarea className="form-control" onChange={onChangeComment}></textarea>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <label>Estado</label>
                            <Select
                              ref={inputSelectStatus}
                              className="basic-single"
                              classNamePrefix="Estado"
                              defaultValue={selectStatus[0]}
                              name="Estado"
                              options={arrStatus}
                            />
                          </Col>
                          <Col>
                            <button onClick={() => { handleSave() }} className="btn btn-display text-white" style={{ backgroundColor: "rgb(52, 22, 120)" }}>Guardar seguimiento</button>
                          </Col>
                          <Col></Col>
                          <Col></Col>
                        </Row>
                      </>
                      : path === "/tracking-list-state-tree" ? //Validando la página de por contactar
                        <>
                          <Row>
                            <Col>
                              <label>Comentario</label>
                              <textarea className="form-control" onChange={onChangeComment}></textarea>
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>
                              <label>Estado</label>
                              <Select
                                ref={inputSelectStatus}
                                className="basic-single"
                                classNamePrefix="Estado"
                                defaultValue={selectStatus[0]}
                                name="Estado"
                                options={arrStatus}
                              />
                            </Col>
                            <Col>
                              <button onClick={() => { handleSave() }} className="btn btn-display text-white" style={{ backgroundColor: "rgb(52, 22, 120)" }}>Guardar seguimiento</button>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                          </Row>
                        </>
                        : null
                    : null
                }

                <br />
                <Table className="table table-responsive-lg table-hover mb-0 mt-2">
                  <thead className="text-white" style={{ backgroundColor: '#ff6363' }}>
                    <tr>
                      <th>#</th>
                      <th>Fecha creación</th>
                      <th>Responsable</th>
                      <th>Estado</th>
                      <th>Descripción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      arrDescription.length > 0 ?
                        arrDescription.map((description, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{description.userCreate}</td>
                              <td>{description.dateCreate}</td>
                              <td>{
                                description.status === 0 ?
                                  <span className="badge badge-info">Por contactar</span>
                                  : description.status === 1 ?
                                    <span className="badge badge-success">Contactado</span>
                                    : description.status === 2 ?
                                      <span className="badge badge-primary">Atendido</span>
                                      : description.status === 3 ?
                                        <span className="badge badge-dark">En curso</span>
                                        : <span className="badge badge-danger">No desea ser contactado</span>
                              }</td>
                              <td>{description.comment}</td>
                            </tr>
                          )
                        })
                        :
                        <tr>
                          <td colspan="5">No hay seguimientos registrados</td>
                        </tr>
                    }
                  </tbody>
                </Table>
              </Tab>

              <Tab label="Historial de solicitudes">
                <Table className="table table-responsive-lg table-hover mb-0 mt-2">
                  <thead className="text-white" style={{ backgroundColor: '#ff6363' }}>
                    <tr>
                      <th>#</th>
                      <th>Fecha creación</th>
                      <th>Comentario</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      arrHistory.length > 0 ?
                        arrHistory.map((history, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{moment(history.date).format("YYYY-MM-DD HH:mm:ss")}</td>
                              <td>
                                {
                                  history.comment != undefined && history.comment != "" ?
                                    history.comment
                                    : "Sin comentario"
                                }
                              </td>
                              <td>{
                                history.status === 0 ?
                                  <span className="badge badge-info">Por contactar</span>
                                  : history.status === 1 ?
                                    <span className="badge badge-success">Contactado</span>
                                    : history.status === 2 ?
                                      <span className="badge badge-primary">Atendido</span>
                                      : history.status === 3 ?
                                        <span className="badge badge-dark">En curso</span>
                                        : <span className="badge badge-danger">No desea ser contactado</span>
                              }</td>
                            </tr>
                          )
                        })
                        :
                        <tr>
                          <td colspan="3">No hay historial registrados</td>
                        </tr>
                    }
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
            <div className="d-flex justify-content-around py-4">
              <button onClick={() => { closeModal() }} className="btn btn-display bg-primary text-white">Cerrar</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="form-group col-md-12 ">
        <Modal
          show={modalContacto}
          onHide={closeContactModal}
          centered
          scrollable
          size="lg"
        >
          <Modal.Body style={{ height: '2500px' }}>
            <iframe src={role === 2 ? "https://chatome.com.co/media/manuales/dashboard/perfil-2/por_contactar.pdf" : role === 3 ? "https://chatome.com.co/media/manuales/dashboard/perfil-3/por_contactar.pdf" : ""} style={{ width: '100%', height: "100%" }} title="Iframe Dashboard"></iframe>
          </Modal.Body>
        </Modal>
      </div>

      <div className="form-group col-md-12 ">
        <Modal
          show={modalContacto2}
          onHide={closeContactModal2}
          centered
          scrollable
          size="lg"
        >
          <Modal.Body style={{ height: '2500px' }}>
            <iframe src={role === 2 ? "https://chatome.com.co/media/manuales/dashboard/perfil-2/contactado.pdf" : role === 3 ? "https://chatome.com.co/media/manuales/dashboard/perfil-3/contactado.pdf" : ""} style={{ width: '100%', height: "100%" }} title="Iframe Dashboard"></iframe>
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  )
}

export default TrackingListState;
