import React, { useState } from "react";
import {
  Container,

} from "@material-ui/core";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Col,
  Row,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Switch from '@material-ui/core/Switch';
import { useSelector, useDispatch } from "react-redux"

const Installer = (props) => {
  const dispatch = useDispatch()
  const numberWapp = useSelector(state => state.auth.user.user.whatsapp)
  const { companyNit, phone, id } = props.client;

  const [copied, setCopied] = useState(false)
  const [siwtch, setSwitch] = useState(false)

  const handleCopied = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 4000)
  }
  return (
    <Container
      fluid
      className="main-content-container px-4"
    >
      <Row noGutters className="page-header py-4">
        <PageTitle
          title=""
          subtitle="Instalación"
          className="text-sm-left mb-3"
        />
      </Row>
      <Col>
        <div className="">

          <h2 style={{ fontWeight: '700', color: "rgb(52, 22, 120)" }}>¡Instalar Cha<span style={{ color: 'rgb(255, 99, 99)' }}>to</span>me</h2>
          <h2 style={{ fontWeight: '700', color: "rgb(52, 22, 120)" }}>en tu sitio web <span style={{ color: 'rgb(255, 99, 99)' }}>es muy fácil!</span></h2>

          <p style={{ fontWeight: '700', color: 'rgb(52, 22, 120)' }}>Solo debes copiar este código y pegarlo al final del Html de tu pagina web</p>
        </div>

        <span style={{ padding: '20px' }}>¿Su pagina cuenta con Jquery?<Switch
          checked={siwtch}
          onChange={() => setSwitch(!siwtch)}
          name="checkedA"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        /></span>

        <div className="code-chatbot" style={{ position: 'relative', backgroundColor: "#f6f6f6", padding: '5px', borderRadius: '5px' }}>
          <div style={{ position: 'absolute', top: '0px', left: '0px', backgroundColor: '#EFEFEF', padding: '2px 5px', cursor: 'pointer' }}>
            <CopyToClipboard onCopy={() => handleCopied()} text={`
<script>
      const loadScriptAsync = function(uri){
          return new Promise((resolve, reject) => {
              sessionStorage.setItem("nitEmpresa", '${companyNit}')
              sessionStorage.setItem("phoneEnterprise", '${phone}')
              sessionStorage.setItem("idResponsable", '${id}')
              const tag = document.createElement('script');
              tag.src = uri;
              tag.async = true;
              tag.onload = () => {
                  resolve();
              };
              const firstScriptTag = document.getElementsByTagName('script')[0];
              firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
          });
      }
      const scriptLoaded = loadScriptAsync('https://media.chatome.com.co/js/${siwtch ? 'widgetChatBotJquery.js' : 'widgetChatBot.js'}');
</script>`}>

              <span>
                <i className="fas fa-copy"></i>
                {` Copy`}
              </span>

            </CopyToClipboard>
          </div>
          <span style={{ position: 'absolute', top: '5px', left: '40px', marginLeft: '20px', color: 'rgb(52, 22, 120)' }}>{copied ? "Script copiado!" : ''}</span>
          <pre>
            <code>
              {`
<script>
    const loadScriptAsync = function(uri){
        return new Promise((resolve, reject) => {
            sessionStorage.setItem("nitEmpresa", '${companyNit}')
            sessionStorage.setItem("phoneEnterprise", '${phone}')
            sessionStorage.setItem("idResponsable", '${id}')
            const tag = document.createElement('script');
            tag.src = uri;
            tag.async = true;
            tag.onload = () => {
                resolve();
            };
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        });
    }
    const scriptLoaded = loadScriptAsync('https://media.chatome.com.co/js/${siwtch ? 'widgetChatBotJquery.js' : 'widgetChatBot.js'}');
</script>`}
            </code>
          </pre>
        </div>

      </Col>
      {/* <button onClick={() => handleGetQr()}>Generar token</button>
      {showQR ? (<QRCode value={qr} />) : loader && <CircularProgress />} */}
      {/* <div style={{ position: 'relative' }}>
        <img style={{
          position: 'absolute', width: '280px',
          bottom: '0px',
          right: '10px'
        }} src={require('../assets/header/CHAD-01.png')} alt="" />
      </div> */}
    </Container>
  );
};

export default Installer;
