import React, { useState, useEffect } from 'react';
import imageLogo from '../images/login/logo.png'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import '../assets/login.css'
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap';

import { APP_API_URL } from '../config';
import { saveDataUserLogin } from '../store/actions/authActions';

const Login = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [forgotEmail, setForgotEmail] = useState("")
  const [msgEnviado, setMsgEnviado] = useState(false)
  // const [error, setError] = useState(false)
  const [redirect, setRedirect] = useState(false);
  /***
   * Credenciales de acceso
    user: admin@chatome.com
    login: toYZzcG$Gq
   */

  const validateEmptyValues = () => {
    if (email === "" || password === '') {
      // setError(true)
      return false
    }
    // setError(false)
    return true
  }
  const handleSubmit = (e) => {
    if (validateEmptyValues()) {
      axios.post(`${APP_API_URL}/auth/login`, {
        email,
        password
      }).then((res) => {
        if (res.data.StatusCode === 200) {
          dispatch(saveDataUserLogin(res.data.data))
          localStorage.setItem('user', JSON.stringify(res.data.data));
          localStorage.setItem('userToken', res.data.data.token);
          setRedirect(true)
          return
        }


      }).catch(err => {
        console.log(err)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Credenciales incorrectas',
        })
      })
    }
  }
  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (userToken != undefined && userToken != null && userToken != 'null') {
      setRedirect(true);
    } else {
      setRedirect(false);
    }

  }, [])

  const handleForgotPassword = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (forgotEmail === "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Debe suminsitrar un correo',
      })
      return
    }

    // if (regex.test(regex)) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Debe suminsitrar un correo valido',
    //   })
    //   return
    // }
    axios
      .post(
        `${APP_API_URL}/auth/forgotMyPassword`,
        {
          email: forgotEmail
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            ContentType: "application/x-www-form-urlencoded"
          }
        }
      ).then(res => {
        if (!res.data.success) {
          setMsgEnviado(false)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.data.message,
          })
        }

        setMsgEnviado(true)
      }).catch(res => {
        setMsgEnviado(false)
      })
  }


  if (redirect) {

    return <Redirect to='/dashboard' />;
  }
  return (
    <div className="text-center d-grid container-fluid" style={{ position: 'relative' }}>
      <div style={{ position: 'absolute' }}>..
        <img style={{ width: '100px' }} src={require('../assets/header/mas-1.png')} alt="" />
      </div>
      <div style={{ width: '250px', position: 'absolute', bottom: '-120px', left: '50px' }}>
        <img className="App-logo2" style={{ width: '100%' }} src={require('../assets/header/mas-2.png')} alt="" />
      </div>
      <div style={{ position: 'absolute', right: '60px' }}>
        <img className="App-logo" style={{ width: '100px', bottom: '-100px' }} src={require('../assets/header/mas-3.png')} alt="" />
      </div>
      <div className="d-block">
        <a href="https://chatome.com.co" target="_blank" >
          <img className="Login__logo" src={imageLogo} alt="login" />
        </a>
      </div>
      <div className="">
        <h1 style={{ color: '#5D4592', fontWeight: '700', marginTop: "3%" }}>Bienvenido humano</h1>
      </div>
      <form onSubmit={handleSubmit} className="Login__form">
        <div className="mb-3">
          <input value={email} onChange={(e) => setEmail(e.target.value)} maxLength="76" placeholder="Correo electronico" type="email" className="form-control inputLogin" />
        </div>
        <div className="mb-3">
          <input value={password} onChange={(e) => setPassword(e.target.value)} maxLength="20" placeholder="Contraseña" type="password" className="form-control inputLogin" />
        </div>

        <button onClick={() => handleSubmit()} type="button" className="btn btn-primary btn_login">Ingresar</button>
        <span onClick={() => setShowModal(true)} className="d-block mt-2" style={{ cursor: "pointer" }}>Olvide mi contraseña</span>
      </form>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        scrollable
        size="lg"

      >
        <Modal.Body>
          <div>
            <>
              {msgEnviado ? (
                <>
                  <h2 style={{ textAlign: 'center', fontWeight: '700', color: '#341677' }}>Revisa tu correo</h2>
                  <h4 style={{ textAlign: 'center', color: "#341677" }}>Hemos enviado la clave a tu correo, revisalo y vuelve a iniciar sesión</h4>
                  <div style={{ justifyContent: 'center', display: 'flex', marginTop: '10px' }}>
                    <Button onClick={() => { setShowModal(false); setMsgEnviado(false) }}>Aceptar</Button>
                  </div>
                </>
              ) : (
                <>
                  <h2 style={{ textAlign: 'center', fontWeight: '700', color: "#ff696a" }}>¿Olvidaste tu contraseña?</h2>
                  <h4 style={{ textAlign: 'center', color: "#341677"}} >Ingresa tu correo electrónico registrado para enviarte la contraseña</h4>

                  <div className="mt-2">
                    <div>
                      <input value={forgotEmail} onChange={(e) => setForgotEmail(e.target.value)} maxLength="76" placeholder="Correo electronico" type="email" className="form-control inputLogin" />
                    </div>
                  </div>

                  <div style={{ justifyContent: 'center', display: 'flex', marginTop: '10px' }}>
                    <Button onClick={() => handleForgotPassword()}>Enviar</Button>
                  </div>
                </>
              )}

            </>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Login;
