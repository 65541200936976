import React, { useEffect, useState, createRef, useRef } from 'react';
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Form,
  FormInput,
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from "shards-react";
import avatar from "./../../images/avatars/avatar-chad.png";
import { useSelector } from "react-redux"
import Swal from 'sweetalert2'
import { APP_API_URL } from '../../config';
import clienteAxios from '../../config/axios';
import { Modal } from 'react-bootstrap';

const UserDetails = ({ userDetails }) => {

  const role = useSelector(state => state.auth.user.user.role)
  const user = useSelector(state => state.auth.user)

  const [name, setName] = useState("")
  const [nameRole, setNameRole] = useState("")

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordNew1, setPasswordNew1] = useState("")
  const [passwordNew2, setPasswordNew2] = useState("")

  const [modalPhoto, setModalPhoto] = useState(false);
  const [photoPrev, setPhotoPrev] = useState('');
  const inputPhoto = useRef(null);

  useEffect(() => {
    setEmail(user.user.email)
    switch (role) {
      case 1:
        setNameRole("Administrador");
        break;

      case 2:
        setNameRole("Empresa");
        setName(user.user.responsable);
        break;

      case 3:
        setNameRole("Departamento");
        setName(user.user.responsable)
        break;
    }
  }, [name])

  const OnChangePassword = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    setPassword(value);
  }

  const OnChangePasswordNew1 = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    setPasswordNew1(value);
  }

  const OnChangePasswordNew2 = (e) => {
    const { target: { value = '' } = {} } = { ...e };
    setPasswordNew2(value);
  }

  const handlePassword = () => {
    if (password == "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: 'El campo de Contraseña actual no puede estar vacio',
      })
      return
    }

    if (passwordNew1 == "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: 'El campo de Nueva contraseña no puede estar vacio',
      })
      return
    }
    if (passwordNew2 == "") {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: 'El campo de Repetir contraseña no puede estar vacio',
      })
      return
    }
    if (passwordNew1 !== passwordNew2) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia!',
        text: 'La Nueva contraseña no coincide con el campo Repetir contraseña',
      })
      return
    }

    clienteAxios.post(`${APP_API_URL}/auth/changePasswordUser`, {
      "email": email,
      "password": password,
      "newPassword": passwordNew2
    },
      {
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('userToken') }
      }
    ).then((res) => {
      const json = res.data
      if (json.success === true) {

        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: json.message
        })
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia!',
          text: json.message
        })
      }
    }).catch(err => console.log(err))
  }

  const showModalPhoto = () => {
    setModalPhoto(true);
    setPhotoPrev("");
  }

  const closeModalPhoto = () => {
    setModalPhoto(false);
  }

  const prevPhotoNew = (e) => {
    var TmpPath = URL.createObjectURL(e.target.files[0]);
    setPhotoPrev(TmpPath);
  }

  const handleUploadPhoto = (event) => {

  }

  return (
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <Row>
          <Col md="6">
            <div className="mb-3 mx-auto">
              <img
                className="rounded-circle"
                src={avatar}
                width="90"
              />
            </div>
          </Col>
          <Col md="6">
            <h5 className="mb-0">{name}</h5>
            <span className="text-muted d-block mb-2">{nameRole}</span>
            {/* <Button pill outline size="sm" className="mb-2" onClick={showModalPhoto}>
            <i className="material-icons mr-1">image_add</i> Subir foto
          </Button> */}
          </Col>
        </Row>
      </CardHeader>

      <CardHeader className="border-bottom text-center">
        <div style={{ fontWeight: "bold" }} className="mb-2 mx-auto">
          Restablecer contraseña
      </div>
        <Row>
          <Col>
            <label htmlFor="Channels">Contraseña actual</label>
            <FormInput
              id="pass"
              type="password"
              onChange={OnChangePassword}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <label htmlFor="Channels">Nueva contraseña</label>
            <FormInput
              id="passNew"
              type="password"
              onChange={OnChangePasswordNew1}
            />
          </Col>
          <Col md="6">
            <label htmlFor="Channels">Repetir contraseña</label>
            <FormInput
              id="passNew2"
              type="password"
              onChange={OnChangePasswordNew2}
            />
          </Col>
        </Row>
        <br />
        <Button size="sm" className="mb-2" theme="accent" onClick={handlePassword}>
          Actualizar contraseña
      </Button>
      </CardHeader>


      <Modal
        show={modalPhoto}
        onHide={closeModalPhoto}
        scrollable

      >
        <Modal.Header>Subir foto de perfil</Modal.Header>
        <Modal.Body>
          <Form
            id="form-registro"
            onSubmit={handleUploadPhoto}
            encType="multipart/form-data">

            <Row>
              <Col>
                <label>Seleccione una imagen</label>
                <input
                  className="form-control"
                  type="file"
                  name="logoNuevo"
                  ref={inputPhoto}
                  onChange={prevPhotoNew}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <fieldset>
                  <legend>Previsualización</legend>
                  <div className="text-center">
                    <img
                      src={photoPrev}
                      id="preview"
                      style={{ width: '100%' }}
                      alt="Photo Profile"
                    />
                  </div>
                </fieldset>
              </Col>
            </Row>
            <div className="d-flex justify-content-around py-4">
              <button className="btn btn-display bg-secondary text-white">Subir foto</button>
              <button onClick={() => { closeModalPhoto() }} className="btn btn-display bg-primary text-white">Cerrar</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Card>

  )
}

export default UserDetails;
