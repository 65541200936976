import {
  LOGIN,
  LOGOUT,
  SAVE_QUESTION_BOT,
  SAVE_QUESTIONS_BOT,
  DELETE_QUESTIONS_BOT,
  SETEANDO_OPCIONES_TRIGGER,
  GUARDAR_ID_RESPONSABLE
} from "../types";

export const saveDataUserLogin = (user) => async (dispatch) => {

  try {
    dispatch(SaveUserData(user))
  } catch (error) {
    console.log(error)
  }
}

const SaveUserData = (user) => ({
  type: LOGIN,
  payload: user,
});
export const deleteDataUserLogout = () => async (dispatch) => {

  try {
    dispatch(deleteUserData())
  } catch (error) {
    console.log(error)
  }
}

const deleteUserData = () => ({
  type: LOGOUT,
});

export const saveQuestionBotAction = (question) => async (dispath) => {
  try {
    dispath(saveQuestionBot(question))
  } catch (error) {
    console.log(error);
  }
}
const saveQuestionBot = (question) => ({
  type: SAVE_QUESTION_BOT,
  payload: question
})
export const saveQuestionsBotAction = (questions) => async (dispath) => {
  try {
    dispath(saveQuestiosnBot(questions))
  } catch (error) {
    console.log(error);
  }
}
const saveQuestiosnBot = (questions) => ({
  type: SAVE_QUESTIONS_BOT,
  payload: questions
})
export const deleteQuestionsBotAction = (key) => async (dispath) => {
  try {
    dispath(deleteQuestiosnBot(key))
  } catch (error) {
    console.log(error);
  }
}
const deleteQuestiosnBot = (key) => ({
  type: DELETE_QUESTIONS_BOT,
  payload: key
})
export const setTriggerOptionsAction = (triggers) => async (dispath) => {
  try {
    dispath(setTriggerOption(triggers))
  } catch (error) {
    console.log(error);
  }
}
const setTriggerOption = (triggers) => ({
  type: SETEANDO_OPCIONES_TRIGGER,
  payload: triggers
})
export const selectTriggerAction = (triggers) => async (dispath) => {
  try {
    dispath(selectTrigger(triggers))
  } catch (error) {
    console.log(error);
  }
}
const selectTrigger = (triggers) => ({
  type: SETEANDO_OPCIONES_TRIGGER,
  payload: triggers
})
export const guardarIdResponsableAction = (idResponsable) => async (dispath) => {
  try {
    dispath(guardarIdResponsable(idResponsable))
  } catch (error) {
    console.log(error);
  }
}
const guardarIdResponsable = (idResponsable) => ({
  type: GUARDAR_ID_RESPONSABLE,
  payload: idResponsable
})
