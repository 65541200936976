import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button
} from "shards-react";
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2'

import { saveQuestionBotAction, saveQuestionsBotAction } from "../../store/actions/authActions";
import axios from "axios";
import { APP_API_URL } from "../../config";

const NewDraft = ({ title, path, setLoadingData }) => {
  const dispatch = useDispatch()
  //const nit = useSelector(state => state.auth.user.user.dataUser.nit)
  //const phone = useSelector(state => state.auth.user.user.dataUser.phone)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const user = useSelector(state => state.auth.user);
  const state = useSelector(state => state.auth);
  const [pregunta, setPregunta] = React.useState({
    id: 0,
    message: '',
    trigger: 1,
  })
  const [erroPregunta, setErrorPregunta] = React.useState(false)

  const getQuestionByNit = async () => {
    setLoadingData(true)

    const newNit = path == "wsp" ? user.user.dataUser.nit : user.user.stateChad == 5 ? user.user.dataUser.nit : user.user.companyNit
    const newNumber = path == "wsp" ? user.user.whatsapp : user.user.stateChad == 5 ? user.user.whatsapp : user.user.phone
    const newDeparment = path == "wsp" ? user.user.dataUser.nit : user.user.stateChad == 5 ? state.idResponsable : user.user.id

    axios.get(`${APP_API_URL}/admin/questions/${newNit}/${newNumber}/${newDeparment}/${path}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken')
      },
    }).then((res) => {

      const arrOptions = res.data.data.questions.sort((a, b) => (a.id > b.id) ? 1 : -1)

      dispatch(saveQuestionsBotAction(arrOptions));
      if (arrOptions.length > 0) {
        const last = arrOptions[arrOptions.length - 1];
        setPregunta({
          id: last.id + 1,
          message: '',
          trigger: 1,
        });
      }
      setLoadingData(false)

    }).catch(err => {
      setLoadingData(false)
    })
  }
  useEffect(() => {
    getQuestionByNit()
  }, [])

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('userToken')
  }

  const handleSaveQuestions = () => {

    if (user.user.role !== 3 && state.idResponsable === null && path != "wsp") {
      setError(true)
      return
    }

    if (pregunta.message === "") {
      setErrorPregunta(true)
      return
    }
    setLoading(true)
    // const obj = { ...pregunta,
    //   nit: user.user.companyNit,
    //   number: user.user.phone,
    //   idResponsable: user.user.id };
    // console.log("... ", obj);
    axios.post(
      `${APP_API_URL}/admin/questions`,
      {
        ...pregunta,
        nit: path == "wsp" ? user.user.dataUser.nit : user.user.stateChad == 5 ? user.user.dataUser.nit : user.user.companyNit,
        number: path == "wsp" ? user.user.whatsapp : user.user.stateChad == 5 ? user.user.whatsapp : user.user.phone,
        idResponsable: path == "web" ? user.user.stateChad == 5 ? state.idResponsable : user.user.id : user.user.dataUser.nit,
        wsp: path == "wsp"
      },
      { headers: headers })
      .then(res => {
        dispatch(saveQuestionBotAction(pregunta))
        setPregunta({
          id: pregunta.id + 1,
          message: '',
          trigger: 1,
        })
        setLoading(false)
      }).catch(error => setLoading(false))

  }

  const changePregunta = (value) => {
    setPregunta({ ...pregunta, message: value })
  }

  return (<Card small className="h-100">
    {/* Card Header */}
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>

    <CardBody className="d-flex flex-column">
      <Form className="quick-post-form">
        {/* Title */}
        <FormGroup>
          <label htmlFor="">Digite su pregunta o enunciado</label>
          <div className="d-flex">
            <FormInput style={{ width: '90%' }} value={pregunta.message} onChange={(e) => changePregunta(e.target.value)} placeholder="Brave New World" />
            {/* <span style={{
              width: '30px',
              marginLeft: '10px',
              margin: 'auto',
              cursor: 'pointer'
            }} className="d-block"><i class="fas fa-link"></i></span> */}
          </div>
          {erroPregunta && <span style={{ color: "red" }}>Este campo es obligatorio</span>}
          {error && <span style={{ color: "red" }}>Debe crear un departamento primero</span>}
        </FormGroup>

        {/* Body */}
        {/* <FormGroup>
          <div style={{
            width: '100%',
            border: '1px solid #efefef',
            borderRadius: ' 5px',
            padding: '20px',
          }}>
            <div className='d-flex'>
              <button onClick={nuevaRespuesta} type="button" style={{ border: '1px solid  #efefef', padding: '12px' }} className="btn">+</button>
              <span style={{ margin: ' auto 0' }} className="ml-2">Agregar mas respuestas</span>
            </div> */}
        {/**RESPUESTAS */}
        {/* <div>
              {respuestas.map((respuesta, index) => (
                <div key={index} className="mt-1 d-flex">
                  <FormInput value={respuesta.message} onChange={(e) => leerCampo(index, e.target.value, 'message')} placeholder="Brave New World" />
                  <Select
                    className="basic-single w-130 ml-1"
                    classNamePrefix="select"
                    defaultValue={options[0]}
                    name="planes"
                    placeholder="trigger"
                    onChange={(e) => leerCampo(index, e.value, 'trigger')}
                    options={options}
                  />
                  <button type="button" onClick={() => eliminarRespuesta(index)} style={{ color: 'white', backgroundColor: '#fe6969' }} className="btn ml-1">
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
              ))}
            </div> */}
        {/* </div>
        </FormGroup> */}
        {/* Create Draft */}
        <FormGroup className="mb-0">
          <Button onClick={() => loading ? undefined : handleSaveQuestions()} theme="accent" type="button">
            {loading ? (
              <CircularProgress size={25} />
            ) : "Crear pregunta"}
          </Button>
        </FormGroup>
      </Form>
    </CardBody>
  </Card>)
};

NewDraft.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

NewDraft.defaultProps = {
  title: "Nueva pregunta"
};

export default NewDraft;
